/*
 * Helper functions for the universal TypeaheadDropdown component
 */

/**
 * Helper to return a single string value from either a string or object option
 * @method getOptionValue
 * @param  {String or Object}   option dropdown option, may be a string or an object
 * @return {String}             Option value to display in the dropdown
 */
export const getOptionValue = option => {
  const value = typeof option === 'string' ? option : option.title || option.key
  return value || ''
}

/**
 * Helper to return a filtered options array for either string or object options.
 * Will also search all nested children within option objects:
 *  {
 *    key: 'option.1',
 *    title: 'Option 1 Title',
 *    children: [
 *      {
 *        key: 'option.1',
 *        title: 'First Child of 1',
 *        children: [ ... ],
 *      },
 *    ],
 *  }
 * @method getFilteredOptions
 * @param  {Array}      options List of dropdown options (either strings or objects)
 * @param  {String}     filter
 * @return {Array}      Re
 */
export const getFilteredOptions = (options, filter) => {
  const normalizedFilter = filter.toLowerCase()

  if (!options || !options.length) return options

  let filteredOptions
  if (typeof options[0] === 'string') {
    filteredOptions = options.filter(option =>
      option.toLowerCase().includes(normalizedFilter),
    )
  } else {
    filteredOptions = options
      .reduce((acc, option) => {
        // If the key or title of the option matches the filter, add it
        if (
          (option.key && option.key.toLowerCase().includes(normalizedFilter)) ||
          (option.title && option.title.toLowerCase().includes(normalizedFilter))
        ) {
          return acc.concat({
            ...option,
            // Filter the chilren of the option
            children: getFilteredOptions(option.children, filter),
          })
        }
        // Need to search the chilren of the option even if it did not match the filter
        return acc.concat(getFilteredOptions(option.children, filter))
      }, [])
      .filter(Boolean) // remove nulls
  }
  return filteredOptions
}
