import React, { Component } from 'react'
import { bool, string } from 'prop-types'
import classNames from 'classnames'
import nanoid from 'nanoid'

import { component } from './icon.scss'

/**
 * Create a razzle svg icon / icon font
 */
export default class Icon extends Component {
  static displayName = 'Icon'

  static propTypes = {
    id: string.isRequired,
    title: string,
    ariaLabelledById: string,
    className: string,
    font: bool,
    presentation: bool,
  }

  static defaultProps = {
    className: '',
    font: true,
    presentation: false,
    title: null,
    ariaLabelledById: '',
  }

  constructor(props) {
    super(props)
    // @TODO KILL THIS "MAGIC" WITH FIRE. STOP BREAKING EVERYTHING.
    // THIS IS THE MAGIC 🔮🎉
    // Use wepack dynamic import with eager mode to dynaimcally import any SVG, the
    // SVG sprite loader will then scoop it up into our sprite
    // eslint-disable-next-line
    import(
      /* webpackMode: "eager", webpackChunkName: "icons" */ `../../../assets/icons/${props.id}.svg`
    )
  }

  render() {
    const {
      title,
      presentation,
      id,
      className,
      font,
      ariaLabelledById,
    } = this.props
    const fill = id.includes('-fill')
    const ariaLabelledBy = ariaLabelledById || `${id}-title-${nanoid(4)}`
    return (
      <svg
        aria-labelledby={title && !presentation ? ariaLabelledBy : undefined}
        role={presentation ? 'presentation' : 'img'}
        className={classNames(component, 'icon', id, className, {
          font,
          'icon-fill': fill,
        })}
      >
        {!presentation && <title id={ariaLabelledBy}>{title || id}</title>}
        <use xlinkHref={`#${id}`} href={`#${id}`} />
      </svg>
    )
  }
}
