import React from 'react'
import classNames from 'classnames'
import { arrayOf, oneOfType, string } from 'prop-types'

const propTypes = {
  className: string,
  description: string,
  id: string,
  invalid: oneOfType([string, arrayOf(string)]),
  warning: string,
}

const defaultProps = {
  className: '',
  description: '',
  id: '',
  invalid: '',
  warning: undefined,
}

/**
 * FormHelpText
 * Will either render description text or an invalid message (invalid text takes precedence)
 * (This is styled in `forms.scss`)
 */
const FormHelpText = ({ className, id, invalid, description, warning }) => {
  if (invalid && !!invalid.length) {
    if (Array.isArray(invalid)) {
      return (
        <span id={id}>
          {invalid.map(
            message =>
              message && (
                <div
                  className={classNames(className, 'form-help-text text-danger')}
                  data-test="form-invalid-text"
                  key={message}
                >
                  {message}
                </div>
              ),
          )}
        </span>
      )
    }
    return (
      <div
        className={classNames(className, 'form-help-text text-danger')}
        id={id}
        data-test="form-invalid-text"
      >
        {invalid}
      </div>
    )
  }
  if (warning || description) {
    return (
      <div
        className={classNames(className, 'form-help-text text-muted')}
        data-test="form-help-text"
        id={id}
      >
        {warning || description}
      </div>
    )
  }

  return <div />
}

FormHelpText.propTypes = propTypes
FormHelpText.defaultProps = defaultProps
export default FormHelpText
