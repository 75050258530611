import { createSelector } from 'reselect'
import nanoid from 'nanoid'

import { removeAllEntities, removeEntity } from './entity-transforms'

import compileEntityMaps from '../utils/compile-entity-maps'
import compileFormValue from '../utils/compile-form-value'
import createNodeDefaultValue from '../utils/create-node-default-value'
import { isValueDirty, shouldCompareNullValues } from '../utils/form-data-helpers'
import { validateParentPaths } from '../utils/validation-helpers'
import getSchemaNode from '../utils/get-schema-node'

const ADD_ARRAY_ITEM = 'FORMOGORGON/ADD_ARRAY_ITEM'
const REMOVE_ARRAY_ITEM = 'FORMOGORGON/REMOVE_ARRAY_ITEM'
const SET_FORM_FIELDS = 'FORMOGORGON/SET_FORM_FIELDS'
const SET_GLOBAL_INVALID = 'FORMOGORGON/SET_GLOBAL_INVALID'
const SET_READ_ONLY = 'FORMOGORGON/SET_READ_ONLY'
const UPDATE_FORM_DATA = 'FORMOGORGON/UPDATE_FORM_FIELD'

export const makeFormReducer = configuredFormId => (
  state = {
    // The form meta data includes all of the form level info
    meta: {
      readOnly: false, // If the form can be changed
      invalid: false, // Are all of the fields valid?
      showValidations: false, // Are we showing validation errors?
      schema: null,
    },
    valueById: {}, // The data for each field in the form
    defaultById: {}, // Default field values
    dirtyById: {}, // The dirty fields
    invalidById: {}, // The invalid fields
  },
  { type, payload = {} } = {},
) => {
  // If the formId of the payload doesn't match this reducer's formId, there's
  // nothing to do
  if (payload.formId !== configuredFormId) return state
  const { arrayPath, batch, clearArray, valid, materializedPath, removeIndex, value } = payload

  // You can't make variables inside of a switch statement 😡
  let newState
  let newArrayCount
  let newArrayState
  const removedArrayCount = state.valueById[arrayPath] - 1
  const removedArrayPath = `${arrayPath},${removedArrayCount}`

  // Prep new entity maps with added array item. If payload includes `batch` option,
  // prep entities with a group of new items in one swoop 🦅
  if (type === ADD_ARRAY_ITEM) {
    const valueArray = Array.isArray(value) ? value : [value]
    const count = state.valueById[materializedPath]
    // The value supplied in the payload of a `batch` add should always represent
    // the full array of data, INCLUDING previous draft rows if desired.
    newArrayCount = batch ? value.length : valueArray.length + count
    newArrayState = { dirtyById: {}, valueById: {}, invalidById: {}, meta: {} }

    valueArray.forEach((newItemValue, idx) => {
      const newItemPath = `${materializedPath},${batch ? idx : count + idx}`
      const itemSchema = getSchemaNode(state.meta.schema, newItemPath)
      const newItemEntityMaps = compileEntityMaps(
        itemSchema, // schema
        newItemValue || createNodeDefaultValue(itemSchema), // data
        newItemPath, // path
        batch ? [] : null, // default data
        { validate: true }, // need to validate to show which fields are required
      )
      if (Object.keys(newItemEntityMaps.invalidById).length) newArrayState.meta.invalid = true
      newArrayState = {
        dirtyById: { ...newArrayState.dirtyById, ...newItemEntityMaps.dirtyById },
        valueById: { ...newArrayState.valueById, ...newItemEntityMaps.valueById },
        invalidById: { ...newArrayState.invalidById, ...newItemEntityMaps.invalidById },
        meta: { ...newArrayState.meta },
      }
    })
  }

  switch (type) {
    case SET_FORM_FIELDS:
      return {
        meta: {
          // If there are any invalid fields, the form is invalid
          invalid: payload.globalInvalidOverride || !!Object.keys(payload.invalidById).length,
          // Don't show form errors on initial setup
          showValidations: false,
          // Schema used to look up node schema and for value
          schema: payload.schema,
          // Globally set all form fields as editible/uneditible
          readOnly: payload.readOnly,
          // Used to trigger Form rerenders, updated each time setFormFields() is called.
          version: payload.version,
        },
        valueById: payload.valueById,
        defaultById: payload.defaultById,
        dirtyById: payload.dirtyById,
        invalidById: payload.invalidById,
      }

    case UPDATE_FORM_DATA:
      newState = {
        ...state,
        valueById: {
          ...state.valueById,
          [materializedPath]: value,
        },
        invalidById: {
          ...state.invalidById,
        },
        dirtyById: {
          ...state.dirtyById,
        },
        meta: {
          ...state.meta,
        },
      }

      // ✅ Update validation status
      if (valid === true) {
        delete newState.invalidById[materializedPath]
        if (!Object.keys(newState.invalidById).length) {
          newState.invalidById = {}
          newState.meta.invalid = false
        } else {
          // For nested data, check if there are parent paths that are valid, and remove
          const validationByPath = validateParentPaths({
            valueById: newState.valueById,
            materializedPath,
            schema: newState.meta.schema,
          })
          Object.keys(validationByPath).forEach(parentPath => {
            if (validationByPath[parentPath] === true) {
              delete newState.invalidById[parentPath]
              if (!Object.keys(newState.invalidById).length) newState.meta.invalid = false
            }
          })
        }
      } else {
        // Add the errors messages array
        // For nested data, check if there are parent paths that are invalid, and add to invalidById
        const validationByPath = validateParentPaths({
          valueById: newState.valueById,
          materializedPath,
          schema: newState.meta.schema,
        })
        Object.keys(validationByPath).forEach(parentPath => {
          if (validationByPath[parentPath] === false) {
            newState.invalidById[parentPath] = valid
          }
        })
        newState.invalidById[materializedPath] = valid
        newState.meta.invalid = true
      }

      // 🛁 Update dirty status
      if (
        isValueDirty({
          type: typeof value,
          value,
          valueDefault: state.defaultById[materializedPath],
          compareNullValues: shouldCompareNullValues({
            // Materialized path without the root
            materializedPath: materializedPath
              .split(',')
              .slice(1)
              .join(','),
            node: state.meta.schema,
          }),
        })
      ) {
        newState.dirtyById[materializedPath] = true
      } else {
        delete newState.dirtyById[materializedPath]
      }

      return newState

    case SET_READ_ONLY:
      return {
        ...state,
        meta: {
          ...state.meta,
          readOnly: payload.readOnly,
        },
      }

    case SET_GLOBAL_INVALID:
      return {
        ...state,
        meta: {
          ...state.meta,
          invalid: payload.invalid,
        },
      }

    case ADD_ARRAY_ITEM:
      newState = {
        ...state,
        meta: {
          ...state.meta,
          ...newArrayState.meta,
        },
        dirtyById: {
          ...state.dirtyById,
          // 🐛 TODO: the dirty value isn't initializing properly when creating the
          // entity values for new array items, it's not a huge deal b/c the array
          // length is correctly being set as dirty... but we should fix it
          ...newArrayState.dirtyById,
        },
        valueById: {
          ...state.valueById,
          ...newArrayState.valueById,
          [materializedPath]: newArrayCount,
        },
        invalidById: {
          ...state.invalidById,
          ...newArrayState.invalidById,
        },
      }
      if (newArrayState?.meta?.invalid) {
        Object.keys(newArrayState.invalidById).forEach(invalidItem => {
          const validationByPath = validateParentPaths({
            valueById: newState.valueById,
            materializedPath: invalidItem,
            schema: newState.meta.schema,
          })
          Object.keys(validationByPath).forEach(parentPath => {
            if (validationByPath[parentPath] === false) {
              newState.invalidById[parentPath] = newArrayState.invalidById[invalidItem]
            }
          })
        })
      }
      if (newArrayCount === state.defaultById[materializedPath]) {
        delete newState.dirtyById[materializedPath]
      } else {
        newState.dirtyById[materializedPath] = true
      }

      return newState

    case REMOVE_ARRAY_ITEM:
      newState = clearArray
        ? // Either clear all entities from the array
          {
            ...state,
            dirtyById: removeAllEntities(materializedPath, state.dirtyById),
            invalidById: removeAllEntities(materializedPath, state.invalidById),
            valueById: {
              ...removeAllEntities(materializedPath, state.valueById),
              [materializedPath]: 0,
            },
          }
        : // Or clear a single entity from the array
          {
            ...state,
            dirtyById: removeEntity(arrayPath, removeIndex, state.dirtyById),
            invalidById: removeEntity(arrayPath, removeIndex, state.invalidById),
            valueById: {
              ...removeEntity(arrayPath, removeIndex, state.valueById),
              [arrayPath]: removedArrayCount,
            },
          }

      // When removing array items, we also need to check if they have valid
      // parent paths, and if so, remove them. We do not want to show an error banner
      // notification if the user has deleted a row with an invalid preference
      if (Object.keys(newState.invalidById).length) {
        const validationByPath = validateParentPaths({
          valueById: newState.valueById,
          materializedPath,
          schema: newState.meta.schema,
        })
        Object.keys(validationByPath).forEach(parentPath => {
          if (validationByPath[parentPath] === true) {
            delete newState.invalidById[parentPath]
            if (!Object.keys(newState.invalidById).length) {
              newState.invalidById = {}
              newState.meta.invalid = false
            }
          }
        })
      }

      if (removedArrayCount === state.defaultById[arrayPath]) {
        delete newState.dirtyById[arrayPath]
        // This makes sure items are removed properly in case they weren't removed by removeEntity
        delete newState.dirtyById[removedArrayPath]
      } else {
        newState.dirtyById[arrayPath] = true
      }

      return newState

    default:
      return state
  }
}

// Selectors
// ---------------------------------------------------------------------------
// NOTE: USE THE `...FormStore` SELECTORS in mapStateToProps WHEN MAKING NEW FORMS!!
// ... 🙏 and try to update old forms as you encounter them. Selectors that use
// the full store cause the consuming Form to update with EVERY action in ANY
// reducer ☠️

/*
 *  ✨ USE THESE! Selectors for Reselect Factory Functions
 */

/* Full Form Data */
const getFormSchemaFormStore = formStore => formStore.meta.schema
const getFormVersionFormStore = formStore => formStore.meta.version
const getFormReadOnlyFormStore = (formStore, { schemaNode, materializedPath }) => {
  const node = getSchemaNode(formStore.meta.schema, materializedPath)
  const formReadOnly = formStore.meta.readOnly
  const manualSchemaNode = schemaNode || {}
  return manualSchemaNode.readOnly || node.readOnly || formReadOnly
}
const getFormDefaultFormStore = (formStore, formId) =>
  compileFormValue(formStore.meta.schema, formStore.defaultById, formId)
const getFormEntitiesFormStore = formStore => ({
  valueById: formStore.valueById,
  defaultById: formStore.defaultById,
  dirtyById: formStore.dirtyById,
  invalidById: formStore.invalidById,
})
const getFormInvalidByIdFormStore = formStore => formStore.invalidById
const getFormInvalidStatusFormStore = formStore => {
  const invalidEntities = formStore.invalidById
  return !!Object.keys(invalidEntities).length || formStore.meta.invalid
}
const getFormDirtyStatusFormStore = (formStore, materializedPath) => {
  const dirtyEntities = formStore.dirtyById
  // Check if this node or any of it's children are dirty
  const dirtyInPath = Object.keys(dirtyEntities).filter(key => key.indexOf(materializedPath) === 0)
  return !!Object.keys(dirtyInPath).length
}
const getFormDirtyNodesFormStore = (formStore, materializedPath) => {
  if (materializedPath) {
    const dirtyEntities = formStore.dirtyById
    // Create a new copy of dirtyById with only dirty values for this node or any of it's children
    const dirtyInPath = Object.keys(dirtyEntities).reduce((acc, key) => {
      if (key.indexOf(materializedPath) === 0) acc[key] = true
      return acc
    }, {})
    return dirtyInPath
  }
  return formStore.dirtyById
}
const getFormValueFormStore = (formStore, materializedPath) =>
  compileFormValue(
    getSchemaForNodeFormStore(formStore, materializedPath), // eslint-disable-line
    formStore.valueById,
    materializedPath,
  )
/* Data by Node */
const getDefaultValueForNodeFormStore = (formStore, materializedPath) =>
  formStore.defaultById[materializedPath]
const getDirtyForNodeFormStore = (formStore, materializedPath) =>
  formStore.dirtyById[materializedPath]
const getInvalidForNodeFormStore = (formStore, materializedPath) =>
  formStore.invalidById[materializedPath]
const getSchemaForNodeFormStore = (formStore, materializedPath) => {
  const node = getSchemaNode(formStore.meta.schema, materializedPath)
  return { ...node, currentMaterializedPath: materializedPath }
}
const getValueForNodeFormStore = (formStore, { materializedPath }) =>
  formStore.valueById[materializedPath]
const getValuesForNodeFormStore = (formStore, materializedPath) => ({
  value: formStore.valueById[materializedPath],
  defaultValue: formStore.defaultById[materializedPath],
  dirty: formStore.dirtyById[materializedPath],
  invalid: formStore.invalidById[materializedPath],
})

const getSchemaNodePropsFormStore = (formStore, { materializedPath, schemaNode }) => {
  // TODO: passing back a new object (even if it's just `node`) makes createSelector()
  // unable to memoize this selector.
  const node = getSchemaNode(formStore.meta.schema, materializedPath)
  return {
    ...node,
    ...schemaNode,
    currentMaterializedPath: materializedPath,
  }
}

/*
 *  Reselect Factory Functions
 *  NOTE: For a `makeSelector()` function to create a properly memoized selector,
 *  the `getSelector` functions passed in must recieve the `formStore` NOT the `store`
 *  (i.e. store[formID]). Without this, a change in ANY reducer in the redux store
 *  will trigger a recalulation of the memoized data. bad. bad. bad.
 */
/* Full Form Data */
const makeGetFormDirtyNodes = () =>
  createSelector(getFormDirtyNodesFormStore, dirtyNodes => dirtyNodes)
const makeGetFormDirtyStatus = () => createSelector(getFormDirtyStatusFormStore, dirty => dirty)
export const makeGetFormReadOnly = () =>
  createSelector(getFormReadOnlyFormStore, readOnly => readOnly)
const makeGetFormValue = () => createSelector(getFormValueFormStore, value => value)
const makeGetFormSchema = () => createSelector(getFormSchemaFormStore, formSchema => formSchema)
const makeGetFormVersion = () => createSelector(getFormVersionFormStore, version => version)
const makeGetInvalidById = () =>
  createSelector(getFormInvalidByIdFormStore, invalidById => invalidById)
const makeGetFormInvalidStatus = () =>
  createSelector(getFormInvalidStatusFormStore, formSchema => formSchema)
const makeGetFormDefault = () => createSelector(getFormDefaultFormStore, formDefault => formDefault)
const makeGetFormEntities = () =>
  createSelector(getFormEntitiesFormStore, formDefault => formDefault)
/* Data by Node */
const makeGetDefaultValueForNode = () =>
  createSelector(getDefaultValueForNodeFormStore, defaultValue => defaultValue)
const makeGetDirtyForNode = () => createSelector(getDirtyForNodeFormStore, dirty => dirty)
const makeGetInvalidForNode = () => createSelector(getInvalidForNodeFormStore, invalid => invalid)
const makeGetSchemaForNode = () => createSelector(getSchemaForNodeFormStore, schema => schema)
const makeGetValueForNode = () => createSelector(getValueForNodeFormStore, value => value)
const makeGetValuesForNode = () => createSelector(getValuesForNodeFormStore, values => values)
const makeGetSchemaNodeProps = () =>
  createSelector(
    [
      getSchemaNodePropsFormStore,
      getFormReadOnlyFormStore,
      getValueForNodeFormStore,
      getFormVersionFormStore,
    ],
    (schemaNodeProps, readOnly, nodeValue, version) => {
      // The `readOnly` prop may be set at the form level or for the individual node
      // NOTE: If this causes issues with updating the form, add readOnly to the schema
      // the way that editDecoration is handled
      const propsForNode = {
        ...schemaNodeProps,
        readOnly,
        version,
      }
      // For arrays we also include the current value (length) of the array in the
      // schema, the renderChildrenNodes uses this to know how many children to render
      if (propsForNode.type === 'array') {
        propsForNode.itemsCount = nodeValue
      }

      return propsForNode
    },
  )

/*
 *  ⚠️  AVOID USING THESE! (in mapStateToProps)
 *  (Convert Forms using these selectors to FormStore selectors as you encounter them)
 */
export const getFormSchema = (store, formId) => store[formId].meta.schema
export const getFormReadOnly = (store, formId) => store[formId].meta.readOnly
export const getFormValue = (store, materializedPath) => {
  const formId = materializedPath.split(',')[0]
  const formData = store[formId]
  return compileFormValue(
    getSchemaForNode(store, materializedPath), // eslint-disable-line
    formData.valueById,
    materializedPath,
  )
}
export const getFormDefault = (store, formId) => {
  const formData = store[formId]
  return compileFormValue(formData.meta.schema, formData.defaultById, formId)
}
export const getSchemaForNode = (store, materializedPath) => {
  const formStore = store[materializedPath.split(',')[0]]
  const node = getSchemaNode(formStore.meta.schema, materializedPath)
  return node
}
export const getValuesForNode = (store, materializedPath) => {
  const formStore = store[materializedPath.split(',')[0]]
  return {
    value: formStore.valueById[materializedPath],
    defaultValue: formStore.defaultById[materializedPath],
    dirty: formStore.dirtyById[materializedPath],
    invalid: formStore.invalidById[materializedPath],
  }
}
export const getFormEntities = (store, formId) => ({
  valueById: store[formId].valueById,
  defaultById: store[formId].defaultById,
  dirtyById: store[formId].dirtyById,
  invalidById: store[formId].invalidById,
})
export const getFormDirtyNodes = (store, formId) => store[formId].dirtyById
export const getFormDirtyStatus = (store, materializedPath) => {
  const formId = materializedPath.split(',')[0]
  const dirtyEntities = store[formId].dirtyById
  const dirtyInPath = Object.keys(dirtyEntities).filter(key => key.indexOf(materializedPath) === 0)
  return !!Object.keys(dirtyInPath).length
}
export const getFormInvalidStatus = (store, formId) => {
  const invalidEntities = store[formId].invalidById
  return !!Object.keys(invalidEntities).length || store[formId].meta.invalid
}

export const selectors = {
  makeGetDefaultValueForNode,
  makeGetDirtyForNode,
  makeGetFormDefault,
  makeGetFormDirtyNodes,
  makeGetFormDirtyStatus,
  makeGetFormEntities,
  makeGetFormInvalidStatus,
  makeGetFormReadOnly,
  makeGetFormSchema,
  makeGetFormValue,
  makeGetFormVersion,
  makeGetInvalidById,
  makeGetInvalidForNode,
  makeGetSchemaForNode,
  makeGetSchemaNodeProps,
  makeGetValueForNode,
  makeGetValuesForNode,
}

// Action Creators
// ---------------------------------------------------------------------------

export const updateFormData = ({ materializedPath, value, valid }) => {
  const path = materializedPath.split(',')

  return {
    type: UPDATE_FORM_DATA,
    payload: {
      formId: path[0],
      materializedPath,
      valid,
      value,
    },
  }
}

/**
 * The reducer uses the passed array path to look up the current array length and
 * schema for the new item, then generates new values by id for updates.
 * @param {string} materializedPath Path of the **array** being updated
 */
export const addArrayItem = (materializedPath, value) => {
  const [formId] = materializedPath.split(',')

  return {
    type: ADD_ARRAY_ITEM,
    payload: {
      formId,
      materializedPath,
      value,
    },
  }
}

/**
 * Add a group of array items in a single action
 * @param {string} materializedPath Path of the **array** being updated
 * @param {array} value An *array* of values
 */
export const addArrayItemsBatch = (materializedPath, value) => {
  const [formId] = materializedPath.split(',')

  return {
    type: ADD_ARRAY_ITEM,
    payload: {
      batch: true,
      formId,
      materializedPath,
      value,
    },
  }
}

/**
 * Uses the passed materializedPath to splice out this array value
 * @param {string} materializedPath Path of the array item to remove
 */
export const removeArrayItem = (materializedPath, options = {}) => {
  const { clearArray = false } = options
  const pathSegments = materializedPath.split(',')
  return {
    type: REMOVE_ARRAY_ITEM,
    payload: {
      clearArray,
      materializedPath,
      formId: pathSegments[0],
      arrayPath: clearArray ? materializedPath : pathSegments.slice(0, -1).join(','),
      removeIndex: pathSegments[pathSegments.length - 1],
    },
  }
}

/**
 * Can be used for initial form setup or for resetting an existing form with a new
 * schema.
 */
export const setFormFields = (
  formId,
  schema,
  reduxMaps,
  { readOnly = false, globalInvalidOverride } = {},
) => ({
  type: SET_FORM_FIELDS,
  payload: {
    formId,
    globalInvalidOverride,
    schema,
    readOnly,
    version: nanoid(),
    ...reduxMaps,
  },
})

/**
 * Clear an existing form
 */
export const clearForm = formId => ({
  type: SET_FORM_FIELDS,
  payload: {
    defaultById: {},
    dirtyById: {},
    formId,
    invalidById: {},
    readOnly: false,
    schema: {},
    valueById: {},
    version: nanoid(),
  },
})

/**
 * Set the view mode of the form as either readOnly or not
 */
export const setFormReadOnly = (formId, readOnly) => ({
  type: SET_READ_ONLY,
  payload: { formId, readOnly },
})

/**
 * Set the global meta invalid flag independent of invalidById
 */
export const setFormGlobalInvalid = (formId, invalid) => ({
  type: SET_GLOBAL_INVALID,
  payload: { formId, invalid },
})
