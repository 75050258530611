import React, { Component } from 'react'
import { string, shape } from 'prop-types'

import FormContext from '../FormContext/FormContext'
import SchemaNode from './SchemaNode'

import compileFormValue from '../utils/compile-form-value'
import getSchemaNode from '../utils/get-schema-node'

// TODO: This needs to be a class Component to use context (switch to useContext after updating react... )
/* eslint-disable react/prefer-stateless-function */
class ContextSchemaNodeContainer extends Component {
  static displayName = 'ContextSchemaNodeContainer'

  static propTypes = {
    materializedPath: string.isRequired,
    schemaNode: shape({}),
  }

  static defaultProps = {
    schemaNode: {},
  }

  render() {
    const { materializedPath, schemaNode, ...rest } = this.props
    const {
      addArrayItem,
      dirtyById,
      formSchema,
      invalidById,
      removeArrayItem,
      update,
      valueById,
    } = this.context

    const schema = getSchemaNode(formSchema, materializedPath)
    let value = valueById[materializedPath]
    let itemsCount
    if (schema.type === 'array') {
      // In valueById, the "value" of an array is it's length, so we need to compile it
      value = compileFormValue(schema, valueById, materializedPath)
      // For arrays we also include the current value (length) of the array in the
      // schema, the renderChildrenNodes uses this to know how many children to render
      itemsCount = value.length
    } else if (schema.type === 'object') {
      // We return the object value if the schema has `properties` since we will just be
      // rendering the object value manually, otherwise we return undefined
      if (schema.properties) {
        value = undefined
      }
    }

    return (
      <SchemaNode
        addArrayItem={(path, itemValue) =>
          addArrayItem({ materializedPath: path, value: itemValue })
        }
        dirty={dirtyById[materializedPath]}
        invalid={invalidById[materializedPath]}
        materializedPath={materializedPath}
        removeArrayItem={(path, options) =>
          removeArrayItem({ materializedPath: path, clearArray: options?.clearArray })
        }
        schemaNode={{
          ...schemaNode,
          ...schema,
          itemsCount,
          currentMaterializedPath: materializedPath,
        }}
        updateFormData={update}
        value={value}
        {...rest}
      />
    )
  }
}

ContextSchemaNodeContainer.contextType = FormContext

export default ContextSchemaNodeContainer
