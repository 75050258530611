import React from 'react'
import { element, string, oneOfType, node } from 'prop-types'
import classNames from 'classnames'

import { Button, Icon, Anchor } from '../..'
import { component } from './BlockButton.scss'

const BlockButton = ({
  url,
  children,
  icon,
  iconOverride,
  inverse,
  expanding,
  className,
  ...rest
}) => {
  const baseClassList = classNames(
    component,
    'd-flex m-0 align-items-center btn-block',
    className,
    { inverse, expanding },
  )
  const innerContent = (
    <div className="w-100 d-flex justify-content-between align-items-center">
      <div
        data-test="block-button-content"
        className="d-inline-block text-left block-button-content"
      >
        {children}
      </div>
      <div>
        {iconOverride}
        {icon && <Icon data-test="block-button-icon" id={icon} presentation />}
      </div>
    </div>
  )
  const fullContent = url ? (
    <Anchor
      href={url}
      data-test="block-button-anchor"
      className={classNames(baseClassList, 'no-underline')}
      link={false}
      {...rest}
    >
      {innerContent}
    </Anchor>
  ) : (
    <Button data-test="block-button-button" className={baseClassList} {...rest}>
      {innerContent}
    </Button>
  )
  return fullContent
}
BlockButton.propTypes = {
  url: string,
  content: oneOfType([element, string]),
  icon: string,
  children: node,
  iconOverride: node,
}

BlockButton.defaultProps = {
  url: null,
  icon: null,
  content: null,
  children: null,
  className: '',
  iconOverride: null,
}

export default BlockButton
