import React, { Fragment } from 'react'
import { arrayOf, bool, node, string, any } from 'prop-types'
import classNames from 'classnames'

import { component } from './condensed-table.scss'

const propTypes = {
  headers: arrayOf(string).isRequired,
  rowLabels: arrayOf(node),
  // eslint-disable-next-line
  rows: arrayOf(arrayOf(any)).isRequired,
  stacked: bool,
}

const defaultProps = { rowLabels: null, stacked: false }

const CondensedTable = ({ headers, rows, stacked, rowLabels, ...rest }) => {
  if (stacked) {
    return (
      <div
        className={classNames(component, 'condensed-table shadow-base')}
        {...rest}
      >
        <table className="table table-sm mb-0 stacked">
          {rows.map((row, rowIdx) => (
            <Fragment key={row}>
              <thead>
                <tr>{rowLabels && <th colSpan="2">{rowLabels[rowIdx]}</th>}</tr>
              </thead>
              <tbody>
                {row.map((cell, colIdx) => (
                  <tr key={cell}>
                    <th scope="row">{headers[colIdx]}</th>
                    {typeof cell === 'string' || typeof cell === 'number' ? (
                      <td key={cell}>{cell}</td>
                    ) : (
                      cell
                    )}
                  </tr>
                ))}
              </tbody>
            </Fragment>
          ))}
        </table>
      </div>
    )
  }
  return (
    <div className={classNames(component, 'condensed-table shadow-base')} {...rest}>
      <table className="table-header table table-sm mb-0">
        <thead className="small">
          <tr>
            {headers.map(header => (
              <th scope="col" key={header}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
      </table>
      {/* NOTE: add a min & max height to .scrolling-content if this needs to have a limited space */}
      <div className="scrolling-content">
        <table className="table-body table table-sm mb-0">
          <tbody>
            {rows.map(row => (
              <tr key={row}>
                {row.map(cell =>
                  typeof cell === 'string' || typeof cell === 'number' ? (
                    <td key={cell}>{cell}</td>
                  ) : (
                    <span key={cell}>{cell}</span>
                  ),
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

CondensedTable.propTypes = propTypes
CondensedTable.defaultProps = defaultProps
CondensedTable.displayName = 'CondensedTable'
export default CondensedTable
