import React from 'react'
import classNames from 'classnames'
import {
  arrayOf,
  bool,
  element,
  func,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types'
import { Button } from '../..'

import { component } from './banner-notification.scss'

// NOTE: Dismissible or autoclose notifications should use the ToastNotification

const propTypes = {
  buttonArray: arrayOf(
    shape({
      text: string,
      onClick: func,
      outline: bool,
      disabled: bool,
    }),
  ),
  className: string,
  color: oneOf([
    'danger',
    'dark',
    'info',
    'light',
    'muted',
    'primary',
    'secondary',
    'success',
    'warning',
    '', // Defaults to gray
  ]),
  decoration: element,
  notificationBody: oneOfType([string, element]),
  position: oneOf(['top', 'bottom']),
}

const defaultProps = {
  buttonArray: [],
  className: null,
  color: '',
  decoration: null,
  notificationBody: '',
  position: 'bottom',
}

/**
 * A colored fixed banner (header or footer) meant to transiently display notifications
 * @method      BannerNotification
 * @param       {Array}     buttonArray Array of button options objects
 * @param       {Element}   decoration An element, such as an Icon, to go before the notificationBody
 * @param       {String}    notificationBody Notification text to be displayed on the left
 * @param       {Boolean}   onDismiss Callback function called whenever the notification is dismissed
 */
const BannerNotification = props => {
  const {
    buttonArray,
    className,
    color,
    decoration,
    notificationBody,
    position,
  } = props

  return (
    !!notificationBody && (
      <div
        className={classNames(
          component,
          className,
          'banner-notification text-white p-3 d-flex align-items-center justify-content-between',
          {
            [`bg-${color}`]: color,
            [`fixed-${position}`]: position,
          },
        )}
        data-test="banner-notification"
      >
        <div className="left-content d-flex align-items-center">
          {decoration && (
            <span className="input-decoration d-inline h5 mb-0 pr-2">
              {decoration}
            </span>
          )}
          {notificationBody}
        </div>
        <div className="right-content d-flex align-items-center">
          {buttonArray.map(({ disabled, outline, onClick, text, ...rest }) => {
            const classes = outline ? 'btn-outline-light' : `btn-secondary`
            return (
              <Button
                className={`ml-2 ${classes}`}
                onClick={onClick}
                key={text}
                color={null}
                disabled={disabled}
                data-test="button"
                {...rest}
              >
                {text}
              </Button>
            )
          })}
        </div>
      </div>
    )
  )
}

BannerNotification.propTypes = propTypes
BannerNotification.defaultProps = defaultProps
BannerNotification.displayName = 'BannerNotification'
export default BannerNotification
