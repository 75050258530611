import { sesh } from '@/storage'

const USER_PLANFIX = 'HSQ_USER_PLANFIX'

export const setUserPlanfix = planfix => sesh.setItem(USER_PLANFIX, planfix)
export const getUserPlanfix = () => sesh.getItem(USER_PLANFIX) || {}
export const getUserPrefix = () => getUserPlanfix().alphaPrefix || ''
export const getUserPlanName = () => getUserPlanfix().planName || ''
export const getUserProductCode = () => getUserPlanfix().productCode || ''
// Product code only referenced by pdf service if SBP is disabled. 🤷‍♂️
export const getUserBackupProductCode = () => getUserPlanfix().backupProductCode || ''

export const clearPlanfix = () => sesh.removeItem(USER_PLANFIX)
