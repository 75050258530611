import React from 'react'
import { node, string } from 'prop-types'
import classNames from 'classnames'

import { component } from './sidebar-footer.scss'

/**
 * Footer for the sidebar. Renders a block footer with a bit of padding. Should be used in
 * conjunction with the `Sidebar.Body` which will flex grow and scroll in order to ensure this
 * footer is always at the bottom of the `Sidebar`
 */
const SidebarFooter = ({ children, className }) =>
  children && <div className={classNames(className, component)}>{children}</div>

SidebarFooter.propTypes = {
  children: node,
  className: string,
}

SidebarFooter.defaultProps = {
  children: null,
  className: '',
}

SidebarFooter.displayName = 'SidebarFooter'

export default SidebarFooter
