import { sesh } from '@hsq/storage'

export const appendCaptchaScript = () => {
  if (!document.getElementById('reCaptcha-script')) {
    const captchaScriptEl = document.createElement('script')
    captchaScriptEl.setAttribute('async', '')
    captchaScriptEl.setAttribute('defer', '')
    captchaScriptEl.setAttribute('id', 'reCaptcha-script')
    captchaScriptEl.setAttribute(
      'src',
      '//www.google.com/recaptcha/api.js?render=explicit',
    )
    document.body.appendChild(captchaScriptEl)
  }
}

const HSQ_CAPTCHA_SITE_KEY = 'HSQ_CAPTCHA_SITE_KEY'

export const setCaptchaSiteKey = key => {
  sesh.setItem(HSQ_CAPTCHA_SITE_KEY, key)
}

export const getCaptchaSiteKey = () => sesh.getItem(HSQ_CAPTCHA_SITE_KEY)

export const renderCaptcha = ({ target, onSuccess, onExpired, ...options }) => {
  if (!target?.id) {
    console.warn(`"id" is required on target element to render the captcha`)
    return
  }
  if (!window.grecaptcha) {
    console.warn('captcha script not found on window')
    // In storybook for the ReportIssues, sometimes the script hasn't resolved on load.
    // wait and try it again
    if (process.env.NODE_ENV === 'development' && !options.secondTry) {
      setTimeout(() => {
        renderCaptcha({ target, onSuccess, onExpired, secondTry: true })
      }, 2000)
    }
    return
  }
  window.grecaptcha.render(target.id, {
    callback: onSuccess,
    'expired-callback': onExpired,
    // eslint-disable-next-line no-use-before-define
    sitekey: getCaptchaSiteKey(),
    tabIndex: 0,
    size: 'normal',
    theme: 'light',
    type: 'image',
    ...options,
  })
}
