import {
  CheckboxContainer,
  ConnectedMultiSelectContainer,
  InputContainer,
  JsonInputContainer,
  MultiSelectContainer,
  RadioContainer,
  SelectDropdownContainer,
  ToggleContainer,
} from '../componentContainers'
import FormArray from '../FormArray/FormArray'
import FormObject from '../FormObject/FormObject'

export const reduxNodeComponentMap = () => ({
  Checkbox: CheckboxContainer,
  FormArray,
  FormObject,
  Input: InputContainer,
  JsonInput: JsonInputContainer,
  MultiSelect: ConnectedMultiSelectContainer,
  Radio: RadioContainer,
  SelectDropdown: SelectDropdownContainer,
  Toggle: ToggleContainer,
})

export const contextNodeComponentMap = () => ({
  Checkbox: CheckboxContainer,
  FormArray,
  FormObject,
  Input: InputContainer,
  JsonInput: JsonInputContainer,
  MultiSelect: MultiSelectContainer,
  Radio: RadioContainer,
  SelectDropdown: SelectDropdownContainer,
  Toggle: ToggleContainer,
})
