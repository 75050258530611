import React, { Component } from 'react'
import { bool, func, node, oneOfType, shape, string } from 'prop-types'
import Ajv from 'ajv'
import cloneDeep from 'lodash.clonedeep'

import compileEntityMaps from '../utils/compile-entity-maps'
import compileFormValue from '../utils/compile-form-value'
import createNodeDefaultValue from '../utils/create-node-default-value'
import decorateFormData from '../utils/decorate-form-data'
import decorateSchema from '../utils/decorate-schema'
import getSchemaNode from '../utils/get-schema-node'
import { isValueDirty, shouldCompareNullValues } from '../utils/form-data-helpers'
import { removeAllEntities, removeEntity } from '../dux/entity-transforms' // TODO: Move to utils

import FormContext from '../FormContext/FormContext'
import SchemaNode from '../SchemaNode'

import { contextNodeComponentMap as defaultComponentMap } from '../utils/default-node-component-maps'

// The serialize and cache options below are used to improve ajv's initialization time.
// More information can be found here: https://github.com/ajv-validator/ajv/issues/1098
const ajv = new Ajv({
  verbose: true,
  nullable: true,
  serialize: false,
  cache: {
    put: () => {},
    get: () => {},
    del: () => {},
    clear: () => {},
  },
})

const getFirstErrorMessage = errors => {
  let msg = ''
  if (errors && errors.length) {
    msg = errors[0].parentSchema.validationMessage || errors[0].message
  }
  return msg
}

/**
 * FormProvider
 * Outer container of a jsonSchema driven form that uses CONTEXT to store it's data.
 *
 * Required props:
 * - defaultSchema (any valid jsonSchema object)
 * - formId (unique string)
 * - onSubmit (function called when submit button is clicked with the current formData)
 *
 * TODO: Improve performace. Currently these context based Forms rerender the
 * SchemaNodes A LOT (unlikethe redux ones, which are super locked down).
 */
class FormProvider extends Component {
  static displayName = 'FormProvider'

  /* eslint-disable react/no-unused-prop-types */
  static propTypes = {
    children: oneOfType([node, func]),
    /** Custom component refs */
    componentMap: shape(),
    /** Custom rules for determining the component for a node */
    customNodeComponentRules: func,
    /** Flag that all input fields should debounce typing */
    debounced: bool,
    /** Starting base data */
    defaultData: shape(),
    /** Starting json schema */
    defaultSchema: shape().isRequired,
    /** Flag that all input fields should have an edit icon (deprecated) */
    editDecoration: bool,
    /** Optional: starting dirty form data */
    formData: shape(),
    /** The namespace of the form for the materialized path */
    formId: string,
    /** Prop called on form submit with form data */
    onSubmit: func.isRequired,
    /* Change this to cause a full form re-render */
    version: string,
  }

  static defaultProps = {
    children: null,
    componentMap: {},
    customNodeComponentRules: null,
    debounced: false,
    defaultData: null,
    editDecoration: false,
    formData: null,
    formId: 'contextForm', // Since these are self contained, all context forms can be the same
    version: '',
  }

  /* eslint-disable react/no-unused-state */
  /* eslint-disable react/destructuring-assignment */
  state = {
    addArrayItem: props => this.addArrayItem(props),
    removeArrayItem: props => this.removeArrayItem(props),
    defaultById: {},
    dirtyById: {},
    invalidById: {},
    valueById: {},
    formInvalid: '',
    formSchema: null,
    update: props => this.updateFormContext(props),
    FORM_PROPERTIES: {
      componentMap: {
        ...defaultComponentMap(),
        ...this.props.componentMap,
      },
      customNodeComponentRules: this.props.customNodeComponentRules || (() => null),
    },
  }

  componentDidMount() {
    this.initializeForm()
  }

  componentDidUpdate(prevProps) {
    const { componentMap, customNodeComponentRules, version } = this.props

    // If the form version is forced to change, re-initialize the form
    if (prevProps.version !== version) this.initializeForm()

    // The componentMap object is technically new on every rerender, so we need to
    // specifically check if any of the component names change
    const prevComponentMap = JSON.stringify(
      Object.values(prevProps.componentMap).map(c => c.displayName),
    )
    const newComponentMap = JSON.stringify(Object.values(componentMap).map(c => c.displayName))
    // Update the Form context if the componentMap changes after the Form is initiated
    if (prevComponentMap !== newComponentMap) {
      this.setState({
        FORM_PROPERTIES: {
          componentMap: {
            ...defaultComponentMap(),
            ...componentMap,
          },
          customNodeComponentRules: customNodeComponentRules || (() => null),
        },
      })
    }
  }

  initializeForm = () => {
    const { debounced, defaultData, defaultSchema, editDecoration, formData, formId } = this.props

    const clonedSchema = cloneDeep(defaultSchema)
    const clonedData = cloneDeep(defaultData)

    // 🎄Decorate the schema nodes with meta data
    const decoratedSchema = decorateSchema(clonedSchema, {
      editDecoration,
      debounced,
    })
    // 📝 Fill in any missing data defined in the schema with a default value
    const decoratedDefaultData = decorateFormData(decoratedSchema, clonedData)
    const decoratedFormData = formData
      ? decorateFormData(decoratedSchema, formData)
      : decoratedDefaultData
    // Create the entity maps for the form store values: { defaultById, dirtyById, invalidById, valueById }
    const { defaultById, dirtyById, invalidById, schemaById, valueById } = compileEntityMaps(
      decoratedSchema,
      decoratedFormData,
      formId,
      decoratedDefaultData,
      {
        withSchemaMap: true,
      },
    )

    // ✅ Do an preliminary check to determine if the data initial data is valid
    this.validate = ajv.compile({ type: 'object', properties: schemaById })
    this.validate(valueById)

    this.setState({
      defaultById,
      dirtyById,
      invalidById,
      valueById,
      formSchema: decoratedSchema,
      formInvalid: getFirstErrorMessage(this.validate.errors),
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    const { defaultSchema, formId, onSubmit } = this.props
    const { valueById } = this.state
    const newData = compileFormValue(defaultSchema, valueById, formId)

    // Handle submitting with data
    onSubmit(newData, e)
  }

  updateFormContext = ({ materializedPath, value, valid }) => {
    const { defaultById, formSchema, dirtyById, formInvalid, invalidById, valueById } = this.state

    const newState = {
      defaultById: { ...defaultById },
      dirtyById: { ...dirtyById },
      formInvalid,
      invalidById: { ...invalidById },
      valueById: {
        ...valueById,
        [materializedPath]: value,
      },
    }

    // ✅ Update validation status
    // Set the validity of the updated node

    // TODO: Duplicate the logic in the form reducer related to validating nested
    // data, and checking/validating parent paths. Can be found in form.js in the
    // UPDATE_FORM_DATA reducer.

    if (valid === true) {
      delete newState.invalidById[materializedPath]
    } else {
      // Add the errors messages array
      newState.invalidById[materializedPath] = valid
    }
    // Set the validity of the full form
    this.validate(newState.valueById)
    newState.formInvalid = getFirstErrorMessage(this.validate.errors)

    // 🛁 Update dirty status
    if (
      isValueDirty({
        type: typeof value,
        value,
        valueDefault: defaultById[materializedPath],
        compareNullValues: shouldCompareNullValues({
          // Materialized path without the root
          materializedPath: materializedPath
            .split(',')
            .slice(1)
            .join(','),
          node: formSchema,
        }),
      })
    ) {
      newState.dirtyById[materializedPath] = true
    } else {
      delete newState.dirtyById[materializedPath]
    }

    this.setState({ ...newState })
  }

  // `addArrayItem` can accept a single value or multiple values in an array. If the
  // array is passed in with the `batch` flag, then existing values will be replaced,
  // otherwise values will be added to the end of the original array.
  addArrayItem = ({ batch = false, value, materializedPath }) => {
    const { defaultById, dirtyById, formSchema, valueById } = this.state
    let newState = { dirtyById, valueById }

    const valueArray = Array.isArray(value) ? value : [value]
    const count = valueById[materializedPath]
    // The value supplied in the payload of a `batch` add should always represent
    // the full array of data, INCLUDING previous draft rows if desired.
    const newArrayCount = batch ? value.length : valueArray.length + count

    valueArray.forEach((newItemValue, idx) => {
      const newItemPath = batch ? materializedPath : `${materializedPath},${count + idx}`
      const itemSchema = getSchemaNode(formSchema, newItemPath)
      const newItemEntityMaps = compileEntityMaps(
        itemSchema, // schema
        newItemValue || createNodeDefaultValue(itemSchema), // data
        newItemPath, // path
        batch ? [] : null, // default data
      )

      newState = {
        dirtyById: {
          ...newState.dirtyById,
          // 🐛 TODO: the dirty value isn't initializing properly when creating the
          // entity values for new array items, it's not a huge deal b/c the array
          // length is correctly being set as dirty... but we should fix it
          ...newItemEntityMaps.dirtyById,
        },
        valueById: {
          ...newState.valueById,
          ...newItemEntityMaps.valueById,
          [materializedPath]: newArrayCount,
        },
      }
      if (newArrayCount === defaultById[materializedPath]) {
        delete newState.dirtyById[materializedPath]
      } else {
        newState.dirtyById[materializedPath] = true
      }
    })

    this.setState({ ...newState })
  }

  removeArrayItem = ({ materializedPath, clearArray = false }) => {
    const { defaultById, dirtyById, invalidById, valueById } = this.state

    const removeIndex = materializedPath.split(',').pop() // Grab index
    const arrayPath = materializedPath.replace(/,(?!.*,).*/, '') // Remove index (the last comma to the end)
    const newArrayCount = valueById[arrayPath] - 1
    const newArrayPath = `${arrayPath},${newArrayCount}`

    let newState
    if (clearArray) {
      newState = {
        dirtyById: removeAllEntities(materializedPath, dirtyById),
        invalidById: removeAllEntities(materializedPath, invalidById),
        valueById: {
          ...removeAllEntities(materializedPath, valueById),
          [materializedPath]: 0,
        },
      }
    } else {
      newState = {
        dirtyById: removeEntity(arrayPath, removeIndex, dirtyById),
        invalidById: removeEntity(arrayPath, removeIndex, invalidById),
        valueById: {
          ...removeEntity(arrayPath, removeIndex, valueById),
          [arrayPath]: newArrayCount,
        },
      }
    }
    if (newArrayCount === defaultById[arrayPath]) {
      delete newState.dirtyById[arrayPath]
      // This makes sure items are removed properly in case they weren't removed by removeEntity
      delete newState.dirtyById[newArrayPath]
    } else {
      newState.dirtyById[arrayPath] = true
    }
    this.setState({ ...newState })
  }

  renderFormContents = () => {
    const { children, formId } = this.props
    const { defaultById, dirtyById, formInvalid, invalidById, valueById } = this.state

    // FACC Form Rendering
    if (typeof children === 'function') {
      return children({
        defaultById,
        dirtyById,
        formInvalid,
        invalidById,
        valueById,
      })
    }

    // Manual Form rendering
    if (children) {
      return children
    }

    // Default Form & Submit button rendering
    return (
      <>
        <SchemaNode materializedPath={formId} />
        <button
          className="btn btn-primary mt-3"
          onClick={this.handleSubmit}
          disabled={!!formInvalid}
          type="submit"
        >
          Submit
        </button>
      </>
    )
  }

  render() {
    const {
      // 🙅‍♀️ You shall not pass!
      children,
      componentMap,
      customNodeComponentRules,
      debounced,
      defaultData,
      defaultSchema,
      editDecoration,
      formId,
      formData,
      onSubmit,
      ...dom
    } = this.props
    const { formSchema } = this.state

    if (!formSchema) return null // Wait for the calculations in componentDidMount

    return (
      <FormContext.Provider value={this.state}>
        <form onSubmit={this.handleSubmit} noValidate data-test="form" {...dom}>
          {this.renderFormContents()}
        </form>
      </FormContext.Provider>
    )
  }
}

export default FormProvider
