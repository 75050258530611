import React from 'react'
import classNames from 'classnames'

import { component } from './mock-hsq-sticky-headers.scss'

/**
 * MockHsqStickyHeaders displays sticky headers that mimic those found in healthsparq-ui
 *
 * HSQ-UI has 3 different sticky header situations depending on media breakpoints.
 * 1. SmallDown has a small sticky header
 * 2. Medium has none
 * 3. LargeUp has a larger sticky nav
 *
 */
const HsqHeaders = () => (
  <div
    className={classNames(
      component,
      'scoped-hippo-styles align-items-center justify-content-center d-flex position-sticky',
    )}
  >
    <h6 className="h7 m-0 text-white">Mock HSQ Sticky Header</h6>
  </div>
)

export default HsqHeaders
