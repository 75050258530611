export default function currency(num) {
  const parsed = parseFloat(num)
    .toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    })
    // This is non-cents!
    .replace(/\.00$/, '')
  // `toLocaleString` of `NaN` returns the string `"$NaN"`
  if (parsed === '$NaN') return null
  return parsed
}
