/* eslint-disable no-continue, no-restricted-syntax */
import { disableBodyScroll, enableBodyScroll } from '../scroll-lock'
import deprecate from '../deprecate'
import trapTab from '../trap-tab'

let dialogCount = 0

/**
 * In order to be A++ accessible we need to ensure that we are hiding all elements that do not
 * house this element. Siblings, uncles, aunts. Anything that isn't a direct ancestor of the dialog.
 * We start from the `document.body` and walk ourselves adding `aria-hidden` to all the extended
 * family along the way.
 * @param {Element} dialog
 */
export const hideElementsForA11y = dialog => {
  let currentNode = document.body
  // `document.body` does not exist in tests.
  while (currentNode && currentNode !== dialog) {
    let nextNode
    for (const child of currentNode.children) {
      // IE only supports `.contains` on HTMLElements
      if (document.documentMode && !(child instanceof HTMLElement)) continue
      // Don't bother.
      if (['style', 'script', 'svg'].includes(child.tagName.toLowerCase())) continue
      // This element is a member of the royal bloodline.
      if (child.contains(dialog)) {
        nextNode = child
        continue
      }
      // Snag the current `display` and `visibility` and assign them aggressively (`!important`) to
      // the `cssText` so that our `[aria-hidden]` css rules do not hide these elements.
      const { display, visibility } = getComputedStyle(child)
      const { cssText } = child.style
      const { dataset } = child

      child.style.cssText = `${cssText}; display: ${display} !important; visibility: ${visibility} !important`
      let ariaHidden = child.getAttribute('aria-hidden') || ''
      if ('previouslySetAriaHidden' in dataset) {
        // This makes sure that, if a dialog is triggered from a different dialog, we always go back to the original
        // aria-hidden value (instead of making it aria-hidden because the last dialog hid it... 🚫🔄 )
        ariaHidden = dataset.previouslySetAriaHidden
      }
      child.setAttribute('aria-hidden', 'true')

      // Store the original `cssText` and `aria-hidden` attributes so we can return them to the
      // original state. This also has the added benefit of creating a simple selector to target
      // all of these elements inside `showElementsForA11y`
      Object.assign(child.dataset, {
        cssText,
        previouslySetAriaHidden: ariaHidden,
      })
    }
    currentNode = nextNode
  }
}

/**
 * Reset the state of the hidden elements from `hideElementsForA11y`
 */
export const showElementsForA11y = () => {
  // See comment in `hideElementsForA11y`
  for (const el of document.querySelectorAll('[data-css-text]')) {
    const { dataset } = el
    const { cssText, previouslySetAriaHidden } = dataset
    delete dataset.cssText
    delete dataset.previouslySetAriaHidden

    if (previouslySetAriaHidden)
      el.setAttribute('aria-hidden', previouslySetAriaHidden)
    else el.removeAttribute('aria-hidden')
    if (cssText) el.style.cssText = cssText
    // @NOTE does not actually remove the `el.style` object. Just the attribute from the `.outerHTML`
    else el.removeAttribute('style')
  }
}

/**
 * Single constructor to be added to each instance of a "dialog" component. This is not inside
 * the `Dialog` component so we can normalize behaviors between hsq-ui `sidebar` and the `Dialog`
 * component.
 *
 * Each iteration of the component should have its own `new DialogUtils()`. This is done so a single
 * instance does not receive multiple dialogs to track. This _probably_ isn't necessary because we
 * should not be showing dialogs on top of other dialogs but... we have in the past. Yikes.
 *
 */
export class DialogUtils {
  /**
   * The element to return to when the dialog has closed
   */
  previouslyActiveElement = null

  /**
   * The element that is the dialog
   */
  dialog = null

  /**
   * Unique ID used to parse out history events and states.
   */
  dialogId = null

  /**
   * Method to remove popstate listener that gets added in mobile. Needs to be on the instance
   * because it can be removed from multiple places.
   */
  removePopstateListener = null

  /**
   * Just a semantically named property that is computed based on dialog. The reference to the dialog
   * element is removed on close.
   */
  get isOpen() {
    return !!this.dialog
  }

  /**
   *
   * @param {Object} options hash of options
   * @param {HTMLElement} options.dialog Element to target as the dialog
   * @param {Function} options.close  Function to be called on Escape and browser back
   * @param {Boolean} options.bindEscape
   * @param {Boolean} options.bindHistory
   */
  open = options => {
    deprecate({
      name: 'DialogUtils.open parameter[0] instance of HTMLElement',
      value: options instanceof HTMLElement,
      message:
        'DialogUtils now accepts an object of options with the `dialog` element itself as `options.dialog`',
    })
    const { close } = options

    const dialog = options instanceof HTMLElement ? options : options.dialog
    this.dialog = dialog

    if (close && typeof close === 'function') this.setupListeners(options)
    this.previouslyActiveElement = document.activeElement
    disableBodyScroll(dialog)
    dialog.addEventListener('keydown', trapTab)
    dialog.focus()
    hideElementsForA11y(dialog)
  }

  close = () => {
    const { dialog, previouslyActiveElement, dialogId } = this
    // this was invoked w/o invoking `open`. just bail.
    if (!dialog) return
    // We don't need to trigger any listener because cleanup is already happening in this method
    this.removePopstateListener?.()
    // This dialog was closed w/o any browser navigation, so take user back to original location in browser history.
    if (window.history.state?.dialogId === dialogId) {
      window.history.back()
    }
    dialog.removeEventListener('keydown', trapTab)
    showElementsForA11y(dialog)
    enableBodyScroll()
    // if the `Dialog` is removed before this element opens, there will be no `previouslyActiveElement`
    if (previouslyActiveElement) {
      // refocus only if the previous element is still there. Otherwise focus the body.
      if (document.body.contains(previouslyActiveElement)) {
        previouslyActiveElement.focus()
      } else document.body.focus()
    }
    this.dialog = null
    this.previouslyActiveElement = null
  }

  setupListeners = ({ bindEscape = true, bindHistory = true, close }) => {
    const { dialog } = this
    // Used in the history navigation(s)
    // eslint-disable-next-line no-plusplus
    const dialogId = dialog.id || `dialog-${++dialogCount}`
    this.dialogId = dialogId
    if (bindEscape) {
      const closeOnEsc = evt => {
        if (evt.key === 'Escape') {
          close()
          dialog.removeEventListener('keydown', closeOnEsc)
        }
      }
      dialog.addEventListener('keydown', closeOnEsc)
    }
    // @TODO Figure out how to make this behave with react router history API
    if (bindHistory) {
      /**
       * Wire up the dialog to close on browser back button.
       */
      window.history.pushState({ dialogId }, 'Dialog History', '')
      const closeOnPopState = evt => {
        // If the event state has this dialogId, then there was another entry that was added over this
        // one (likely another dialog). Do not close it if this dialog is still open.
        if (evt.state?.dialogId === dialogId && this.isOpen) return
        // If state does not match, and the dialog is open, close this dialog.
        // @BUG there is an issue here where if there was another history entity on top of this, and
        // the user goes forward instead of back, the dialog will close. 🤷‍♂️
        if (this.isOpen) close()
        // This dialog was closed out of sync with another dialog's opening meaning it has written
        // a history entry that has not been cleared. Clear it.
        else window.history.back()
        this.removePopstateListener?.()
      }
      this.removePopstateListener = () => {
        window.removeEventListener('popstate', closeOnPopState)
        this.removePopstateListener = null
      }
      window.addEventListener('popstate', closeOnPopState)
    }
  }
}
