import React, { useRef, useEffect, useState } from 'react'
import { bool, func, node, oneOfType, string } from 'prop-types'

import { DialogUtils } from '../../utils'

/**
 * Handy Dandy Dialog component for whenever you have an element that is supposed to behave like
 * a dialog. Really just handles invoking an instance of the `DialogUtils` methods when an `active`
 * prop is passed
 */

const Dialog = ({
  active,
  children,
  close,
  bindEscape,
  // @NOTE bindHistory currently not used... but we don't want it assigned to a DOM element
  bindHistory,
  ...rest
}) => {
  const dialogRef = useRef()
  const [dialogUtils] = useState(new DialogUtils())

  useEffect(() => {
    if (active) {
      const { current: dialog } = dialogRef || {}
      dialogUtils.open({ dialog, close, bindEscape, bindHistory: false })
    } else dialogUtils.close()
    return dialogUtils.close
  }, [active])

  return (
    <div
      ref={dialogRef}
      tabIndex="-1"
      aria-hidden={!active}
      role="dialog"
      {...rest}
    >
      {children}
    </div>
  )
}

Dialog.propTypes = {
  active: bool,
  bindEscape: bool,
  bindHistory: bool,
  children: oneOfType([node, string]),
  close: func,
}

Dialog.defaultProps = {
  active: false,
  bindEscape: undefined,
  bindHistory: undefined,
  children: null,
  close: undefined,
}

export default Dialog
