import React, { useEffect, useState } from 'react'
import { node, shape } from 'prop-types'

import debounce from 'lodash.debounce'

import MediaBreakpointContext from './Context'

/**
 *  Helper to generate an object that has all the keys in `queries` with values of false
 */
const getDefaultQueryMatch = queries => {
  const defaultQueryMatch = {}
  Object.keys(queries).forEach(key => {
    defaultQueryMatch[key] = false
  })
  return defaultQueryMatch
}

/**
 * MediaBreakpointProvider
 * Context Provider that uses a custom React hook to check media breakpoints
 *
 * @param queries       Optional object defining the breakpoints to watch
 */
const MediaBreakpointProvider = ({ children, queries }) => {
  // State in which we maintain our matching query
  const [queryMatch, setQueryMatch] = useState(getDefaultQueryMatch(queries))

  useEffect(() => {
    const mediaQueryLists = {}
    const mediaSizes = Object.keys(queries)
    // To track whether the event listeners are attached or not for cleanup
    let isAttached = false

    // Debouncing this makes it so it doesn't update state multiple times for a single breakpoint change
    // i.e. because things like `smallUp` & `medium` change together
    const handleQueryListener = debounce(() => {
      const updatedMatches = mediaSizes.reduce((acc, media) => {
        acc[media] = !!(mediaQueryLists[media] && mediaQueryLists[media].matches)
        return acc
      }, {})

      // Set state to the updated matches when the window either starts or stops matching a query
      setQueryMatch(updatedMatches)
    }, 150)

    if (window && window.matchMedia) {
      // Set the state to the initial matching queries
      const matches = {}
      mediaSizes.forEach(media => {
        mediaQueryLists[media] = window.matchMedia(queries[media])
        matches[media] = mediaQueryLists[media].matches
      })
      setQueryMatch(matches)

      // Attach the listeners to the mediaMatch elements directly. Then, instead of updating on
      // a generic function like `resize`, it only updates only when the matchMedia elements change.
      isAttached = true
      mediaSizes.forEach(media => {
        mediaQueryLists[media].addListener(handleQueryListener)
      })
    } else {
      // This is a just precaution, it shouldn't happen: https://caniuse.com/#search=matchMedia
      // eslint-disable-next-line no-console
      console.warn('This browser does not support the mobile view of this site')
    }

    // When the component unmounts, remove the event listeners if they exist
    return () => {
      if (isAttached) {
        mediaSizes.forEach(media => {
          mediaQueryLists[media].removeListener(handleQueryListener)
        })
      }
    }
  }, [queries]) // Only re-run the effect if the defined queries change

  return (
    <MediaBreakpointContext.Provider value={queryMatch}>{children}</MediaBreakpointContext.Provider>
  )
}

const propTypes = {
  children: node.isRequired,
  queries: shape(),
}

const defaultProps = {
  queries: {
    // Bootstrap-based query sizes
    xsmall: '(max-width: 575px)',
    xsmallUp: '(min-width: 575px)',
    small: '(min-width: 575px) and (max-width: 767px)',
    smallDown: '(max-width: 767px)',
    smallUp: '(min-width: 767px)',
    mediumDown: '(max-width: 991px)',
    medium: '(min-width: 767px) and (max-width: 991px)',
    mediumUp: '(min-width: 991px)',
    large: '(min-width: 991px) and (max-width: 1199px)',
    largeUp: '(min-width: 1199px)',
    xlarge: '(min-width: 1199px) and (max-width: 1440px)',
    xlargeUp: '(min-width: 1440px)',
  },
}

MediaBreakpointProvider.propTypes = propTypes
MediaBreakpointProvider.defaultProps = defaultProps
export default MediaBreakpointProvider
