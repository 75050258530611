import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { bool, func, string, node, oneOf } from 'prop-types'

import { component } from './sidebar.scss'
import SidebarHeader from './Header'
import SidebarFooter from './Footer'
import SidebarBody from './Body'
import SidebarContent from './Content'
import Dialog from '../Dialog'
import deprecate from '../../utils/deprecate'

/**
 * Sidebar
 * - Modal component that shows from right or bottom of the viewpoint
 *
 */
const Sidebar = ({
  bindEscape,
  bindHistory,
  children,
  className,
  closeSidebar,
  hideScrim,
  id,
  isShaking,
  onHidden,
  onShakeEnd,
  open,
  position,
  sidebarBackground,
}) => {
  deprecate({
    // I don't think this was ever used, but don't know all the places the razzle sidebar was consumed.
    name: 'sidebarBackground',
    value: sidebarBackground,
    message: 'Pass in `bg-[BACKGROUND]` as a `className` directly',
  })

  const classList = className.split(' ')

  if (classList.includes('small')) {
    deprecate({
      name: 'className `.small`',
      value: className,
      message:
        '`.small` is a bootstrap class that sets font-size and can have unanticipated inheritance. Use `.sidebar-small`',
    })
  }

  const scrollRef = useRef()
  const [isActive, setIsActive] = useState(false)
  const [clickInitiatedInDialog, setClickInitiatedInDialog] = useState(false)
  useEffect(() => {
    if (open) setIsActive(true)
  }, [open])

  // call `closeSidebar` when element is torn down
  useEffect(() => closeSidebar, [])

  const isClosing = isActive && !open
  const isDrawer = position.includes('bottom')
  // Scrim Props
  // These props go on the element that is the `scrim`
  //----------------------------------------------------------------------------
  // Bound to the `.scrim`s. It allows to invoke `closeSidebar` only when the scrim and not any
  // children are clicked.
  const closeOnScrimClick = evt => {
    if (evt.target === evt.currentTarget && !clickInitiatedInDialog) {
      closeSidebar(evt)
    }
  }
  const scrimClassNames = classNames('sidebar-scrim', {
    'is-closing': isClosing,
    'sidebar-dialog-bottom': isDrawer,
    'invisible-scrim': hideScrim,
  })
  const scrimProps = {
    'data-test': 'sidebar-scrim',
    className: scrimClassNames,
    onClick: closeOnScrimClick,
    role: 'presentation',
    onMouseDown: () => setClickInitiatedInDialog(false),
  }

  // Content Props
  // These classes go on the element that houses the `content`.
  //----------------------------------------------------------------------------
  const contentClassNames = classNames(className, {
    'is-closing': isClosing,
    'sidebar-panel': isDrawer,
    'partial-panel': position === 'partial-bottom',
    'sidebar-dialog-right': position === 'right',
    'sidebar-dialog-left': position === 'left',
    shaking: isShaking,
  })
  // Handles letting the animation finish before hiding the dialog. Also handles invoking `onShakeEnd`
  const handleAnimations = evt => {
    // Don't care about propagated events
    if (evt.target !== evt.currentTarget) return
    if (evt.animationName.startsWith('SLIDE_OUT')) {
      if (scrollRef.current) scrollRef.current.scrollIntoView({ block: 'start' })
      setIsActive(false)
      if (onHidden) onHidden()
      return
    }
    // @TODO investigate using `isLocked` paradigm instead of `isShaking`/`onShakeEnd`. Issue would
    // be Sidebar.Header not communicating `closeSidebar` to this component's scope.
    if (evt.animationName === 'shake') onShakeEnd()
  }

  const contentProps = {
    className: contentClassNames,
    id,
    onAnimationEnd: handleAnimations,
    'data-test': 'sidebar-content',
    onMouseDown: evt => {
      setClickInitiatedInDialog(true)
      evt.stopPropagation()
    },
  }

  return (
    <div
      className={classNames(component, { active: isActive })}
      data-test="sidebar-wrapper"
    >
      {isDrawer ? (
        /**
         * In the "drawer", the `dialog` is the `scrim`. This is because a11y requires the focusable
         * element to also have the scroll, and the `dialog` to be the focused element. In this
         * case, the content scrolls across the scrim like some straight up Star Wars ish. It
         * doesn't quiet make sense from a semantic viewpoint, but it keeps a11y happy and it
         * ultimately behaves the same.
         *
         * Drawer order of elements
         * Wrapper -> Dialog/Scrim -> Content
         */

        <Dialog
          active={isActive}
          close={closeSidebar}
          bindEscape={bindEscape}
          bindHistory={bindHistory}
          {...scrimProps}
        >
          <span ref={scrollRef} />
          <div {...contentProps}>{children}</div>
        </Dialog>
      ) : (
        /**
         * Sidebar is really just a fancier modal. The dialog houses the content in a sane fashion.
         *
         * Sidebar order of elements
         * Wrapper -> Scrim -> Dialog/Content
         */
        <div {...scrimProps}>
          <Dialog
            active={isActive}
            close={closeSidebar}
            bindEscape={bindEscape}
            bindHistory={bindHistory}
            {...contentProps}
          >
            {children}
          </Dialog>
        </div>
      )}
    </div>
  )
}

Sidebar.propTypes = {
  bindEscape: bool,
  bindHistory: bool,
  children: node,
  className: string,
  closeSidebar: func.isRequired,
  hideScrim: bool,
  id: string,
  isShaking: bool,
  onHidden: func,
  onShakeEnd: func,
  open: bool.isRequired,
  position: oneOf(['bottom', 'partial-bottom', 'right', 'left']),
  sidebarBackground: string,
}

Sidebar.defaultProps = {
  bindEscape: undefined,
  bindHistory: undefined,
  children: null,
  className: '',
  hideScrim: false,
  id: undefined,
  isShaking: false,
  onHidden: () => {},
  onShakeEnd: () => {},
  position: 'right',
  sidebarBackground: null,
}
Sidebar.displayName = 'Sidebar'

Sidebar.Body = SidebarBody
Sidebar.Content = SidebarContent
Sidebar.Footer = SidebarFooter
Sidebar.Header = SidebarHeader

export default Sidebar
