import { useLoc } from '@hsq/magic-loc'

/**
 * A way for razzle components to localize content safely if possible. This way these component are
 * not _required_ to be in a translations provider (e.g. in hub). Should always be used with a
 * backupText to ensure content is always rendered.
 *
 * @param {String} key
 * @param {Object} options
 * @param {Array.<String>} options.params
 * @param {String} options.backupText
 */
export default function safeLoc(key, { params, backupText } = {}) {
  let content
  let context
  try {
    context = useLoc()
    content = context.getLoc(key, params)
  } catch {
    // reset context to null this means the method was invoked w/o a parent `TranslationsProvider`.
    context = null
  }
  // OUTDATED: Return based on the `context`. This way if the context is in place, but the key is not found,
  // we don't render the backupText. This should help with debugging missing locs.
  // TODO: Talk to Austin about another way to handle the above use case. Trying to avoid having dev look
  // broken while we waiting for new translations to be approved.
  if (context) return content || backupText
  return backupText
}
