import React, { Component } from 'react'
import { oneOfType, node, object, string } from 'prop-types'

import ErrorMessage from '../ErrorMessage'

class ErrorBoundary extends Component {
  static displayName = 'ErrorBoundary'

  static propTypes = {
    /* eslint-disable react/forbid-prop-types */
    children: oneOfType([node, object]),
    className: string,
    customErrorComponent: node,
    customMessage: string,
  }

  static defaultProps = {
    children: null,
    className: '',
    customErrorComponent: null,
    customMessage: '',
  }

  state = { hasError: false }

  componentDidCatch() {
    // Also have access to (error, info) & info.componentStack has error call stack
    this.setState({ hasError: true })
  }

  render() {
    const { className, customErrorComponent, customMessage, children } = this.props
    const { hasError } = this.state

    if (hasError) {
      return (
        customErrorComponent || (
          <ErrorMessage message={customMessage} className={className} />
        )
      )
    }
    return children
  }
}

export default ErrorBoundary
