import React, { useEffect, useState } from 'react'
import { bool, func, shape, string } from 'prop-types'
import classNames from 'classnames'
import Input from '../Input'

import { component } from './json-input.scss'
import { Button } from '../../..'

/**
 * Custom JSON input component that validates the JSON string onChange and formats
 * the JSON on button click.
 */

const JsonInput = props => {
  const { formatQuotes, label, onChange, rows, value } = props

  const [localValue, setLocalValue] = useState(JSON.stringify(value, null, 2))
  const [jsonFieldInvalid, setJsonFieldInvalid] = useState('')

  useEffect(() => {
    // Compare the two objects to see if they're the same, not including whitespace
    try {
      if (
        JSON.stringify(JSON.parse(localValue)) &&
        JSON.stringify(value) !== JSON.stringify(JSON.parse(localValue))
      ) {
        setLocalValue(JSON.stringify(value, null, 2))
      }
    } catch {
      setJsonFieldInvalid('Could not parse invalid JSON')
    }
  }, [value])

  const validateJson = e => {
    setLocalValue(e.target.value)
    try {
      JSON.parse(e.target.value)
      setJsonFieldInvalid('')
      onChange({ ...e, value: JSON.parse(e.target.value) })
    } catch {
      setJsonFieldInvalid('Please enter valid JSON')
    }
  }

  const formatJson = () => {
    setLocalValue(JSON.stringify(JSON.parse(localValue), null, 2))
  }

  const cleanJsonQuotes = () => {
    const cleanQuotes = localValue.replace(/[\u201C\u201D]/g, '"')
    setLocalValue(JSON.stringify(JSON.parse(cleanQuotes), null, 2))
    setJsonFieldInvalid('')
  }

  return (
    <div className={classNames(component, 'mt-3 position-relative')}>
      <Button
        className="format-json-button"
        color="secondary"
        disabled={jsonFieldInvalid}
        onClick={formatJson}
        size="small"
      >
        Format JSON
      </Button>
      {formatQuotes && (
        <Button
          className="ml-2"
          color="secondary"
          onClick={cleanJsonQuotes}
          size="small"
        >
          Format Quotes
        </Button>
      )}
      <Input
        autoComplete="off"
        className="mt-4"
        inputType="textarea"
        invalid={jsonFieldInvalid}
        label={label}
        onChange={validateJson}
        rows={rows}
        spellCheck="false"
        value={localValue}
      />
    </div>
  )
}

JsonInput.propTypes = {
  formatQuotes: bool,
  label: string.isRequired,
  onChange: func.isRequired,
  rows: string,
  value: shape({}).isRequired,
}
JsonInput.defaultProps = {
  formatQuotes: false,
  rows: '3',
}
JsonInput.displayName = 'JsonInput'

export default JsonInput
