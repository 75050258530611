let returnScroll = null

/**
 * Simple wrapper to add the `fixed-body` class to disable body scroll and to return the user to
 * their original scroll position when applicable. The latter part is mostly to combat an older iOS
 * bug where adding `overflow-hidden` to the `body` did not prevent the body from scrolling.
 * Combine this with iOS's scroll-on-focus behavior for `input` elements and we have bad things
 * happening in sidebars.
 */
export const disableBodyScroll = () => {
  document.body.classList.add('fixed-body')
  const { scrollingElement = document.body } = document

  if (
    // check window for testing porpoises
    window &&
    window.matchMedia &&
    window.matchMedia('(max-width: 767px)').matches
  ) {
    returnScroll = scrollingElement.scrollTop
  }
}

export const enableBodyScroll = () => {
  document.body.classList.remove('fixed-body')
  const { scrollingElement = document.body } = document
  if (returnScroll || returnScroll === 0) scrollingElement.scrollTop = returnScroll
  returnScroll = null
}
