/**
 * Set of base rules used to assign each schema node to a form component type
 * @param {Boolean} base Indicates if a base component should be rendered (i.e. no extra decoration or padding)
 * @param {Array} enumerables Set of limited options for the node value
 * @param {String} type Atomic data type
 */
const defaultNodeComponentRules = schemaNode => {
  const { items, type, enumerables, properties } = schemaNode

  if (type === 'array' && !Array.isArray(items) && items.type === 'string') {
    return 'MultiSelect'
  }

  switch (type) {
    case 'object':
      return properties ? 'FormObject' : 'JsonInput'
    case 'array':
      return 'FormArray'
    case 'string':
    case 'number':
    case 'integer':
      return enumerables ? 'Radio' : 'Input'
    case 'boolean':
      return 'Toggle'
    default:
      return 'div'
  }
}

export default defaultNodeComponentRules
