import React from 'react'
import { bool, func, number, string } from 'prop-types'
import classNames from 'classnames'

import { component } from './pill-badge.scss'

const propTypes = {
  className: string,
  color: string,
  condensed: bool,
  hidden: bool,
  idx: number,
  pill: bool,
  setPillRef: func,
  text: string.isRequired,
  truncated: bool,
}

const defaultProps = {
  color: 'muted',
  condensed: false,
  className: '',
  hidden: false,
  setPillRef: () => {},
  idx: 0,
  pill: false,
  truncated: true,
}

function PillBadge(props) {
  const { color, condensed, className, hidden, idx, pill, text, truncated } = props

  return (
    <div
      className={classNames(
        component,
        className,
        `badge badge-${color} align-self-centered font-size-normal font-weight-semibold`,
        { 'badge-pill': pill, condensed, truncated },
      )}
      aria-hidden={hidden}
      ref={el => props.setPillRef(el, idx)}
      title={text}
    >
      {text}
    </div>
  )
}

PillBadge.displayName = 'PillBadge'
PillBadge.propTypes = propTypes
PillBadge.defaultProps = defaultProps
export default PillBadge
