import React, { useRef, useState, useLayoutEffect } from 'react'
import { bool, func, number, string } from 'prop-types'
import classNames from 'classnames'
import nanoid from 'nanoid'

import { Button, Icon } from '../..'
import { component } from './Disclaimer.local.scss'
import safeLoc from '../../utils/safeLoc'
import debounce from '../../utils/debounce'

const getCleanType = type => {
  const cleanType = (type || '').toUpperCase()
  if (cleanType === 'DANGER') return 'ALERT'
  // This basically denotes that it is a check-mark and also that it shouldn't have a header. 🤷‍♂️
  // Maybe it should be `icon` if we actually want this functionality to be scalable
  if (cleanType === 'CHECKMARK-SUCCESS') return 'SUCCESS'
  return cleanType
}

export const getIcon = (type = '') => {
  switch (type.toLowerCase()) {
    case 'warning':
    case 'alert':
      return 'warning-fill'
    case 'success':
      return 'check-circle-fill'
    default:
      return null
  }
}

// TODO: Possibly allow an array of disclaimers to localize the special styles for stacked disclaimers

/**
 * Disclaimer
 * Simple banner footer to add to cards
 *
 * @param {String} disclaimerId       Use if the disclaimer needs to be linked to an aria-describedby somewhere else on the page
 * @param {String} disclaimerIndex    Adds a superscript number of the `disclaimerIndex` + 1
 * @param {Function} closeSidebar     Must be passed in from consuming application to avoid circular dependencies since @hsq/global-sidebar imports razzle
 * @param {FUnction} openSidebar      Must be passed in from consuming application to avoid circular dependencies since @hsq/global-sidebar imports razzle
 */
const Disclaimer = ({
  ariaLabel,
  className,
  closeSidebar,
  disclaimerId,
  disclaimerIndex,
  header,
  icon,
  isMobile,
  openSidebar,
  overrideDrawer,
  renderDrawerBody,
  text,
  type,
}) => {
  if (!type && !header) return null

  const [overflowingText, setOverflowingText] = useState(true)
  const textRef = useRef()
  const cleanType = getCleanType(type)
  const computedIcon = icon || getIcon(cleanType)
  const computedHeader =
    header || safeLoc(`GLO_disclaimerType${cleanType}`, { backupText: cleanType })
  useLayoutEffect(() => {
    // Create a debounced function to recheck if the text is overflowing when the window is resized
    const checkOverflowOnResize = debounce(() => {
      if (textRef.current) {
        setOverflowingText(
          textRef.current.offsetWidth < textRef.current.scrollWidth,
        )
      }
    }, 300)
    window.addEventListener('resize', checkOverflowOnResize)

    return () => {
      window.removeEventListener('resize', checkOverflowOnResize)
    }
  }, [])

  useLayoutEffect(() => {
    // 1. Check if the text is overflowing whenever the ref updates (may be more than just on mount, so this needs it's own effect)
    if (textRef.current) {
      setOverflowingText(textRef.current.offsetWidth < textRef.current.scrollWidth)
    }
  }, [textRef.current])

  const openTextInDrawer = () =>
    openSidebar({
      Component: () => (
        <div>
          <header className="d-flex justify-content-between p-4 bg-gray14">
            <h3 className="mb-0">{computedHeader}</h3>
            <Button className="h3 btn btn-anchor" onClick={closeSidebar}>
              {safeLoc('GLO_close', { backupText: 'Close' })}
            </Button>
          </header>
          <div className="p-4">
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: text }}
            />
            {renderDrawerBody && renderDrawerBody()}
          </div>
        </div>
      ),
      sidebarOptions: { position: 'bottom' },
    })

  const moreDetailsId = nanoid(6)
  const containsEmbeddedHTML = /<\/?[a-z][\s\S]*>/i.test(text)
  const desktopWithText = text && !containsEmbeddedHTML && !isMobile
  const mobileWithText =
    text && isMobile && !containsEmbeddedHTML && !overflowingText

  return (
    <div
      className={classNames(component, className, 'd-flex align-items-center')}
      id={disclaimerId || undefined}
    >
      {computedIcon && (
        <div className="d-inline-block position-relative">
          <div className="d-flex align-items-center">
            <Icon
              id={computedIcon}
              className={classNames(
                // normalize `alert` back to `danger` for Twitter Bootstrap™
                `text-${
                  cleanType === 'alert' ? 'danger' : cleanType.toLowerCase()
                }`,
                'disclaimer-icon mr-2',
              )}
              font={false}
            />
          </div>
        </div>
      )}
      {!type.startsWith('checkmark') && (
        <h6 className="disclaimer-type h7 mb-0 mr-2">{computedHeader}</h6>
      )}
      {disclaimerIndex !== null && (
        <sup className="mr-1">{disclaimerIndex + 1}</sup>
      )}
      {/* PLAIN TEXT */}
      {(desktopWithText || mobileWithText) && (
        <div
          className="disclaimer-text text-truncate small"
          data-test="disclaimer-text"
          title={text}
          id={moreDetailsId}
          ref={textRef}
        >
          {text}
        </div>
      )}
      {/* DRAWER TRIGGER: MORE DETAILS */}
      {!isMobile &&
        (overflowingText || renderDrawerBody || overrideDrawer) &&
        !containsEmbeddedHTML && (
          <Button
            aria-label={`${safeLoc('RES_moreDetails', {
              backupText: 'More details',
            })}: ${ariaLabel}`}
            className="disclaimer-link-text text-nowrap font-size-sm ml-2"
            link
            onClick={overrideDrawer || openTextInDrawer}
          >
            {safeLoc('RES_moreDetails', { backupText: 'More details' })}
          </Button>
        )}
      {/* DRAWER TRIGGER: TEXT */}
      {isMobile &&
        (overflowingText || renderDrawerBody || overrideDrawer) &&
        !containsEmbeddedHTML && (
          <Button
            className="disclaimer-link-text text-nowrap text-truncate font-size-sm"
            link
            onClick={overrideDrawer || openTextInDrawer}
          >
            <div className="text-truncate" ref={textRef}>
              {text}
            </div>
          </Button>
        )}
      {/* DRAWER TRIGGER: READ NOTES (if text has embedded HTML) */}
      {containsEmbeddedHTML && (
        <Button
          aria-label={`${safeLoc('GLO_readNotes', {
            backupText: 'Read notes',
          })}: ${ariaLabel}`}
          className="disclaimer-link-text text-nowrap font-size-sm"
          data-test="read-notes-trigger"
          link
          onClick={openTextInDrawer}
        >
          {safeLoc('GLO_readNotes', { backupText: 'Read notes' })}
        </Button>
      )}
    </div>
  )
}

Disclaimer.propTypes = {
  ariaLabel: string,
  className: string,
  closeSidebar: func.isRequired,
  disclaimerId: string,
  disclaimerIndex: number,
  header: string,
  icon: string,
  isMobile: bool,
  openSidebar: func,
  overrideDrawer: func,
  renderDrawerBody: func,
  text: string,
  type: string,
}

Disclaimer.defaultProps = {
  ariaLabel: '',
  className: null,
  disclaimerId: '',
  disclaimerIndex: null,
  header: null,
  icon: null,
  isMobile: false,
  openSidebar: () => console.warn('No `openSidebar` method passed'),
  overrideDrawer: undefined,
  renderDrawerBody: null,
  text: '',
  type: '',
}

export default Disclaimer
