/**
 * Handles walking a JSON schema to the node specified by the passed path.
 * @param {Object} schema
 * @param {string} materializedPath Path to requested node
 */
const getSchemaNode = (schema, materializedPath) => {
  const schemaPaths = materializedPath.split(',').slice(1)

  // Prev is the current schema node, current is the path of next node
  return schemaPaths.reduce((prev, current) => {
    const { items, type, properties } = prev

    if (type === 'object') return properties[current]

    return Array.isArray(items) ? items[current] : items
  }, schema)
}

export default getSchemaNode
