import React from 'react'
import { bool, number } from 'prop-types'
import classNames from 'classnames'

import { component } from './linear-loading-timer.scss'

const propTypes = {
  infinite: bool,
  progress: number,
}

const defaultProps = {
  infinite: false,
  progress: null,
}

/**
 * Linear Loader
 * @method LinearLoadingTimer
 * @param  {Number}   progress  Percent completion (0-100) in 100ms increments.
 *                              (If using an increment other than 100ms, the CSS
 *                              transition time should be adjusted)
 * @param  {Boolean}  infinite  Display an infinitely scrolling loader instead of a timed one
 */
const LinearLoadingTimer = ({ infinite, progress }) => (
  <div className={classNames(component, 'loading-timer w-100 bg-gray12')}>
    {infinite ? (
      <div className="infinite-loader new h-100 w-100" />
    ) : (
      <div
        className="progress-bar h-100 bg-gray7"
        style={{ width: `${progress}%` }}
      />
    )}
  </div>
)

LinearLoadingTimer.propTypes = propTypes
LinearLoadingTimer.defaultProps = defaultProps
LinearLoadingTimer.displayName = 'LinearLoadingTimer'
export default LinearLoadingTimer
