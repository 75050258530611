import React from 'react'
import { shape } from 'prop-types'
import { Checkbox } from '@hsq/razzle-dazzle'

import compileValidationMessage from './validationHelper'

const propTypes = {
  schemaNode: shape(),
}

const defaultProps = {
  schemaNode: {},
}

/**
 * CheckboxContainer
 * Wrapper for the Checkbox form elements passed into a Formogorgon Form that
 * converts Formogorgon related props to those expected by the plain components.
 */
const CheckboxContainer = ({ schemaNode, ...rest }) => {
  const {
    // Props being used here to define the input attributes
    base,
    disabled,
    dynamicOptions = {},
    guid,
    id,
    invalid,
    label,
    materializedPath,
    readOnly, // Formogorgon flag to render a disabled form
    title,
    validationMessage, // Optional schemaNode override for invalid message from AJV
    /* Form related props that are not used here and don't get passed to the input 🙅 */
    connected,
    currentMaterializedPath,
    debounced,
    defaultValue,
    dirty,
    editDecoration,
    itemsCount,
    nullable,
    parentType,
    type,
    NodeComponent,
    uiMenuComponent,
    /* Remaining props to pass straight through to the rendered input component */
    ...propsRest
  } = {
    ...schemaNode,
    // ℹ️ Allow passed props to override the schemaNode values
    ...rest,
  }

  // dynamicOptions is the only way to  pass a prop to SchemaNode that will trigger
  // a component update when the prop changes
  const { validationOverride } = dynamicOptions

  const derivedDisabled = disabled || readOnly
  const derivedId = materializedPath || id || guid || ''
  // Get the invalid message based upon the three possible values
  const derivedInvalid = compileValidationMessage({
    invalid,
    validationMessage,
    validationOverride,
  })
  const derivedLabel =
    label || title || (materializedPath && materializedPath.split(',').pop()) || ''

  return (
    <Checkbox
      // Explicitly passing `base` through so it's clear that this is different than the Toggle/BaseToggle pattern
      base={base}
      disabled={derivedDisabled}
      id={derivedId}
      label={derivedLabel}
      invalid={derivedInvalid}
      {...propsRest}
    />
  )
}

CheckboxContainer.displayName = 'CheckboxContainer'
CheckboxContainer.defaultProps = defaultProps
CheckboxContainer.propTypes = propTypes
export default CheckboxContainer
