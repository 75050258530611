import React from 'react'
import { node, oneOfType, string } from 'prop-types'
import classNames from 'classnames'

import { Alert } from '../..'

const propTypes = {
  children: node,
  className: string,
  // Optional. Error message to display. If non is provided, defaults to
  // 'Something went wrong'
  message: oneOfType([string, node]),
}

const defaultProps = {
  className: '',
  children: undefined,
  message: 'Something went wrong.',
}

/**
 * @method ErrorMessage
 * Generic error message. Takes in an optional message
 */
const ErrorMessage = ({ children, className, message }) => (
  <Alert className={classNames('component-error', className)} color="danger" active>
    {children || <span>{message}</span>}
  </Alert>
)

ErrorMessage.propTypes = propTypes
ErrorMessage.defaultProps = defaultProps
ErrorMessage.displayName = 'ErrorMessage'
export default ErrorMessage
