import React, { Component } from 'react'
import { bool, func } from 'prop-types'
import classNames from 'classnames'

import Modal from '../Modal'

import { component } from './spotlight-modal.scss'

/**
 * SpotlightModal displays a modal scrim behind the SpotlightContent component that
 * is passed in.
 */
class SpotlightModal extends Component {
  static displayName = 'SpotlightModal'

  static propTypes = {
    active: bool.isRequired,
    deactivate: func.isRequired,
    fillAvailableWidth: bool,
    SpotlightContent: func.isRequired,
  }

  static defaultProps = {
    fillAvailableWidth: false,
  }

  componentDidUpdate() {
    // Bootstrap modals don't scroll by default, but Spotlight modals should
    document.body.classList.remove('no-scroll')
  }

  render() {
    const {
      active,
      deactivate,
      fillAvailableWidth,
      SpotlightContent,
      ...rest
    } = this.props
    return (
      <span className={component} data-test="spotlight-modal">
        <Modal active={active} deactivate={deactivate} />
        {active && (
          <div
            className={classNames('spotlight-content', {
              'fill-width': fillAvailableWidth,
            })}
            data-test="spotlight-content"
          >
            <SpotlightContent deactivateSpotlight={deactivate} {...rest} />
          </div>
        )}
      </span>
    )
  }
}

export default SpotlightModal
