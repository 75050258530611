import React, { useRef, useState } from 'react'
import { array, func, string } from 'prop-types'
import classNames from 'classnames'
import debounce from 'lodash.debounce'
import safeLoc from '../../utils/safeLoc'
import { Button } from '../..'

import { component } from './mobile-carousel.scss'

/**
 * Mobile helper to render a swipe-able carousel of items
 *
 * @param {Array} items
 * @param {Function} children
 * @returns
 */
const MobileCarousel = ({ className, items, renderItem }) => {
  const [selectedIdx, setSelectedIdx] = useState(0)

  // Initialize card refs
  const containerRef = useRef()
  const cardRefs = useRef([])

  // NOTE: If design decides they want the items to scroll to top when swiping sideways, in addition to
  // clicking the button to move to the next card, then uncomment this & remove scrolling from the onClick
  // useLayoutEffect(() => {
  //   const el = cardRefs.current[selectedIdx]
  //   cardRefs.current[selectedIdx]?.scrollIntoView({
  //     behavior: 'smooth',
  //     block: 'nearest',
  //   })
  // }, [selectedIdx])

  // Determine if the item ref is centered in the carousel
  const isItemCentered = ref =>
    // Using Math.floor() because getBoundingClientRect() is more precise than window.innerWidth, which rounds to the nearest pixel
    Math.floor(ref?.getBoundingClientRect()?.left) >= 0 &&
    Math.floor(ref?.getBoundingClientRect()?.right) <= window.innerWidth

  // Determine if a item is taking up more than half the screen
  const isItemCloseToCenter = ref => {
    // Using Math.floor() because getBoundingClientRect() is more precise than window.innerWidth, which rounds to the nearest pixel
    const leftOfItem = Math.floor(ref?.getBoundingClientRect()?.left)
    const rightOfItem = Math.floor(ref?.getBoundingClientRect()?.right)
    // Is this item scrolled off the left side of the page but is taking up more than 1/2 the screen?
    if (
      leftOfItem < 0 &&
      rightOfItem < window.innerWidth &&
      rightOfItem >= window.innerWidth / 2
    ) {
      return true
    }
    // Is the left side of this item on the first 1/2 the screen?
    if (leftOfItem >= 0 && leftOfItem <= window.innerWidth / 2) return true
    return false
  }

  // Find the card that is currently centered
  const findCurrentCard = debounce(() => {
    const primaryCardIndex = cardRefs.current.findIndex(isItemCentered)
    // An edge case can occur where the css scroll-snap does not work if it is intercepted by a vertical user scroll
    // This is manually doing the work that the scroll-snap should have done.
    if (primaryCardIndex === -1) {
      const closestCardIndex = cardRefs.current.findIndex(isItemCloseToCenter)
      cardRefs.current[closestCardIndex]?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
      setSelectedIdx(closestCardIndex)
      return
    }

    if (selectedIdx !== primaryCardIndex) {
      setSelectedIdx(primaryCardIndex)
    }
  }, 50)

  return (
    <div className={classNames(component, className, 'pb-4')}>
      <div
        className="carousel-container d-flex align-items-stretch overflow-scroll"
        ref={containerRef}
        onScroll={findCurrentCard}
      >
        {items.map((item, idx) => (
          <div key={JSON.stringify(item)} className="carousel-element">
            <div
              className="element-scroll-anchor"
              ref={el => {
                cardRefs.current[idx] = el
              }}
            />
            {renderItem(item, idx)}
          </div>
        ))}
      </div>
      {items.length > 1 && (
        <Button
          className="carousel-dots d-flex shadow-2dp bg-white p-2 mt-4"
          onClick={() => {
            const newIdx = selectedIdx + 1 >= items.length ? 0 : selectedIdx + 1
            cardRefs.current[newIdx]?.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
            })
            setSelectedIdx(newIdx)
          }}
          aria-label={safeLoc('GLO_goToNextItem')}
          tab-index="1"
        >
          {items.map((_, idx) => (
            <div
              className={classNames('dot rounded-circle m-1', {
                'bg-gray9': selectedIdx !== idx,
                'bg-primary': selectedIdx === idx,
              })}
            />
          ))}
        </Button>
      )}
    </div>
  )
}

MobileCarousel.propTypes = {
  className: string,
  // eslint-disable-next-line react/forbid-prop-types
  items: array,
  renderItem: func.isRequired,
}
MobileCarousel.defaultProps = { className: '', items: [] }
MobileCarousel.displayName = 'MobileCarousel'
export default MobileCarousel
