import React from 'react'
import classNames from 'classnames'
import { func, oneOfType, arrayOf, string, node } from 'prop-types'

import { Button } from '../../..'
import { component } from './sidebar-header.scss'
import deprecate from '../../../utils/deprecate'

const propTypes = {
  actionText: string,
  action: func,
  children: oneOfType([arrayOf(node), node]).isRequired,
  className: string,
  headerBackground: string,
}

const defaultProps = {
  actionText: null,
  action: null,
  className: '',
  headerBackground: null,
}

const SidebarHeader = ({
  actionText,
  action,
  children,
  className,
  headerBackground,
}) => {
  // I don't think this was ever used, but don't know all the places the razzle sidebar was consumed.
  deprecate({
    name: 'headerBackground',
    value: headerBackground,
    message: 'Pass in `bg-[BACKGROUND]` as a `className` directly',
  })

  return (
    <div className={classNames(component, className)}>
      <div className="position-relative">
        <h4 className="h4 font-weight-bold m-0">
          <span>{children}</span>
        </h4>
        {action && (
          <Button
            link
            data-test="sidebar-header-action"
            className="position-absolute font-weight-bold h4 center-y l-0"
            onClick={action}
          >
            {actionText}
          </Button>
        )}
      </div>
    </div>
  )
}

SidebarHeader.propTypes = propTypes
SidebarHeader.defaultProps = defaultProps
SidebarHeader.displayName = 'SidebarHeader'
// Just a thought...
// SidebarHeader.CallToAction = ({ children, className, onClick, ...rest }) => (
//   <Button
//     link
//     data-test="sidebar-header-action"
//     className={classNames(
//       className,
//       'position-absolute font-weight-bold h4 center-y l-4',
//     )}
//     onClick={onClick}
//     {...rest}
//   >
//     {children}
//   </Button>
// )
// SidebarHeader.CallToAction.displayName = 'SidebarHeaderCTA'
export default SidebarHeader
