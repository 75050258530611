import createNodeDefaultValue from './create-node-default-value'
/* eslint-disable no-param-reassign */

const visitNode = (node, value) => {
  const { items, properties, type, default: defaultValue } = node

  // NOTE: `null` values indicate a missing node in one of the datasets. This usually
  // means a node exists in the formData but not the defaultFormData, or vise versa. It is
  // necessary in order to identify missing values and should not be replaced with a default.
  if (value === null) return

  if (type === 'object' && properties) {
    Object.keys(properties).forEach(property => {
      const childNode = properties[property]

      if (value[property] === undefined) {
        value[property] = defaultValue || createNodeDefaultValue(childNode)
      }

      visitNode(childNode, value[property])
    })
  }

  if (type === 'array' && Array.isArray(items)) {
    items.forEach((childNode, idx) => {
      if (value[idx] === undefined) {
        value[idx] = defaultValue || createNodeDefaultValue(childNode)
      }

      visitNode(childNode, value[idx])
    })
  }
}

const decorateFormData = (schema, data) => {
  data = data || createNodeDefaultValue(schema)

  visitNode(schema, data)

  return data
}

export default decorateFormData
