// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".\\@hsq-razzle-dazzle_10-0-2_Disclaimer-local__component__edabec23 .\\@hsq-razzle-dazzle_10-0-2_Disclaimer-local__disclaimer-type__c1dae74e,.\\@hsq-razzle-dazzle_10-0-2_Disclaimer-local__component__edabec23 .\\@hsq-razzle-dazzle_10-0-2_Disclaimer-local__disclaimer-text__b80b9092,.\\@hsq-razzle-dazzle_10-0-2_Disclaimer-local__component__edabec23 .\\@hsq-razzle-dazzle_10-0-2_Disclaimer-local__disclaimer-link-text__e01a5d59,.\\@hsq-razzle-dazzle_10-0-2_Disclaimer-local__component__edabec23 .\\@hsq-razzle-dazzle_10-0-2_Disclaimer-local__disclaimer-icon__0c3358f2{line-height:1.25}\n", ""]);
// Exports
exports.locals = {
	"component": "@hsq-razzle-dazzle_10-0-2_Disclaimer-local__component__edabec23",
	"disclaimer-type": "@hsq-razzle-dazzle_10-0-2_Disclaimer-local__disclaimer-type__c1dae74e",
	"disclaimer-text": "@hsq-razzle-dazzle_10-0-2_Disclaimer-local__disclaimer-text__b80b9092",
	"disclaimer-link-text": "@hsq-razzle-dazzle_10-0-2_Disclaimer-local__disclaimer-link-text__e01a5d59",
	"disclaimer-icon": "@hsq-razzle-dazzle_10-0-2_Disclaimer-local__disclaimer-icon__0c3358f2"
};
module.exports = exports;
