import React from 'react'
import classNames from 'classnames'
import { node, string } from 'prop-types'

import { component } from './Content.scss'

/**
 * Wrapper for Sidebar Header/Content. Should be a sibling of any `Sidebar.Footer` element.
 * @param {} param
 */

const SidebarContent = ({ className, children, ...rest }) => (
  <div className={classNames(className, component)} {...rest}>
    {children}
  </div>
)

SidebarContent.propTypes = {
  className: string,
  children: node.isRequired,
}

SidebarContent.defaultProps = {
  className: '',
}

SidebarContent.displayName = 'SidebarContent'

export default SidebarContent
