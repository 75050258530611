import React from 'react'
import { node, oneOf, oneOfType, string } from 'prop-types'
import classNames from 'classnames'
import { Icon, Popover } from '../..'

import { component } from './info-popover.scss'

const propTypes = {
  body: oneOfType([string, node]),
  color: oneOf([
    'danger',
    'dark',
    'info',
    'light',
    'muted',
    'gray7',
    'primary',
    'secondary',
    'success',
    'warning',
  ]),
  className: string,
  direction: string,
  header: oneOfType([string, node]),
  overflow: oneOf(['wrap', 'truncate', '']),
  triggerIcon: node,
  /* Element to the left of the info icon that will also trigger the popover */
  triggerText: oneOfType([string, node]),
}

const defaultProps = {
  body: '',
  className: '',
  color: 'gray7',
  direction: 'right',
  header: '',
  overflow: '',
  triggerIcon: null,
  triggerText: '',
}

const InfoPopover = ({
  body,
  className,
  color,
  direction,
  header,
  overflow,
  triggerIcon,
  triggerText,
  ...rest
}) =>
  !body ? null : (
    <Popover
      className={classNames(
        component,
        className,
        'info-popover mw-100',
        'scoped-hippo-styles',
      )}
      direction={direction}
      data-test="info-popover"
      {...rest}
    >
      <Popover.Trigger className="popover-trigger text-left text-dark mw-100" link>
        <span className={classNames({ 'd-flex align-items-start': triggerText })}>
          <span
            className={classNames('trigger-text', {
              [`overflow-${overflow}`]: overflow,
            })}
          >
            {triggerText}
          </span>
          {triggerIcon || (
            <span className="d-flex align-items-center">
              <Icon
                className={classNames(`text-${color}`, {
                  'ml-2 mt-1': triggerText,
                })}
                id="info-fill"
                presentation
              />
            </span>
          )}
        </span>
      </Popover.Trigger>

      <Popover.Content className="border text-dark mt-3">
        {header && (
          <Popover.Header>
            <div className="header font-size-base">{header}</div>
          </Popover.Header>
        )}
        <Popover.Body>
          <div className="body font-size-base">{body}</div>
        </Popover.Body>
      </Popover.Content>
    </Popover>
  )

InfoPopover.propTypes = propTypes
InfoPopover.defaultProps = defaultProps
InfoPopover.displayName = 'InfoPopover'
export default InfoPopover
