const themeOverrides = (theme /* , props */) => ({
  ...theme,
  borderRadius: 3,
  colors: {
    ...theme.colors,
    primary25: 'var(--gray13)',
    primary: 'var(--primary)',
  },
  spacing: {
    ...theme.spacing,
    controlHeight: 48, // TODO: If we add all the size variants, make this responsive to a size prop
    // baseUnit, menuGutter,
  },
})

const sharedCustomStyles = ({ base }) => ({
  control: provided => ({
    ...provided,
    boxShadow: 0,
    borderRadius: base ? 0 : provided.borderRadius,
    backgroundColor: 'transparent',
  }),
  menu: provided => ({
    ...provided,
    boxShadow: 'var(--shadow-4dp)',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'var(--gray8)' : provided.backgroundColor,
  }),
})

export { sharedCustomStyles, themeOverrides }
