import { connect } from 'react-redux'
import { addArrayItem, selectors, removeArrayItem } from '../../dux/form'

import MultiSelectContainer from './MultiSelectContainer'

// Pass the MultiSelectContainer some required Formogorgon Props if this is a connected Form
// ---------------------------------------------------------------------------
const makeMapStateToProps = () => {
  const getFormValue = selectors.makeGetFormValue()
  const mapStateToProps = (store, props) => {
    const formId = props.materializedPath.split(',').shift()
    return {
      value: getFormValue(store[formId], props.materializedPath),
    }
  }
  return mapStateToProps
}

const mapDispatchToProps = {
  addArrayItem,
  removeArrayItem,
}

export default connect(makeMapStateToProps, mapDispatchToProps)(MultiSelectContainer)
