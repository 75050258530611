import React, { Component } from 'react'
import { bool, element, func, oneOf, number, shape, string } from 'prop-types'
import classNames from 'classnames'
import { Button, Icon, LinearLoadingTimer, Anchor } from '../..'

import { component } from './toast-notification.scss'

const statusMap = { success: 'Success', warning: 'Warning', danger: 'Danger' }

/**
 * @function contentBasedDelay
 * Determine the default autoclose delay based on the length of readable content.
 * Based on properties of visual attention & average reading rates
 * (See comment 2 here: https://ux.stackexchange.com/questions/11203/how-long-should-a-temporary-notification-toast-appear)
 * 1. 2000ms minimum
 * 2. Add 50ms per character
 * 3. 7000ms maximum (anything longer should be dismissibly instead of autoclosing)
 *
 * @param {String} visibleText  All readable text concatinated into one string
 * @returns {Number}            Delay in seconds
 */
const contentBasedDelay = visibleText => {
  const baseDelay = 2000 + visibleText.length * 50
  if (baseDelay > 7000) return 7
  return baseDelay / 1000
}

/**
 * A small notification meant to transiently display messages (autoclosing or dismissible)
 * @method      ToastNotification
 * @param       {Boolean}   autoclose Will call onDismiss() after a variable amount of time based on content length
 * @param       {Boolean}   autocloseOverride Override for the default autoclose delay time (in seconds)
 * @param       {Object}    action Contains details to format a button that can trigger a callback
 *                                 * pass a 'btn-anchor' class to make it render identical to the 'link'
 * @param       {Boolean}   dismissible Displays an 'X' that will call onDismiss() when clicked
 * @param       {Object}    link Contains the url & copy text to display a link in the notification
 *                               * link will open in a new tab
 * @param       {String}    body Notification text body
 * @param       {Function}  onDismiss Callback function that should remove the notification when called
 * @param       {String}    status 'success', 'danger' or 'warning' header rendered in it's corresponding color
 */
class ToastNotification extends Component {
  static propTypes = {
    autoclose: bool,
    autocloseOverride: number,
    action: shape({
      text: string,
      onClick: func,
      classes: string,
      disabled: bool,
    }),
    className: string,
    dismissible: bool,
    header: string,
    link: shape({ copy: string, url: string }),
    body: string,
    onClick: func,
    onDismiss: func,
    status: oneOf(['success', 'warning', 'danger']),
    CustomNotification: element,
  }

  static defaultProps = {
    autoclose: true,
    autocloseOverride: null, // seconds
    action: null,
    className: null,
    dismissible: null,
    link: null,
    body: '',
    header: null,
    onClick: () => {},
    onDismiss: () => {},
    status: null,
    CustomNotification: null,
  }

  state = {
    autocloseDelay: null, // seconds
    autocloseTimeout: null,
    timer: null,
    wrapBody: false,
  }

  componentDidMount() {
    const { autoclose } = this.props

    if (autoclose) this.setAutocloseDelay()
    this.checkBodyWrapping()
  }

  componentWillUnmount() {
    this.dismissNotification()
  }

  setAutocloseDelay = () => {
    const { autocloseOverride, link, body, status } = this.props
    // If there is not a delay specified, calculate the delay based on content length
    const delay =
      autocloseOverride ||
      contentBasedDelay(`${body}${status}${link ? link.copy : ''}`)
    // Set and begin the to autoclose coundown for the notification
    this.setState({ autocloseDelay: delay }, () => this.startCountdown(delay))
  }

  checkBodyWrapping = () => {
    // Detect text wrapping in the body element of the notification
    const { bodyEl } = this
    // The `text-truncate` class must be present on the  bodyEl when it mounts
    // to be able to detect text wrapping with hasOverflowingContent below
    const hasOverflowingContent =
      bodyEl &&
      (bodyEl.offsetHeight < bodyEl.scrollHeight ||
        bodyEl.offsetWidth < bodyEl.scrollWidth)

    // If the bodyEl text is wrapping, the ToastNotification should not be in a single line format
    if (hasOverflowingContent) this.setState({ wrapBody: true })
  }

  dismissNotification = () => {
    const { onDismiss } = this.props
    const { autocloseTimeout } = this.state
    if (autocloseTimeout) clearTimeout(autocloseTimeout)
    onDismiss()
  }

  startCountdown = seconds => {
    const { autocloseTimeout } = this.state
    clearTimeout(autocloseTimeout)
    this.countdown(seconds)
  }

  countdown = seconds => {
    if (seconds <= 0) {
      this.dismissNotification()
    } else {
      this.setState({
        timer: seconds,
        // NOTE: The coundown increment here (100ms) should stay in sync with the
        // css animation in the loading timer (i.e. transition: width .1s)
        autocloseTimeout: setTimeout(() => this.countdown(seconds - 0.1), 100),
      })
    }
  }

  renderNotificationContent = () => {
    const { action, body, link, status, onClick, header } = this.props
    const { autoclose, dismissible } = this.props
    const { wrapBody } = this.state
    // If this is a larger toast, we render the close icon inline with the header.
    const renderCloseInHeader = wrapBody && (dismissible || !autoclose)

    return (
      <>
        {/* STATUS */}
        {(status || header) && (
          <div className={classNames('position-relative', { 'mb-2': wrapBody })}>
            <h4
              data-test="toast-detail"
              className={classNames('font-weight-semibold mb-0', {
                [`text-${status}`]: status,
                // Space for the absolute positioned close icon
                'pr-4': renderCloseInHeader,
                // space between body and header
                'mr-2': !wrapBody,
              })}
            >
              {header || statusMap[status]}
            </h4>
            {/* CLOSE X for large toast */}
            {renderCloseInHeader && (
              <Button
                className={classNames('position-absolute center-y r-0 h4')}
                onClick={this.dismissNotification}
                data-test="dismiss"
                link
              >
                <Icon id="close" className="text-gray4" font title="Close" />
              </Button>
            )}
          </div>
        )}
        {/* BODY */}
        <div
          className={classNames('', {
            'text-truncate': !wrapBody, // v-imporant for auto text wrapping!
            'mb-2': link,
          })}
          ref={el => {
            this.bodyEl = el
          }}
        >
          {body}
        </div>
        {/* LINK */}
        {link && (
          <div className="mb-2">
            <Anchor href={link.url} onMouseUp={onClick}>
              {link.copy}
            </Anchor>
          </div>
        )}
        {/* CALLBACK BUTTON */}
        {action && (
          <div className="d-flex justify-content-end mt-4">
            <Button
              className={classNames(action.classes, 'd-block')}
              color={action.btnColor || 'primary'}
              onClick={action.onClick}
              disabled={action.disabled}
              data-test="action-button"
            >
              {action.text}
            </Button>
          </div>
        )}
      </>
    )
  }

  render() {
    const {
      autoclose,
      className,
      CustomNotification,
      dismissible,
      link,
    } = this.props
    const { autocloseDelay, timer, wrapBody } = this.state

    const progress = timer ? 100 - (timer / autocloseDelay) * 100 : 0

    if (CustomNotification) {
      return (
        <div
          className={classNames(component, className, 'bg-white mr-3')}
          data-test="toast-notification"
        >
          {React.cloneElement(CustomNotification, {
            ...this.props,
            dismissNotification: this.dismissNotification,
          })}
        </div>
      )
    }

    return (
      <div
        className={classNames(
          'scoped-hippo-styles',
          component,
          className,
          'bg-white mr-3 position-relative',
          {
            'p-4': wrapBody,
            'py-2 px-3': !wrapBody,
          },
        )}
        data-test="toast-notification"
      >
        <div
          className={classNames('d-flex justify-content-between', {
            'pb-0': autoclose,
            'align-items-start': link,
          })}
        >
          <div
            className={classNames('left-content w-100', {
              'd-flex': !wrapBody,
              'flex-column align-items-start': link,
              'align-items-center': !link,
            })}
          >
            {this.renderNotificationContent()}
          </div>
          {/* CLOSE X for inline toast */}
          {!wrapBody && (dismissible || !autoclose) && (
            <div className="position-relative pl-4">
              <Button
                className={classNames('position-absolute h4 center-y r-0 p-0', {
                  'mt-1': link,
                })}
                onClick={this.dismissNotification}
                data-test="dismiss"
                link
              >
                <Icon id="close" className="text-gray4" font title="Close" />
              </Button>
            </div>
          )}
        </div>
        {/* LOADER */}
        {autoclose && (
          <div className="position-absolute b-0 center-x">
            <LinearLoadingTimer countdown={timer} progress={progress} />
          </div>
        )}
      </div>
    )
  }
}

export default ToastNotification
