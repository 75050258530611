// Don't throw `MISSING_PARAM`s into production
const fallback = process.env.NODE_ENV === 'development' ? 'MISSING_PARAM' : ''
/**
 * Behaves like Ember.String.loc and replaces each value of `%@` with the next value in the provided
 * array
 */
export default function magicLoc(loc = '', params) {
  const sanitizedParams = [...(Array.isArray(params) ? params : [params])]
  return loc.replace(/%@/g, () => {
    const next = sanitizedParams.shift()
    // allow for passing `''` as a param by checking for `undefined`
    if (next === undefined) return fallback
    return next
  })
}
