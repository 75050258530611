import React from 'react'
import { bool, func, string } from 'prop-types'
import classNames from 'classnames'
import { Button, Checkbox } from '../..'

import { component } from './checkbox-button.scss'

const propTypes = {
  className: string,
  color: string,
  disabled: bool,
  outline: bool,
  onChange: func.isRequired,
  leftCheckbox: bool,
  text: string.isRequired,
  value: bool.isRequired,
}
const defaultProps = {
  className: '',
  color: 'secondary',
  outline: false,
  leftCheckbox: false,
  disabled: false,
}

const CheckboxButton = props => {
  const {
    className,
    color,
    text,
    onChange,
    outline,
    value,
    leftCheckbox,
    disabled,
    ...rest
  } = props

  return (
    <Button
      aria-checked={value}
      className={classNames(component, className, {
        left: leftCheckbox,
      })}
      color={color}
      onClick={() => onChange(!value)}
      outline={outline}
      data-test="checkbox-button"
      role="checkbox"
      disabled={disabled}
      {...rest}
    >
      <div className="d-flex align-items-center">
        <span className={classNames({ 'order-2': leftCheckbox })}>{text}</span>
        <div role="presentation">
          <Checkbox
            ariaLabel={text}
            base
            className={classNames({
              'ml-2': !leftCheckbox,
              'mr-2': leftCheckbox,
            })}
            data-test="checkbox"
            onChange={() => {}}
            onClick={e => e.stopPropagation()}
            tabIndex="-1"
            value={value}
            disabled={disabled}
          />
        </div>
      </div>
    </Button>
  )
}

CheckboxButton.propTypes = propTypes
CheckboxButton.defaultProps = defaultProps
CheckboxButton.displayName = 'CheckboxButton'

export default CheckboxButton
