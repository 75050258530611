import React, { createElement } from 'react'
import { shape } from 'prop-types'
import classNames from 'classnames'
import compileValidationMessage from './validationHelper'
import Input from '../Input'
import BaseInput from '../Input/BaseInput'
import Icon from '../../Icon'

const propTypes = {
  schemaNode: shape(),
}

const defaultProps = {
  schemaNode: {},
}

/**
 * InputContainer
 * Wrapper for the Input/BaseInput form elements passed into a Formogorgon Form that
 * convert Formogorgon related props to those expected by the plain components.
 */
const InputContainer = ({ schemaNode, ...rest }) => {
  const {
    inputType: schemaInputType,
    type: schemaType,
    typeOverride,
    ...schemaRest
  } = schemaNode

  const {
    // Props being used here to define the input attributes
    base,
    className,
    decoration,
    dynamicOptions = {},
    disabled,
    guid,
    id,
    inputType, // A terrible workaround for `type` in jsonSchema being different than a `type` in an <input />
    invalid,
    label,
    materializedPath,
    placeholder,
    editDecoration, // Formogorgon flag to render an edit-line icon in the Inputs
    readOnly, // Formogorgon flag to render a disabled form
    title,
    type,
    validationMessage, // Optional schemaNode override for invalid message from AJV
    /* Form related props that are not used here and don't get passed to the input 🙅 */
    currentMaterializedPath,
    defaultValue,
    dirty,
    NodeComponent,
    nullable,
    parentType,
    pattern,
    uiMenuComponent,
    /* Remaining props to pass straight through to the rendered input component */
    ...propsRest
  } = {
    ...schemaRest,
    // ℹ️ Allow passed props to override the schemaNode values
    ...rest,
  }

  // dynamicOptions is the only way to  pass a prop to SchemaNode that will trigger
  // a component update when the prop changes
  const { validationOverride } = dynamicOptions

  const renderEditIcon = () => <Icon id="edit-line" font presentation />

  const element = base ? BaseInput : Input
  const derivedClassName = classNames(className, { base })
  // It's important that the decoration falls back to `undefined` so it isn't passed a boolean
  const derivedDecoration =
    decoration || (editDecoration && renderEditIcon()) || undefined
  const derivedDisabled = disabled || readOnly
  const derivedId = materializedPath || id || guid || ''
  // Get the invalid message based upon the three possible values
  const derivedInvalid = compileValidationMessage({
    invalid,
    validationMessage,
    validationOverride,
  })
  const derivedLabel =
    label || title || (materializedPath && materializedPath.split(',').pop()) || ''
  const derivedPlaceholder = placeholder || ''
  let derivedInputType =
    type === 'textarea' || inputType === 'textarea' ? 'textarea' : 'input'
  // Numbers fields can be specified as either numbers or integers via the preference-service
  let derivedType = ['number', 'integer'].includes(schemaType) ? 'number' : type
  // Allows a `textarea` inputType declared in the pref schema to override the element
  if (schemaInputType) {
    derivedInputType = schemaInputType
  }
  // Allows passing an input type attribute like `password` via the schema
  if (typeOverride) {
    derivedType = typeOverride
  }

  return createElement(element, {
    className: derivedClassName,
    decoration: derivedDecoration,
    disabled: derivedDisabled,
    id: derivedId,
    inputType: derivedInputType,
    invalid: derivedInvalid,
    label: derivedLabel,
    placeholder: derivedPlaceholder,
    type: derivedType,
    ...propsRest,
  })
}

InputContainer.displayName = 'InputContainer'
InputContainer.defaultProps = defaultProps
InputContainer.propTypes = propTypes
export default InputContainer
