import React, { createElement, Component } from 'react'
import { arrayOf, bool, func, oneOfType, string } from 'prop-types'

import { BaseInput, Button, Input, PillBadge } from '../..'
import Icon from '../Icon' // this doesn't work in the last line /shrug
import { component } from './filter-bar.scss'

/**
 * FilterBar can either take in a filter string or an array of filter strings.
 * (An array of filter strings allows for narrowing a search by pressing ENTER)
 *
 * Example use: The array of filters allows the ClientPreferencesScreen to narrow the
 * preferences displayed by several independent search queries.
 */
class FilterBar extends Component {
  static displayName = 'FilterBar'

  static propTypes = {
    autoFocus: bool,
    base: bool,
    className: string,
    disabled: bool,
    label: string,
    onChange: func.isRequired,
    placeholder: string,
    iconSize: string,
    value: oneOfType([string, arrayOf(string)]).isRequired,
  }

  static defaultProps = {
    autoFocus: false,
    base: false,
    className: '',
    disabled: false,
    label: '',
    placeholder: '',
    iconSize: 'medium',
  }

  state = {
    /* eslint-disable-next-line react/destructuring-assignment */
    multiSearch: Array.isArray(this.props.value),
  }

  localOnChange = newValue => {
    const { onChange, value } = this.props
    const { multiSearch } = this.state
    // Only the first position in the filter array is editable in the Input field below
    const updatedValue = multiSearch ? [newValue, ...value.slice(1)] : newValue
    onChange(updatedValue)
  }

  handleKeyPress = evt => {
    const { multiSearch } = this.state

    if (evt.key === 'Enter' && multiSearch) {
      const { onChange, value } = this.props
      // Copy only the unique values from value
      const newValue = Array.from(new Set(value))
      // Add a new empty string to the first position
      newValue.unshift('')
      // Update the value
      onChange(newValue)
    }
  }

  clearAllValues = () => {
    const { onChange } = this.props
    const { multiSearch } = this.state
    const clearedValue = multiSearch ? [''] : ''
    onChange(clearedValue)
  }

  renderSearchIcon = () => {
    const { iconSize } = this.props
    return <Icon id="search" className={iconSize || ''} presentation />
  }

  renderClearButton = () => {
    const { value } = this.props
    const { multiSearch } = this.state
    return (
      <span className="d-flex align-items-center">
        {multiSearch &&
          value.map(
            (filter, idx) =>
              idx > 0 && <PillBadge key={filter} className="mr-2" text={filter} />,
          )}
        <Button
          key="button"
          className="d-flex align-items-center"
          onClick={this.clearAllValues}
          aria-label="Clear filter"
          link
        >
          <Icon className="ml-1" id="close-circle-fill" presentation />
        </Button>
      </span>
    )
  }

  render() {
    const {
      base,
      value,
      iconSize,
      // 🙅‍♀️ Don't pass these with `rest`
      onChange,
      ...rest
    } = this.props
    const { multiSearch } = this.state

    const showClearButton = multiSearch ? !!value.filter(Boolean).length : !!value
    const element = base ? BaseInput : Input

    return (
      <div className={`${component} filter-bar`}>
        {createElement(element, {
          'data-test': 'input',
          debounced: true,
          decoration:
            // If there are any non-empty strings in the value array
            showClearButton ? this.renderClearButton() : this.renderSearchIcon(),
          onChange: e => this.localOnChange(e.target.value),
          onKeyPress: this.handleKeyPress,
          // Only the first value in the filter array is editable
          value: multiSearch ? value[0] : value,
          ...rest,
        })}
      </div>
    )
  }
}

export default FilterBar
