// NOTE: This lookup table is necessary because there does not seem to be a way to
// create an escape sequence from a template string.
const ESCAPE_SEQUENCES = {
  n: '\n', // New Line
  t: '\t', // Horizontal Tabulator
  r: '\r', // Carriage Return
  b: '\b', // Backspace
  f: '\f', // Form Feed
  v: '\v', // Vertical Tabulator
}

/**
 * @method processEscapesEnteringInput
 * @param {String} value            String in which to escape the escapeSequences
 * @param {Array} escapeSequences   Array of escape characters to make sure are displayed in the input
 * @returns       String where the '\'s in the indicated escape sequences are escaped
 *
 * @input   {value: 'string \n to \t escape \r', escapeSequences: ['n', 't']}
 * @output  'string \\n to \\t escape \r'
 */
export const processEscapesEnteringInput = ({ value, escapeSequences }) => {
  if (escapeSequences) {
    let escapedValue = value
    escapeSequences.forEach(seq => {
      // NOTE: The selector for a single escape and the string for a double escape are the same
      const singleEscapeSelector = `\\${seq}`
      const doubleEscapeString = `\\${seq}`
      const regex = new RegExp(singleEscapeSelector, 'g')
      // (If seq is 'n': escapedValue.replace(/\n/g, '\\n'))
      escapedValue = escapedValue.replace(regex, doubleEscapeString)
    })
    return escapedValue
  }
  return value
}

/**
 * @method processEscapesExitingInput
 * @param {String} value            String in which to unescape the escapeSequences
 * @param {Array} escapeSequences   Array of escape characters to convert back to single escapes with the onChange
 * @returns       String where the escaped '\'s in the indicated escape sequences are removed
 *
 * @input   {value: 'string \\n to \\t escape \r', escapeSequences: ['n', 't']}
 * @output  'string \n to \t escape \r'
 */
export const processEscapesExitingInput = ({ value, escapeSequences }) => {
  if (escapeSequences) {
    let unescapedValue = value
    escapeSequences.forEach(seq => {
      const selectorForDoubleEscape = `\\\\${seq}`
      const regex = new RegExp(selectorForDoubleEscape, 'g')
      // (e.g. If seq is 'n' `escapedValue.replace(/\\n/g, '\n')
      unescapedValue = unescapedValue.replace(regex, ESCAPE_SEQUENCES[seq])
    })
    return unescapedValue
  }
  return value
}

/**
 * @method appendListToString   Example usage: Append degree credentials to the end of provider names
 *
 *
 * @param {String} baseString   The string we want to append to (e.g. "Susan Salamander")
 * @param {Array} items         The items to add to the baseString (e.g. ["MD", "PhD"])
 * @returns {String}            The baseString with list items added comma separated (e.g. "Susan Salamander, MD, PhD")
 *                              * if there is no base string, it will return nothing, even if there are `item`s
 */
export const appendListToString = (baseString, items) =>
  baseString && items?.length ? [baseString, ...items].join(', ') : baseString
