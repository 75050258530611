import React, { createContext, useContext, useState, useEffect } from 'react'
import { shape } from 'prop-types'
import magicLoc from '../utils/magicLoc'
import { SHOW_KEYS } from '../utils/setupShowKeys'

const TranslationContext = createContext({ defaultContextValue: true })
TranslationContext.displayName = 'TranslationContext'

export const generateContextValue = (translations, showKeys) => ({
  translations,
  getLoc: (key, params) => {
    if (!key || typeof key !== 'string') {
      console.warn('Invalid type supplied to getLoc. Expected a string but received: ', key)
      return null
    }
    let loc
    if (translations[key]) loc = magicLoc(translations[key], params)
    else if (key.includes('.')) {
      const nestedKeys = key.split('.')
      let reference = translations
      while (nestedKeys.length && reference) {
        const nestedKey = nestedKeys.shift()
        reference = reference[nestedKey]
      }
      loc = typeof reference === 'string' ? magicLoc(reference, params) : ''
    }
    if (showKeys) {
      return loc ? key : `MISSING LOC: ${key}`
    }
    return loc || ''
  },
})

/**
 * When using contexts, enzyme requires a wrapping Component as an option in your
 * `wrapper`/`mount`. This generates that.
 *
 * This wrapper can be bypassed by omitting `translations` if the necessary context has
 * already been instantiated by a parent component.
 */
// eslint-disable-next-line
export const TranslationsProvider = ({ translations, children }) => {
  const [showKeys, setShowKeys] = useState(false)
  const parentTranslationContext = useContext(TranslationContext)

  useEffect(() => {
    const listenForShowKeys = () => {
      // setShowKeys(!getKeysShown())
      setShowKeys(true)
    }
    window.addEventListener(SHOW_KEYS, listenForShowKeys)
    return () => window.removeEventListener(SHOW_KEYS, listenForShowKeys)
  }, [])
  // 1. Wrap the children in the provider only if `translations` is passed in
  if (translations) {
    const value = generateContextValue(translations, showKeys)
    return <TranslationContext.Provider value={value}>{children}</TranslationContext.Provider>
  }
  // 2. If a parent already set up an TranslationsProvider, just pass the children through
  if (!parentTranslationContext.defaultContextValue) return children
  // 3. TranslationsProvider cannot be used properly
  console.warn('TranslationsProvider does not have the appropriate translations data')
  return null
}

TranslationsProvider.propTypes = {
  translations: shape({}),
}

TranslationsProvider.defaultProps = {
  translations: null,
}

export default TranslationContext
