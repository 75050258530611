import React from 'react'
import { Drawer } from 'componentry'
import { func, oneOfType, string, node, bool } from 'prop-types'
import classNames from 'classnames'
import nanoid from 'nanoid'

import { component } from './drawer-card.scss'
import Icon from '../Icon'

/**
 * Simple component to dedupe styles and ensure sidebar form drawers render the same
 *
 * @NOTE i think we should try to make this a feature of our regular drawer.
 */
const DrawerCard = ({ title, content, className, defaultActive, fireTag, id }) => {
  const localGuid = () => (process.env.NODE_ENV === 'test' ? 'test-id' : nanoid())
  const guid = localGuid()
  return (
    <Drawer defaultActive={defaultActive} onActivate={fireTag}>
      {({ active }) => (
        <div
          className={classNames('card p-0 bg-white', className, component, {
            'border-primary': active,
          })}
        >
          <Drawer.Trigger
            guid={id || guid}
            className={classNames(
              'card-header d-flex justify-content-between text-left mb-0 text-gray1 ws-normal',
              // bootstrap adds the a bottom border to card headers to separate from the body. Remove that border when the card is not expanded.
              { 'border-0': !active, 'no-active-shadow': active },
            )}
          >
            {title}
            <div className="d-inline-block">
              <Icon
                id={active ? 'chevron-up' : 'chevron-down'}
                className="h3"
                presentation
              />
            </div>
          </Drawer.Trigger>
          <Drawer.Content guid={guid} className="card-body">
            {content}
          </Drawer.Content>
        </div>
      )}
    </Drawer>
  )
}

DrawerCard.propTypes = {
  className: string,
  content: oneOfType([node, string]).isRequired,
  defaultActive: bool,
  fireTag: func,
  id: string,
  title: oneOfType([node, string]).isRequired,
}

DrawerCard.defaultProps = {
  className: '',
  defaultActive: false,
  fireTag: () => {},
  id: '',
}

export default DrawerCard
