import { createElement, Component } from 'react'
import { array, arrayOf, bool, func, number, oneOfType, shape, string } from 'prop-types'
import Ajv from 'ajv'

import { checkObjectKeyRequired } from '../utils/validation-helpers'
import defaultNodeComponentRules from '../utils/default-node-component-rules'

import FormContext from '../FormContext/FormContext'

const ajv = new Ajv({ nullable: true })

class SchemaNode extends Component {
  static displayName = 'SchemaNode'

  static propTypes = {
    addArrayItem: func.isRequired,
    dirty: bool,
    invalid: oneOfType([bool, arrayOf(string), string]),
    materializedPath: string.isRequired,
    removeArrayItem: func.isRequired,
    schemaNode: shape({
      onChange: func,
      readOnly: bool,
    }).isRequired,
    sortprop: string,
    setRef: func,
    updateFormData: func.isRequired,
    value: oneOfType([array, bool, string, number, shape({})]),
    dynamicOptions: shape(),
  }

  static defaultProps = {
    dirty: undefined,
    invalid: undefined,
    sortprop: '',
    value: null,
    dynamicOptions: {},
    setRef: () => {},
  }

  componentDidMount() {
    const { schemaNode } = this.props
    const { type } = schemaNode
    // ⚠️ TODO: There are some circumstances that arrays and objects should be validated
    // but it needs to be more specific because large schemas crash ajv (i.e. the preferences)

    // Create a JSON schema validator
    if (type !== 'object' && type !== 'array') {
      this.validate = ajv.compile(schemaNode)
    }
  }

  shouldComponentUpdate(nextProps) {
    const {
      dirty,
      invalid,
      value,
      schemaNode: { readOnly, version },
      dynamicOptions,
    } = this.props

    const {
      dirty: nextDirty,
      invalid: nextInvalid,
      value: nextValue,
      schemaNode: { readOnly: nextReadOnly, version: nextVersion },
      dynamicOptions: nextDynamicOptions,
    } = nextProps

    return (
      dirty !== nextDirty ||
      invalid !== nextInvalid ||
      value !== nextValue ||
      readOnly !== nextReadOnly ||
      version !== nextVersion ||
      JSON.stringify(dynamicOptions) !== JSON.stringify(nextDynamicOptions)
    )
  }

  componentDidUpdate(prevProps) {
    const { schemaNode } = this.props
    const { schemaNode: prevSchemaNode } = prevProps
    const { type } = schemaNode
    const { type: prevType } = prevSchemaNode

    // Update the JSON validator if the node data type changes (via setFormFields)
    if (type !== 'object' && type !== 'array' && type !== prevType) {
      this.validate = ajv.compile(schemaNode)
    }
  }

  // 🤔 TODO: we should probably make this expect the value, and allow components to
  // pass those values to this handler...
  // TODO: do validations stuff
  onChangeHandler = ({ target, value }) => {
    const { updateFormData, materializedPath, schemaNode } = this.props
    const { onChange: schemaOnChange, type } = schemaNode

    let evtValue
    if (value || value === '') {
      evtValue = value
    } else {
      evtValue = target.type === 'checkbox' ? target.checked : target.value
    }

    // Type number are rendered as inputs, but HTML Input elements with a type of
    // number "return a string representing a number", so this is here to convert
    // it back to a number.
    if (type === 'number' || type === 'integer') {
      if (evtValue === '') {
        evtValue = null
      } else {
        // NOTE: Using parseFloat, which returns NaN for empty strings led to issues when removing
        // array items as this onChangeHandler function was sporadically getting called with type
        // integer. Ajv then invalidated the deleted row's integer field, triggering the UPDATE_FORM_DATA
        // reducer, updating invalidById, and re-rendering the invalid banner notification. If we leave
        // empty strings as is, and then use Number() instead of parseFloat(), it works properly.
        evtValue = Number(evtValue)
      }
    }

    // Call the onChange *observer* if one was declared in the schema
    // ⚠️ this onChange does not override the library onChange, but it's possible
    // that this could be renamed to something like onChangeObserver and allow a
    // passed onChange to override the onChange and make consumers responsible for
    // deciding when to override the store.
    if (schemaOnChange) schemaOnChange({ value: evtValue, materializedPath })

    let valid = true
    // Call validate to get status and errors
    // TODO: Make sure this is only doing the minimal computation
    if (type !== 'object' && type !== 'array') {
      valid = this.validate(evtValue)
    }

    const valueMissing = checkObjectKeyRequired(schemaNode, evtValue)

    // USE AJV TO VALIDATE 🎉 😍 🎉
    updateFormData({
      materializedPath,
      value: evtValue,
      // `valueMissing` must come first becasue a missing property could be `valid` according to ajv
      valid: valueMissing || valid || this.validate.errors.map(error => error.message),
    })
  }

  render() {
    const { updateFormData, addArrayItem, removeArrayItem, setRef, ...rest } = this.props
    const { schemaNode } = rest
    const { FORM_PROPERTIES } = this.context
    const { componentMap, customNodeComponentRules } = FORM_PROPERTIES
    const { NodeComponent, type } = schemaNode

    const element =
      // If we have a custom component specified in the schema, use it
      NodeComponent ||
      // Next check custom rules
      customNodeComponentRules(schemaNode) ||
      // Finally use defaults
      defaultNodeComponentRules(schemaNode)

    const actions = {}

    if (type !== 'array') actions.onChange = this.onChangeHandler
    if (type === 'array') {
      actions.addArrayItem = addArrayItem
      actions.removeArrayItem = removeArrayItem
      // This is added to pagination on ArrayTables to avoid going into draft mode when paginating
      actions.setRef = setRef
    }

    return createElement(componentMap[element], {
      ...rest,
      ...actions,
    })
  }
}

SchemaNode.contextType = FormContext

export default SchemaNode
