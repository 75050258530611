import React from 'react'
import { arrayOf, bool, func, oneOfType, shape, string } from 'prop-types'
import { components } from 'react-select'
import {
  SortableContainer,
  SortableElement,
  sortableHandle,
} from 'react-sortable-hoc'

import MultiSelect from './MultiSelect'

const arrayMove = (array, from, to) => {
  const newArray = array.slice()
  newArray.splice(
    to < 0 ? newArray.length + to : to,
    0,
    newArray.splice(from, 1)[0],
  )
  return newArray
}

const MultiSelectContainer = ({ value, onChange, sortable, ...rest }) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(value, oldIndex, newIndex)
    onChange(newValue)
  }

  if (!sortable) return <MultiSelect onChange={onChange} value={value} {...rest} />

  const SortableMultiValue = SortableElement(props => {
    // this prevents the menu from being opened/closed when the user clicks
    // on a value to begin dragging it. ideally, detecting a click (instead of
    // a drag) would still focus the control and toggle the menu, but that
    // requires some magic with refs that are out of scope for this example
    const onMouseDown = e => {
      e.preventDefault()
      e.stopPropagation()
    }
    // eslint-disable-next-line react/prop-types
    const innerProps = { ...props.innerProps, onMouseDown }
    return <components.MultiValue {...props} innerProps={innerProps} />
  })

  const SortableMultiValueLabel = sortableHandle(props => (
    <components.MultiValueLabel {...props} />
  ))

  const SortableSelect = SortableContainer(MultiSelect)

  return (
    <SortableSelect
      useDragHandle
      // react-sortable-hoc props:
      axis="xy"
      onSortEnd={onSortEnd}
      distance={4}
      // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
      getHelperDimensions={({ node }) => node.getBoundingClientRect()}
      // react-select props:
      components={{
        MultiValue: SortableMultiValue,
        MultiValueLabel: SortableMultiValueLabel,
      }}
      onChange={onChange}
      value={value}
      {...rest}
    />
  )
}

MultiSelectContainer.propTypes = {
  onChange: func.isRequired,
  sortable: bool,
  value: arrayOf(oneOfType([string, shape({ value: string, label: string })])),
}
MultiSelectContainer.defaultProps = {
  sortable: true,
  value: [],
}
MultiSelectContainer.displayName = 'MultiSelectContainer'
export default MultiSelectContainer
