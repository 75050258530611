import { validateNode } from './validation-helpers'
import { isValueDirty } from './form-data-helpers'

/**
 * Method to traverse data with corresponding jsonSchema and generate a flattened
 * data set where each nested node in the original data is represented by the
 * materializedPath to that value.
 *
 * @method compileEntityMaps
 * @param  {Object}          schema                  jsonSchema of the `data`
 * @param  {Any}             data                    Data set whose structure is specified by the schema
 * @param  {String}          formId                  Base of the materializedPath
 * @param  {Any}             [defaultData=undefined] Data set that `data` is compared to determine if the value of a given node has changed.
 * @return {Object}                                  Set of flattened information to allow for constant
 *                                                   time access of default value, dirty state, invalid state,
 *                                                   and current value for each node in the schema.
 */
const compileEntityMaps = (schema, data, formId, defaultData = undefined, options = {}) => {
  const defaultById = {}
  const dirtyById = {}
  const invalidById = {}
  const valueById = {}
  const schemaById = {}

  // Determining the valueById, defaultById, and dirtyById parameters for each node
  // NOTE: Must use `undefined` instead of `null` for missing values since strings
  // may be `null`
  const visitNode = ({
    node,
    value,
    materializedPath,
    valueDefault,
    nodePreValidated = false,
    ...rest
  }) => {
    const { items, properties, type } = node
    // If a node is set to `compareNullValues` that will also be applied to all of it's children
    const compareNullValues = node.compareNullValues || rest.compareNullValues

    schemaById[materializedPath] = node
    // The value for the indices of arrays is not saved, so we should not set invalidById for them
    // (parentType === 'array' for these nodes)
    let childrenValid = nodePreValidated
    if (options.validate && !node.noValidate && node.parentType !== 'array' && !nodePreValidated) {
      try {
        const nodeInvalid = validateNode({ node, value })
        if (nodeInvalid) {
          invalidById[materializedPath] = nodeInvalid
        } else {
          childrenValid = true
        }
      } catch {
        // eslint-disable-next-line no-console
        console.warn(`Could not validate: ${materializedPath}`)
      }
    }

    if (type === 'object') {
      // Make sure the original data is properly formatted.
      if (properties) {
        // Objects don't have values added, they are static nodes with no changes
        Object.keys(properties).forEach(property => {
          visitNode({
            node: properties[property],
            value: value ? value[property] : undefined,
            materializedPath: `${materializedPath},${property}`,
            valueDefault: valueDefault ? valueDefault[property] : undefined,
            compareNullValues,
            nodePreValidated: childrenValid,
          })
        })
      } else {
        // If the schema has a type of object without properties, then we will stop
        // walking the tree and just save the whole object as the value of that node
        const nodeValue = value
        const nodeDefault = valueDefault === undefined ? value : valueDefault

        defaultById[materializedPath] = nodeDefault
        valueById[materializedPath] = nodeValue
      }
    } else if (type === 'array') {
      // Override the schema type for arrays because they're stored here as the length (i.e. an integer)
      schemaById[materializedPath] = { ...node, type: 'integer' }

      // Handling two cases here:
      // 1) We have an array for both `value` and `valueDefault`, and we need to figure out
      //    which is longer
      // 2) We only have one of `value` or `valueDefault`
      let longestArr

      // NOTE: valueById for arrays is the length of the array, so we need to specify that it is a number
      schemaById[materializedPath] = { type: 'number' }

      if (Array.isArray(value) && Array.isArray(valueDefault)) {
        // Set the array count in the entity maps
        defaultById[materializedPath] = valueDefault ? valueDefault.length : value.length
        valueById[materializedPath] = value.length

        // Here we need to determine whether our new data array is longer
        // than the comparison array, and make sure we run `visitNode` for
        // the length of the _longest_.
        if (valueDefault.length) {
          longestArr = value.length && value.length >= valueDefault.length ? value : valueDefault
        } else {
          longestArr = value
        }
      } else if (Array.isArray(value) || Array.isArray(valueDefault)) {
        longestArr = Array.isArray(value) ? value : valueDefault
        // Set the array length to null if the node is not present in one of the datasets
        // NOTE: This is consistent with the note above that `null` is reserved to
        // indicate missing values
        defaultById[materializedPath] = Array.isArray(valueDefault) ? longestArr.length : null
        valueById[materializedPath] = Array.isArray(value) ? longestArr.length : null
      } else {
        return
      }

      longestArr.forEach((_, idx) => {
        visitNode({
          node: Array.isArray(items) ? items[idx] : items,
          value: value ? value[idx] : undefined,
          materializedPath: `${materializedPath},${idx}`,
          valueDefault: valueDefault ? valueDefault[idx] : undefined,
          compareNullValues,
          nodePreValidated: childrenValid,
        })
      })
    } else {
      // This is a field, store the value in the entity maps
      const nodeValue = value
      const nodeDefault = valueDefault === undefined ? value : valueDefault
      const nodeDirty = isValueDirty({
        type,
        value,
        valueDefault,
        compareNullValues,
      })

      // Set the node values in the entity maps
      if (nodeDirty) dirtyById[materializedPath] = nodeDirty
      defaultById[materializedPath] = nodeDefault
      valueById[materializedPath] = nodeValue
    }
  }

  visitNode({
    node: schema,
    value: data,
    materializedPath: formId,
    valueDefault: defaultData,
  })

  const newEntityMaps = {
    defaultById,
    dirtyById,
    invalidById,
    valueById,
  }
  if (options.withSchemaMap) newEntityMaps.schemaById = schemaById

  return newEntityMaps
}

export default compileEntityMaps
