/**
 * Handle walking the schema and pulling values out of the entity map to create the
 * form value.
 * @param {object} schema Form schema
 * @param {object} valuesById Entity map of form values by materializedPath
 * @param {string} formId Id of the form
 */
const compileFormValue = (schema, valuesById, formId) => {
  const getValueForNode = ({ items, properties, type }, materializedPath) => {
    if (type !== 'object' && type !== 'array') return valuesById[materializedPath]

    // NOTE: compileFormValue may be called on null values if a node exists in the
    // formData but not the defaultFormData, or vise versa. It signifies a missing
    // value and should not be replaced with a default.
    if (valuesById[materializedPath] === null) return null

    // Create an empty container for the type
    const nodeContainer = type === 'object' ? {} : []

    if (type === 'object') {
      if (properties) {
        Object.keys(properties).forEach(property => {
          nodeContainer[property] = getValueForNode(
            properties[property],
            `${materializedPath},${property}`,
          )
        })
      } else {
        // If the schema has a type of object without properties, then we will stop
        // walking the tree and just save the whole object as the value of that node
        return valuesById[materializedPath]
      }
    }

    if (type === 'array') {
      const itemsCount = valuesById[materializedPath]
      for (let i = 0, ii = itemsCount; i < ii; i += 1) {
        const childSchemaNode = Array.isArray(items) ? items[i] : items

        nodeContainer.push(getValueForNode(childSchemaNode, `${materializedPath},${i}`))
      }
    }

    return nodeContainer
  }

  return getValueForNode(schema, formId)
}

export default compileFormValue
