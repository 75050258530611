import React, { Component } from 'react'
import { arrayOf, bool, func, shape, oneOf, oneOfType, string } from 'prop-types'
import classNames from 'classnames'
import nanoid from 'nanoid'

import Select from 'react-select'
import Creatable from 'react-select/creatable'

import { Icon } from '../../../..'
import { FormHelpText, RequiredFlag } from '../../../utilComponents'
import { SIZE_OPTIONS } from '../../../../utils/constants'
import {
  sharedCustomStyles,
  themeOverrides,
} from '../../../../utils/reactSelectHelpers'

import { component } from './multi-select.scss'

// Helper to generate options for a react-select component from enumerables and/or value
const getOptions = (enumerables, value) => {
  // values/enumerables may come in as either strings or arrays of objects, but they
  // should always be returned as an array of objects (EX: {value: '1', label: '1'})
  let mergedOptions = []
  // 1. Copy the values (since options may be created that aren't in the enums)
  if (value && value.length) {
    if (typeof value[0] === 'string') {
      value.forEach(el => mergedOptions.push({ value: el, label: el }))
    } else {
      mergedOptions = [...mergedOptions, ...value]
    }
  }
  // 2. Copy the enumerables, if they exist
  if (enumerables && enumerables.length !== 0) {
    if (typeof enumerables[0] === 'string') {
      enumerables.forEach(el => mergedOptions.push({ value: el, label: el }))
    } else {
      mergedOptions = [...mergedOptions, ...enumerables]
    }
    return mergedOptions
  }
  // 3. Remove duplicates?
  // NOTE: If an enumerable is also in the value set, then this will be add them to
  // the options list more than once, but the <Select /> component only checks that
  // the values are in the options list, so duplicates shouldn't matter.

  return mergedOptions
}

const normalizeValue = value =>
  value
    ? value.map(item =>
        typeof item === 'string' ? { label: item, value: item } : item,
      )
    : value

/**
 * MultiSelect is a form component used to select an array of string values
 *
 * Values & enumerables may be passed in one of two formats: either an array of
 * strings or an array of objects with `value` and `label` string attributes.
 * NOTE: Values will be returned to the onChange handler in the format that
 * they were originally passed in.
 */
class MultiSelect extends Component {
  static displayName = 'MultiSelect'

  static propTypes = {
    additionalItems: bool,
    base: bool,
    className: string,
    components: shape(),
    description: string,
    disabled: bool,
    enumerables: arrayOf(
      oneOfType([string, shape({ value: string, label: string })]),
    ),
    id: string,
    invalid: oneOfType([string, arrayOf(string)]),
    isRequired: bool,
    label: string,
    onChange: func.isRequired,
    size: oneOf(SIZE_OPTIONS),
    value: arrayOf(oneOfType([string, shape({ value: string, label: string })])),
  }

  static defaultProps = {
    additionalItems: true,
    base: false,
    className: '',
    components: {},
    description: '',
    disabled: false,
    enumerables: [],
    id: '',
    invalid: '',
    isRequired: false,
    label: '',
    size: 'md',
    value: [],
  }

  state = {
    options: getOptions(this.props.enumerables, this.props.value), // eslint-disable-line
  }

  componentDidMount() {
    const { enumerables, value } = this.props

    if (enumerables.length) {
      this.initialValueType = typeof enumerables[0]
    } else if (value && value.length) {
      this.initialValueType = typeof value[0]
    } else {
      this.initialValueType = 'string'
    }
  }

  componentDidUpdate(prevProps) {
    const { enumerables, value } = this.props
    const { value: prevValue } = prevProps

    if (JSON.stringify(value) !== JSON.stringify(prevValue)) {
      this.updateOptions(enumerables, value)
    }
  }

  updateOptions = (enumerables, value) => {
    this.setState({
      options: getOptions(enumerables, value),
    })
  }

  handleOnChange = newValue => {
    const { onChange } = this.props

    // Check if the newly added value (the one on the end) needs special handling
    const addedElement = newValue.pop()
    let newElements = [addedElement]
    // Allow the a comma + space separated list to be pasted in all at once & create separate values (trimming extra)
    // EXAMPLE:
    // "this,will,be,added,as,one,value" --> ["this,will,be,added,as,one,value"]
    // "these, will, be, added    ,   separately" --> ["these", "will", "be", "added", "separately"]
    if (addedElement?.value.includes(', ')) {
      newElements = addedElement.value
        .split(', ')
        .map(el => ({ label: el.trim(), value: el.trim() }))
    }

    // If all values are cleared out, addedElement will be undefined
    const cleanNewValue = addedElement ? newValue.concat(newElements) : newValue

    // Return the same data type that was initially passed in (since 2 are allowed)
    if (this.initialValueType === 'object') {
      onChange(cleanNewValue)
      return
    }
    onChange(cleanNewValue.map(el => el.value))
  }

  // In testing use the same guid always so that snapshots are consistent
  localGuid = () => (process.env.NODE_ENV === 'test' ? 'test-id' : nanoid())

  renderInvalidFeedback = invalid => {
    if (Array.isArray(invalid)) {
      return invalid.map(
        message => message && <span className="invalid-feedback">{message}</span>,
      )
    }
    return <span className="invalid-feedback">{invalid}</span>
  }

  renderMultiSelect = guid => {
    const {
      additionalItems,
      base,
      className,
      components,
      description,
      disabled,
      invalid,
      size,
      value,
      // Props that are not currently used but should not be passed to the input 🙅
      enumerables,
      id,
      isRequired,
      label,
      onChange,
      ...rest
    } = this.props
    const { options } = this.state

    const SelectComponent = additionalItems ? Creatable : Select
    const placeholder = additionalItems ? 'Select or enter a value...' : 'Select...'

    return (
      <SelectComponent
        aria-label={label}
        className={classNames(className, 'react-select', {
          'is-invalid': invalid && invalid.length,
          'vanilla-component': base,
        })}
        components={{
          ClearIndicator: ({ innerProps }) => (
            <div {...innerProps}>
              <Icon id="close-x" className="ml-2" />
            </div>
          ),
          IndicatorSeparator: () => null,
          DropdownIndicator: state => {
            const {
              selectProps: { menuIsOpen },
            } = state
            return (
              <Icon
                className="mx-2"
                id={menuIsOpen ? 'chevron-up' : 'chevron-down'}
              />
            )
          },
          ...components,
        }}
        id={guid}
        isClearable
        isDisabled={disabled}
        isMulti
        isSearchable
        onChange={this.handleOnChange}
        options={options}
        placeholder={placeholder}
        styles={sharedCustomStyles(this.props)}
        theme={theme => themeOverrides(theme, this.props)}
        value={normalizeValue(value)}
        {...rest}
      />
    )
  }

  render() {
    const {
      base,
      className,
      description,
      id,
      invalid,
      isRequired,
      label,
      size,
    } = this.props
    const computedLabel = label || ''
    const guid = id || this.localGuid()

    if (base) {
      return (
        <span className={classNames(component, 'input-multiselect w-100')}>
          {this.renderMultiSelect(guid)}
        </span>
      )
    }

    return (
      <span>
        <div
          className={classNames(
            component,
            className,
            'form-group input-multiselect input-common-component',
            `form-control-${size}`,
          )}
        >
          {label ? (
            <div htmlFor={guid} className="w-100">
              <div
                className={classNames(
                  'd-flex justify-content-between align-items-baseline',
                  { 'mb-1': computedLabel },
                )}
              >
                <label
                  htmlFor={guid}
                  className="input-text-label"
                  data-test="label"
                >
                  {computedLabel}
                </label>
                {isRequired && <RequiredFlag />}
              </div>
              {this.renderMultiSelect(guid)}
            </div>
          ) : (
            this.renderMultiSelect(guid)
          )}
          <FormHelpText
            description={description}
            invalid={invalid}
            id={`${guid}-helper`}
          />
        </div>
      </span>
    )
  }
}

export default MultiSelect
