/**
 * @NOTE Strong candidate for unit testing over here in formo-land 🦕 🦖
 *
 * Handles iterating through the values of an entityMap, removing the item specified
 * and updating the keys for any items affected by that removal.
 * @param {string} arrayPath The materialized path to the parent array
 * @param {number} removeIndex The index of the element to remove
 * @param {object} entityMap Entity map with materializedPath keys
 */
export const removeEntity = (arrayPath, removeIndex, entityMap) => {
  const newEntityMap = {}
  // Since we're about to do some EXTREMELY IMPORTANT number comparisons, make
  // dang sure we're using numbers for both the `removeIndex` and the `ItemIdx` below
  const removeIndexNum = parseInt(removeIndex)

  Object.keys(entityMap).forEach(entry => {
    if (entry === arrayPath) {
      // Pass through the parent array value of an entity
      newEntityMap[entry] = entityMap[entry]
    } else if (entry.indexOf(`${arrayPath},${removeIndex},`) === 0) {
      /* remove the item by doing nothing */
    } else if (entry.indexOf(arrayPath) === 0) {
      // Get the index of the item to know whether to adjust it
      const [entryRoot, itemIdx] = entry.match(new RegExp(`${arrayPath},(\\d+)`))
      const itemIdxNum = parseInt(itemIdx)

      if (itemIdxNum > removeIndexNum) {
        // Decrement the value by 1
        newEntityMap[entry.replace(entryRoot, `${arrayPath},${itemIdxNum - 1}`)] = entityMap[entry]
      } else {
        // If the itemIdx is less than the item removed, pass it through
        newEntityMap[entry] = entityMap[entry]
      }
    } else {
      // This entity is not related at all to the array being changed, pass it through
      newEntityMap[entry] = entityMap[entry]
    }
  })

  return newEntityMap
}

export const removeAllEntities = (arrayPath, entityMap) => {
  const newEntityMap = {}

  Object.keys(entityMap).forEach(entry => {
    if (entry === arrayPath) {
      // Pass through the parent array value of an entity
      newEntityMap[entry] = entityMap[entry]
    } else if (entry.includes(`${arrayPath},`)) {
      /* remove the item by doing nothing */
    } else {
      // This entity is not related at all to the array being changed, pass it through
      newEntityMap[entry] = entityMap[entry]
    }
  })

  return newEntityMap
}

/* eslint-disable */
export const reorderEntities = (materializedPath, newIndex, entityMap) => {}
