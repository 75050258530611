import React from 'react'
import {
  arrayOf,
  bool,
  func,
  number,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types'

import classNames from 'classnames'
import { SIZE_OPTIONS } from '../../../utils/constants'
import Checkbox from '../Checkbox'
import FormGroup from '../FormGroup'
import { component } from './checkbox-group.scss'

const propTypes = {
  className: string,
  isAriaLegend: bool,
  // This is required for accessibility reasons
  legend: string.isRequired,
  options: arrayOf(
    shape({
      // I think this is all that is allowed for `key`s
      key: oneOfType([number, string, arrayOf(oneOfType([number, string]))]),
      base: bool,
      className: string,
      description: string,
      disabled: bool,
      id: string,
      invalid: oneOfType([string, arrayOf(string)]),
      isRequired: bool,
      legend: string,
      label: string,
      onChange: func.isRequired,
      size: oneOf(SIZE_OPTIONS),
      value: bool,
    }),
  ).isRequired,
  size: string,
}

const defaultProps = {
  isAriaLegend: false,
  className: '',
  size: 'md',
}
/**
 * Real simple component to wrap a group of checkboxes in a fieldset/legend so we can keep styles
 * consistent
 */
const CheckboxGroup = ({ className, legend, options, isAriaLegend, ...rest }) => (
  <FormGroup
    className={classNames(className, component)}
    label={legend}
    isAriaLabel={isAriaLegend}
    {...rest}
  >
    <div
      className={classNames('checkbox-group-container', {
        'visible-legend': !isAriaLegend,
      })}
    >
      {options.map((option, idx) => (
        <Checkbox
          {...option}
          className={classNames({
            'mt-0': idx === 0,
          })}
        />
      ))}
    </div>
  </FormGroup>
)

CheckboxGroup.displayName = 'CheckboxGroup'
CheckboxGroup.propTypes = propTypes
CheckboxGroup.defaultProps = defaultProps
export default CheckboxGroup
