import React from 'react'
import { arrayOf, func, oneOf, shape, string } from 'prop-types'
import classNames from 'classnames'

import { useMediaBreakpoint } from '@hsq/media-breakpoints'

import { Dropdown } from '../..'

import Ellipses from './Ellipses'
import MultiSelectOptions from './MultiSelectOptions'
import { component } from './MultiSelectButton.scss'

const propTypes = {
  actions: arrayOf(shape()).isRequired,
  ariaLabel: string,
  className: string,
  closeSidebar: func.isRequired,
  direction: oneOf(['top', 'bottom', 'left', 'right']),
  openSidebar: func.isRequired,
  onOpen: func,
  size: oneOf(['small', 'medium', 'large']),
}

const defaultProps = {
  className: '',
  direction: 'top',
  // Note: if there is more than one MultiSelectButton on the page the ariaLabel must be unique
  ariaLabel: 'View additional options',
  size: 'small',
  onOpen: null,
}

/**
 * MultiSelectButton
 *
 *  TODO: explain me
 * 
 * actions Example
 * [{
      label,
      onSelect,
      description,
      disabled,
      disabledMessage,
      CustomComponent,
      selected,
      url,
    }]
 */
const MultiSelectButton = ({
  actions,
  ariaLabel,
  className,
  closeSidebar,
  direction,
  openSidebar,
  onOpen,
  size,
  /* store, (does this need to go to sidebar content?) */
}) => {
  const { smallDown: isMobile } = useMediaBreakpoint()

  if (!actions.length) return null

  const selectedAction = !!actions.filter(action => action.selected).length

  if (!actions.length) return null

  return (
    <span
      className={classNames(component, className, {
        'd-flex justify-content-end': isMobile,
      })}
    >
      {!!actions.length && (
        <Dropdown
          className="dropdown-menu-right"
          direction={direction}
          data-test="dropdown"
          onActivate={() => {
            if (onOpen) onOpen()
            if (isMobile) {
              openSidebar({
                Component: () => (
                  <MultiSelectOptions
                    actions={actions}
                    closeSidebar={closeSidebar}
                    isMobile={isMobile}
                    data-test="multi-select-options"
                  />
                ),
                sidebarOptions: {
                  position: 'partial-bottom',
                },
              })
            }
          }}
        >
          <Dropdown.Trigger
            className="hide-default-icon ml-2 font-weight-normal"
            color="secondary"
            aria-label={ariaLabel}
            showDecoration={false}
            size={size}
          >
            <Ellipses />
            {selectedAction && (
              <span className="action-badge" data-test="notification" />
            )}
          </Dropdown.Trigger>
          <Dropdown.Content className={classNames({ 'd-none': isMobile })}>
            <MultiSelectOptions
              actions={actions}
              closeSidebar={closeSidebar}
              data-test="multi-select-options"
            />
          </Dropdown.Content>
        </Dropdown>
      )}
    </span>
  )
}

MultiSelectButton.propTypes = propTypes
MultiSelectButton.defaultProps = defaultProps
MultiSelectButton.displayName = 'MultiSelectButton'
export default MultiSelectButton
