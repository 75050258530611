import React from 'react'
import classNames from 'classnames'
import { bool, number, oneOf, string } from 'prop-types'
import safeLoc from '../../utils/safeLoc'
import { Icon } from '../..'

import { component } from './star-rating.scss'

const propTypes = {
  ariaLabelHelper: string,
  className: string,
  color: string,
  fontSize: oneOf(['sm', 'xs', undefined]),
  outline: bool,
  overallRating: number.isRequired,
  numRatings: number,
  ratingsLink: string,
  showRatingText: bool,
}

const defaultProps = {
  ariaLabelHelper: '',
  className: '',
  color: 'primary',
  fontSize: 'sm',
  outline: false,
  numRatings: null,
  ratingsLink: '',
  showRatingText: true,
}

const StarRating = ({
  ariaLabelHelper,
  className,
  color,
  fontSize,
  outline,
  overallRating,
  numRatings,
  ratingsLink,
  showRatingText,
}) => {
  // Shows Partial stars as 1/2 filled
  const getOutlineStarIcon = (star, rating) => {
    let id = 'star'
    const difference = (star - rating).toFixed(1)
    if (rating < star && difference >= 0.3 && difference <= 0.7) {
      id = 'star-half-fill'
    } else if (
      rating >= star ||
      (rating < star && difference >= 0.1 && difference <= 0.2)
    ) {
      id = 'star-fill'
    }
    return (
      <Icon
        key={star}
        id={id}
        data-test={id}
        className={`m-0 icon-sm text-${color}`}
        presentation
      />
    )
  }

  // Shows partial stars as empty
  const getStarIcon = (star, rating) => {
    let starColor = 'gray9'
    if (rating >= star) {
      starColor = color
    }
    return (
      <Icon
        font={false}
        key={star}
        id="star-fill"
        className={`m-0 icon-xs text-${starColor}`}
        presentation
      />
    )
  }

  return (
    <div className={classNames(component, className, 'd-inline-block m-0 mt-1')}>
      <a
        className="custom-anchor d-flex align-items-center mb-1"
        aria-label={
          ratingsLink
            ? `${safeLoc('REVIEWS_reviewLinkAria', {
                backupText: 'see reviews',
              })}: ${ariaLabelHelper}, ${safeLoc('GLO_blankOfBlank', {
                params: [overallRating.toFixed(1), '5'],
                backupText: `${overallRating.toFixed(1)} of 5`,
              })}`
            : `${safeLoc('REVIEWS_dw_singleReviewAriaLabel', {
                params: ariaLabelHelper,
                backupText: `${ariaLabelHelper} review`,
              })}`
        }
        href={ratingsLink}
      >
        {showRatingText && (
          <span
            className={classNames('mr-1 text-body', {
              [`font-size-${fontSize}`]: fontSize,
            })}
          >
            {overallRating.toFixed(1)}
          </span>
        )}

        {[1, 2, 3, 4, 5].map(star =>
          outline
            ? getOutlineStarIcon(star, overallRating.toFixed(1))
            : getStarIcon(star, overallRating.toFixed(1)),
        )}
        {numRatings && (
          <span
            className={classNames('rating-number ml-1 text-body', {
              [`font-size-${fontSize}`]: fontSize,
            })}
          >{`(${numRatings})`}</span>
        )}
      </a>
    </div>
  )
}

StarRating.propTypes = propTypes
StarRating.defaultProps = defaultProps
export default StarRating
