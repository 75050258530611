import { number } from 'prop-types'
import React from 'react'

import SpotlightModal from './SpotlightModal'
import SpotlightV2 from './V2'

/**
 * Wrapper to look at version and render the appropriate Spotlight
 */
const SpotlightWrapper = ({ version, ...props }) => {
  if (version === 2) {
    // eslint-disable-next-line react/jsx-filename-extension
    return <SpotlightV2 {...props} />
  }
  return <SpotlightModal {...props} />
}

SpotlightWrapper.propTypes = {
  version: number,
}

SpotlightWrapper.defaultProps = {
  version: 1,
}

SpotlightWrapper.displayName = 'SpotlightWrapper'

export default SpotlightWrapper
