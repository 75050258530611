/**
 * Just a handy guy to handle JSON parsing and stringifying that comes when using browser storage.
 *
 * @class Storage.sesh
 * @constructor
 */
export const sesh = {
  /**
   * Sets a given property into session storage. Prolly don't include PHI, but I
   * can't say for sure.
   * @method setItem
   * @param {String} property
   * @param {All} value
   * @return {undefined}
   */
  setItem(property, value) {
    sessionStorage.setItem(property, JSON.stringify(value))
  },
  /**
   * Gets a given value from `sessionStorage`
   * @method getItem
   * @param  {String} property
   * @return {All} whatever the value was from `sessionStorage`
   */
  getItem(property) {
    // @NOTE `getItem` returns `null` even if the item is `undefined`.
    // This will prevent JSON.parse from some radical barfage.
    return JSON.parse(sessionStorage.getItem(property))
  },
  /**
   * Deletes a given property from the sesh
   * @method removeItem
   * @param  {String} property
   * @return {undefined}
   */
  removeItem(property) {
    sessionStorage.removeItem(property)
  },
  /**
   * Deletes all the properties from sesh.
   * @method clear
   */
  clear() {
    sessionStorage.clear()
  },
}

/**
 * Just a handy guy to handle JSON parsing and stringifying that comes when using browser storage.
 *
 * @class Storage.local
 * @constructor
 */
export const local = {
  /**
   * Sets a given property into session storage. Prolly don't include PHI, but I
   * can't say for sure.
   * @method setItem
   * @param {String} property
   * @param {All} value
   * @return {undefined}
   */
  setItem(property, value) {
    localStorage.setItem(property, JSON.stringify(value))
  },
  /**
   * Gets a given value from `localStorage`
   * @method getItem
   * @param  {String} property
   * @return {All} whatever the value was from `localStorage`
   */
  getItem(property) {
    // @NOTE `getItem` returns `null` even if the item is `undefined`.
    // This will prevent JSON.parse from some radical barfage.
    return JSON.parse(localStorage.getItem(property))
  },
  /**
   * Deletes a given property from the sesh
   * @method removeItem
   * @param  {String} property
   * @return {undefined}
   */
  removeItem(property) {
    localStorage.removeItem(property)
  },
  /**
   * Deletes all the properties from sesh.
   * @method clear
   */
  clear() {
    localStorage.clear()
  },
}
