import React from 'react'
import classNames from 'classnames'

import { component } from './Ellipses.scss'

// TODO: I think this will be replaced by a Nucleo Icon (remove me when it's in!)
const Ellipses = () => (
  <div className={classNames(component, 'd-flex align-content-center')}>
    <span className="dot" />
    <span className="dot" />
    <span className="dot" />
  </div>
)

export default Ellipses
