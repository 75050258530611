import { createElement, Component } from 'react'
import { bool, func, node, string } from 'prop-types'

class ClickWrapper extends Component {
  static propTypes = {
    children: node.isRequired,
    escHandler: bool,
    onClickOutside: func.isRequired,
    wrapperElement: string,
  }

  static defaultProps = {
    escHandler: true,
    wrapperElement: 'div',
  }

  componentDidMount() {
    const { escHandler } = this.props
    // Add click+touch listener to handle clicking outside component
    document.addEventListener('mouseup', this.clickHandler)
    document.addEventListener('touchend', this.clickHandler)

    if (escHandler) {
      // Add a keydown listener to handle pressing `esc`
      document.addEventListener('keydown', this.keyHandler)
    }
  }

  componentWillUnmount() {
    const { escHandler } = this.props
    document.removeEventListener('mouseup', this.clickHandler)
    document.removeEventListener('touchend', this.clickHandler)
    if (escHandler) {
      document.removeEventListener('keydown', this.keyHandler)
    }
  }

  clickHandler = e => {
    const { onClickOutside } = this.props
    if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      onClickOutside(e)
    }
  }

  keyHandler = e => {
    const { onClickOutside } = this.props
    if (e.which === 27) onClickOutside(e)
  }

  setRef = ref => {
    this.wrapperRef = ref
  }

  render() {
    const { wrapperElement, children } = this.props
    return createElement(
      wrapperElement,
      { ref: this.setRef, 'data-test': 'click-wrapper' },
      children,
    )
  }
}

export default ClickWrapper
