import { useContext } from 'react'

import MediaBreakpointContext from './Context'

function useMediaBreakpoint(props) {
  const context = useContext(MediaBreakpointContext)
  // TODO: process.env.NODE_ENV !== 'production' doesn't seem to be working
  if (context && context.defaultContextValue && process.env.NODE_ENV !== 'production') {
    console.warn(
      'useMediaBreakpoint() must be used within a MediaBreakpointProvider component',
      props,
    )
  }
  return context
}

export default useMediaBreakpoint
