/**
 * Determine if a value differs from it's default
 *  - does not flag `null` as different from an empty string
 *
 * @method isValueDirty
 * @param  {String}     type              Data type of the value
 * @param  {Any}        value             Current data value
 * @param  {Any}        valueDefault      Default data value
 * @param  {Boolean}    compareNullValues Flag indicating if truthy values should be distinguished
 * @return {Boolean}    Indicator that the value and valueDefault are different
 */
export const isValueDirty = ({ type, value, valueDefault, compareNullValues = false }) => {
  let dirty

  if (
    !compareNullValues && // Only allow truthy comparisons if compareNullValues is not set
    (type === 'string' || type === 'number' || type === 'integer')
  ) {
    // If either the valueDefault or value is falsey, compare their truthiness
    //  - This handles cases where the default value of a string or number is `null`
    //    but the current value is an empty string -- which we don't want to flag
    //    as dirty
    //  - Do not convert data to a boolean if it's value is ZERO (to account for numbers)
    const truthyDefault = valueDefault === 0 ? valueDefault : !!valueDefault
    const truthyValue = value === 0 ? value : !!value

    if (!truthyDefault || !truthyValue) {
      dirty = truthyDefault !== truthyValue
    } else {
      dirty = valueDefault !== value
    }
  } else {
    dirty = valueDefault !== value
  }
  return dirty
}

/**
 * @method shouldCompareNullValues
 *  Helper function to step through the elements in the materialized path and determine
 *  if any of the nodes have a flag to compareNullValues.
 *
 * @param {String} materializedPath     Set of path nodes describing how to traverse a jsonSchema
 * @param {Object} node                 Schema node whose root corresponds to the root of the `materializedPath`
 * @return {Boolean}  Indicator that the node defined by the materializedPath should compare nulls
 */
export const shouldCompareNullValues = ({ materializedPath, node }) => {
  const { items, properties, type, compareNullValues } = node

  if (compareNullValues) return true

  // Select the next node & materializedPath
  const nextNode = materializedPath.split(',').shift()
  const nextPath = materializedPath
    .split(',')
    .slice(1)
    .join(',') // remove current element (root)

  // Proceed to the next node in the tree if it exists
  if (type === 'object' && properties) {
    const childNode = properties[nextNode]
    return shouldCompareNullValues({ node: childNode, materializedPath: nextPath })
  }
  if (type === 'array' && Array.isArray(items)) {
    const childNode = items[nextNode]
    return shouldCompareNullValues({ node: childNode, materializedPath: nextPath })
  }
  return false
}
