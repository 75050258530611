import Ajv from 'ajv'
import createNodeDefaultValue from './create-node-default-value'
import getSchemaNode from './get-schema-node'
import compileFormValue from './compile-form-value'

// The serialize and cache options below are used to improve ajv's initialization time.
// More information can be found here: https://github.com/ajv-validator/ajv/issues/1098
const ajv = new Ajv({
  verbose: true,
  nullable: true,
  serialize: false,
  cache: {
    put: () => {},
    get: () => {},
    del: () => {},
    clear: () => {},
  },
})

/**
 * @method checkObjectKeyRequired
 * Manually validate if an object property is required
 *
 * This is necessary because we are validating each node individually instead
 * of the whole schema. Since jsonSchema validation checks for object key requirements
 * in the parent object, not in the node itself, we have to manually check.
 *
 * NOTE: isRequired is added to object properties in `decorate-schama.js`, so we can
 * check if the node has isRequired and then check if the value equals the defaultValue.
 *
 * @param {*} schema
 * @param {*} value
 */

// TODO: Check to see if we can remove this now that we have full form validation
export const checkObjectKeyRequired = (schema, value) => {
  const { isRequired, label, title } = schema
  if (isRequired) {
    const defaultValue = createNodeDefaultValue(schema)
    return value === defaultValue ? `A value is required for ${label || title}.` : false
  }
  return false
}

/**
 * @method validateNode
 *
 * This function compiles individual nodes and validates based on value. From the validation errors
 * returned by ajv, we extract the first error message, which can be either a custom validation
 * message that exists in the json schema, or it will take the ajv validation error message.
 * *
 * @param {*} value
 * @param {*} node
 */

export const validateNode = ({ value, node }) => {
  const getFirstErrorMessage = errors => {
    let msg = ''
    if (errors && errors.length) {
      msg = errors[0].parentSchema.validationMessage || errors[0].message
    }
    return msg
  }
  const validate = ajv.compile({
    type: 'object',
    properties: { validateNode: node },
  })
  validate({ validateNode: value })
  if (validate.errors) {
    return [getFirstErrorMessage(validate.errors)]
  }
  return false
}

/**
 * @method validateParentPaths
 *
 * For nested data, the validity of the parent paths is added to the invalidById object
 * in ajv validation. Therefore, the appropriate preference paths may be valid, but the
 * parent paths are still marked as invalid. This recursive function checks the parent paths
 * and validates them. It returns an object of validPaths, with each path set to a boolean.
 * There is also array handling.
 *
 * @param {*} valueById
 * @param {*} materializedPath
 * @param {*} schema
 * @param {*} validPaths
 */

export const validateParentPaths = ({ valueById, materializedPath, schema, validPaths = {} }) => {
  if (materializedPath.split(',').length === 1) return validPaths

  const newValidPaths = { ...validPaths }
  const parentPath = materializedPath
    .split(',')
    .slice(0, -1)
    .join(',')
  const itemSchema = getSchemaNode(schema, parentPath)
  let itemValue = valueById[parentPath]

  // The value for the indices of arrays is not saved, so we should not set invalidById for them
  // (parentType === 'array' for these nodes)
  if (itemSchema.type === 'array') {
    itemValue = compileFormValue(itemSchema, valueById, parentPath)
  }

  // Only validate if needed
  if (!itemSchema.noValidate) {
    const validate = ajv.compile({
      type: 'object',
      properties: { test: itemSchema },
    })
    validate({ test: itemValue })
    // Append the parentPath and validity boolean to newValidPaths
    newValidPaths[parentPath] = !validate.errors
  }

  // Otherwise, call getValidParentPaths on the parent
  return validateParentPaths({
    valueById,
    materializedPath: parentPath,
    schema,
    validPaths: newValidPaths,
  })
}
