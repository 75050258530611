/* eslint-disable no-prototype-builtins */
import qs from 'qs'

/**
 * the `qs` library parses everything as a string, this is a wrapper to ensure we parse as real
 * JSON.
 *
 * see: https://github.com/ljharb/qs/issues/91#issuecomment-437926409
 *
 * If a search parameter is supposed to be an array, but there is only one value of it
 * (e.g. myArray=myValue), `qs.parse` will return it as a singleton isntead of an array.
 * Passing an array of parameter names that are meant to be arrays, allows this wrapping method to
 * return those values as arrays even in the case they only have one value.
 * ```JavaScript
 * parseQueryString('myArray=myValue') // returns: { myArray: 'myValue'}
 * parseQueryString('myArray=myValue', ['myArray']) // returns: { myArray: ['myValue']}
 * ```
 *
 * @param {String} query queryString to be parsed
 * @param {Array.<String>} arrayParamNames List of parameters that should be returned as an array.
 */
export default function parseQueryString(query = '', arrayParamNames = []) {
  const sanitizedQuery = query.replace(/^\?/, '')
  const params = qs.parse(sanitizedQuery, {
    decoder(str, _decoder, charset) {
      const strWithoutPlus = str.replace(/\+/g, ' ')
      if (charset === 'iso-8859-1') {
        // unescape never throws, no try...catch needed:
        return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape)
      }

      if (/^(\d+|\d*\.\d+)$/.test(str)) {
        // If there is a leading 0, assume that is a significant identifier and do strip it
        if (str.startsWith('0')) return str
        return parseFloat(str)
      }

      const keywords = {
        true: true,
        false: false,
        null: null,
        undefined: '',
      }
      if (keywords.hasOwnProperty(str)) {
        return keywords[str]
      }

      // utf-8
      try {
        return decodeURIComponent(strWithoutPlus)
      } catch (e) {
        return strWithoutPlus
      }
    },
  })
  if (arrayParamNames && arrayParamNames.length) {
    arrayParamNames.forEach(name => {
      // Check if returned params contains the value, but it is not an array
      // using `hasOwnProperty` in case the array has an empty value. (I don't see a use case for that. What am I even doing?)
      if (params.hasOwnProperty(name) && !Array.isArray(params[name])) {
        params[name] = [params[name]]
      }
    })
  }
  return params
}
