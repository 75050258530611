import * as React from 'react'
import { string, node, number, shape } from 'prop-types'
import nanoid from 'nanoid'

// modified component from https://github.com/danilowoz/react-content-loader/blob/master/src/web/Svg.tsx

const uid = () => nanoid(6)

const SkeletonWrapper = ({
  // animate,
  backgroundColor,
  backgroundOpacity,
  baseUrl,
  children,
  foregroundColor,
  foregroundOpacity,
  // gradientRatio,
  uniqueKey,
  // interval,
  // rtl,
  speed,
  style,
  title,
  ...props
}) => {
  const animate = true
  const idClip = `${uniqueKey}-diff`
  const idGradient = `${uniqueKey}-animated-diff`
  const idAria = `${uniqueKey}-aria`

  // const rtlStyle = rtl ? { transform: 'scaleX(-1)' } : null
  const keyTimes = `0; 0.25; 0.5; 0.75; 1`
  const dur = `${speed}s`

  return (
    <svg
      aria-labelledby={idAria}
      role="img"
      style={{ ...style /* , ...rtlStyle */ }}
      {...props}
    >
      {title ? <title id={idAria}>{title}</title> : null}
      <rect
        role="presentation"
        x="0"
        y="0"
        width="100%"
        height="100%"
        clipPath={`url(${baseUrl}#${idClip})`}
        style={{ fill: `url(${baseUrl}#${idGradient})` }}
      />

      <defs role="presentation">
        <clipPath id={idClip}>{children}</clipPath>
        <linearGradient id={idGradient}>
          {/* left side of left edge of moving block to left end (beginning) */}
          <stop
            offset="100%"
            stopColor={foregroundColor}
            stopOpacity={foregroundOpacity}
          >
            {animate && (
              <animate
                id="s1"
                attributeName="offset"
                values="0; 0; 0; 1; 1"
                keyTimes={keyTimes}
                dur={dur}
                repeatCount="indefinite"
              />
            )}
          </stop>
          {/* right side of left edge of moving block */}
          <stop
            offset="100%"
            stopColor={backgroundColor}
            stopOpacity={backgroundOpacity}
          >
            {animate && (
              <animate
                id="s1"
                attributeName="offset"
                values="0; 0; 0; 1; 1"
                keyTimes={keyTimes}
                dur={dur}
                repeatCount="indefinite"
              />
            )}
          </stop>
          {/* left side of right edge of moving block */}
          <stop
            offset="100%"
            stopColor={backgroundColor}
            stopOpacity={backgroundOpacity}
          >
            {animate && (
              <animate
                id="s1"
                attributeName="offset"
                values="0; 0; 1; 1; 1"
                keyTimes={keyTimes}
                dur={dur}
                repeatCount="indefinite"
              />
            )}
          </stop>
          {/* right side of right edge of moving block to right end (finish) */}
          <stop
            offset="100%"
            stopColor={foregroundColor}
            stopOpacity={foregroundOpacity}
          >
            {animate && (
              <animate
                id="s1"
                attributeName="offset"
                values="0; 0; 1; 1; 1"
                keyTimes={keyTimes}
                dur={dur}
                repeatCount="indefinite"
              />
            )}
          </stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

SkeletonWrapper.propTypes = {
  backgroundColor: string,
  backgroundOpacity: number,
  baseUrl: string,
  children: node.isRequired,
  foregroundColor: string,
  foregroundOpacity: number,
  id: string,
  uniqueKey: string,
  speed: number,
  style: shape({}),
  title: string,
}

SkeletonWrapper.defaultProps = {
  // animate: true,
  backgroundColor: '#e5e5e5',
  backgroundOpacity: 1,
  baseUrl: '',
  foregroundColor: '#eee',
  foregroundOpacity: 1,
  // gradientRatio: 2,
  id: null,
  // interval: 0.25,
  // rtl: false,
  uniqueKey: uid(),
  speed: 3,
  style: {},
  title: 'Loading...',
}

export default SkeletonWrapper
