import React from 'react'
import { arrayOf, bool, func, oneOfType, string } from 'prop-types'
import nanoid from 'nanoid'
import classNames from 'classnames'

import { RequiredFlag } from '../../utilComponents'

import { component } from './toggle.scss'

const propTypes = {
  className: string,
  disabled: bool,
  id: string,
  invalid: oneOfType([string, arrayOf(string)]),
  isRequired: bool,
  label: string,
  onChange: func.isRequired,
  renderBooleanValues: bool,
  renderDisabledAsText: bool,
  showLabel: bool,
  value: bool.isRequired,
}

const defaultProps = {
  className: '',
  disabled: false,
  id: '',
  invalid: null,
  isRequired: false,
  label: '',
  renderBooleanValues: false,
  renderDisabledAsText: false,
  showLabel: false,
}

// In testing use the same guid always so that snapshots are consistent
const localGuid = () => (process.env.NODE_ENV === 'test' ? 'test-id' : nanoid())

const BaseToggle = ({
  className,
  disabled,
  id,
  invalid,
  isRequired,
  label,
  renderBooleanValues,
  renderDisabledAsText,
  showLabel,
  value,
  ...rest
}) => {
  const guid = id || localGuid()
  const computedLabel = label || ''

  const renderLabel = () => (
    <span className={classNames('input-checkbox-label', { disabled })}>
      {computedLabel}
      {isRequired && <RequiredFlag className="ml-3" />}
    </span>
  )

  if (disabled && renderDisabledAsText) {
    return (
      <span
        className={classNames(component, className, 'd-flex input-toggle', {
          'is-invalid': invalid && invalid.length,
        })}
      >
        {value ? (
          <div className="text-success">
            {renderBooleanValues ? 'True' : 'Active'}
          </div>
        ) : (
          <div className="text-muted">
            {renderBooleanValues ? 'False' : 'Inactive'}
          </div>
        )}
        {showLabel && renderLabel()}
      </span>
    )
  }

  return (
    <span
      className={classNames(component, className, 'input-toggle', {
        'is-invalid': invalid && invalid.length, // Bootstrap color
      })}
    >
      <label className="toggle-label m-0" htmlFor={guid}>
        <span className="toggle-container">
          <input
            id={guid}
            checked={value}
            type="checkbox"
            disabled={disabled}
            {...rest}
          />
          <span className="toggle" />
        </span>
        {/* TODO: Look for a cleaner way to keep a functional toggle without a label  */}
        {showLabel && renderLabel()}
      </label>
    </span>
  )
}

BaseToggle.displayName = 'BaseToggle'
BaseToggle.propTypes = propTypes
BaseToggle.defaultProps = defaultProps
export default BaseToggle
