import React from 'react'
import { shape } from 'prop-types'
import compileValidationMessage from './validationHelper'
import SelectDropdown from '../SelectDropdown'

const propTypes = {
  schemaNode: shape(),
}

const defaultProps = {
  schemaNode: {},
}

/**
 * SelectDropdownContainer
 * Wrapper for the SelectDropdown form elements passed into a Formogorgon Form that
 * converts Formogorgon related props to those expected by the plain components.
 */
const SelectDropdownContainer = ({ schemaNode, ...rest }) => {
  const {
    // Props being used here to define the input attributes
    base,
    disabled,
    dynamicOptions = {},
    guid,
    id,
    invalid,
    label,
    materializedPath,
    onChange,
    readOnly, // Formogorgon flag to render a disabled form
    title,
    validationMessage, // Optional schemaNode override for invalid message from AJV
    /* Form related props that are not used here and don't get passed to the input 🙅 */
    currentMaterializedPath,
    debounced,
    defaultValue,
    dirty,
    editDecoration,
    parentType,
    nullable,
    pattern,
    type,
    NodeComponent,
    uiMenuComponent,
    /* Remaining props to pass straight through to the rendered input component */
    ...propsRest
  } = {
    ...schemaNode,
    // ℹ️ Allow passed props to override the schemaNode values
    ...rest,
  }

  // dynamicOptions is the only way to  pass a prop to SchemaNode that will trigger
  // a component update when the prop changes
  const { validationOverride } = dynamicOptions

  const derivedDisabled = disabled || readOnly
  const derivedId = materializedPath || id || guid || ''
  // Get the invalid message based upon the three possible values
  const derivedInvalid = compileValidationMessage({
    invalid,
    validationMessage,
    validationOverride,
  })
  const derivedLabel =
    label || title || (materializedPath && materializedPath.split(',').pop()) || ''
  // The Formogorgon onChange is expecting either an event or an object with a `value`
  const derivedOnChange = value => onChange({ value })

  return (
    <SelectDropdown
      base={base}
      disabled={derivedDisabled}
      id={derivedId}
      invalid={derivedInvalid}
      label={derivedLabel}
      onChange={derivedOnChange}
      {...propsRest}
    />
  )
}

SelectDropdownContainer.displayName = 'SelectDropdownContainer'
SelectDropdownContainer.defaultProps = defaultProps
SelectDropdownContainer.propTypes = propTypes
export default SelectDropdownContainer
