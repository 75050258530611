/*
 * Simple debounce function adapted from Underscore.
 *
 * TODO: If we use this for anything more complex, it might be worth using lodash's
 * debounce https://www.npmjs.com/package/lodash.debounce
 */

/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 * @method debounce
 * @param  {Function} func      The function you want to debounce
 * @param  {Number} wait        The delay until the function is called
 * @param  {Boolean} immediate  Indicator to trigger the function at the leading edge
 * @return {Function}           Debounced `func`
 */
export default function debounce(func, wait, immediate) {
  let timeout
  return (...args) => {
    const later = () => {
      timeout = null
      if (!immediate) func.apply(this, args)
    }

    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)

    if (callNow) func.apply(this, args)
  }
}
