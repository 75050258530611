import React from 'react'
import { bool, string } from 'prop-types'
import classNames from 'classnames'

import BaseToggle from './BaseToggle'

const propTypes = {
  base: bool,
  className: string,
}

const defaultProps = {
  base: false,
  className: '',
}

const Toggle = ({ base, className, ...rest }) => {
  if (base) return <BaseToggle className={className} {...rest} />

  return (
    <div className={classNames(className, 'd-flex form-group')}>
      <BaseToggle showLabel {...rest} />
    </div>
  )
}

Toggle.displayName = 'Toggle'
Toggle.propTypes = propTypes
Toggle.defaultProps = defaultProps
export default Toggle
