import React, { Component } from 'react'
import {
  arrayOf,
  bool,
  func,
  instanceOf,
  number,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types'
import classNames from 'classnames'
import nanoid from 'nanoid'
import Select, { components } from 'react-select'
import Creatable from 'react-select/creatable'

import { Icon } from '../../..'
import { FormHelpText, RequiredFlag } from '../../utilComponents'
import { SIZE_OPTIONS } from '../../../utils/constants'
import {
  sharedCustomStyles,
  themeOverrides,
} from '../../../utils/reactSelectHelpers'

import { component } from './select-dropdown.scss'

/**
 * SelectDropdown is a form component used to select a single value
 *
 * Values may be passed in one of two formats: either a single string or an
 * object with `value` and `label` string attributes.
 * NOTE: Values will be returned to the onChange handler in the format that
 * they were originally passed in.
 */
class SelectDropdown extends Component {
  static displayName = 'SelectDropdown'

  static propTypes = {
    additionalItems: bool,
    appendLabel: string,
    base: bool,
    className: string,
    conditionallyPrependValue: shape({
      value: string,
      pattern: instanceOf(RegExp),
    }),
    description: string,
    disabled: bool,
    enumerables: arrayOf(oneOfType([number, string])).isRequired,
    id: string,
    invalid: oneOfType([string, arrayOf(string)]),
    isRequired: bool,
    label: string,
    onChange: func.isRequired,
    prependValue: string,
    size: oneOf(SIZE_OPTIONS),
    value: oneOfType([number, string, shape({ value: string, label: string })]),
  }

  static defaultProps = {
    additionalItems: true,
    appendLabel: '',
    base: false,
    className: '',
    conditionallyPrependValue: null,
    description: '',
    disabled: false,
    id: '',
    invalid: '',
    isRequired: false,
    label: '',
    prependValue: '',
    size: 'md',
    value: '',
  }

  componentDidMount() {
    const { enumerables, value } = this.props

    if (enumerables.length) {
      this.initialValueType = typeof enumerables[0]
    } else if (value.length) {
      this.initialValueType = typeof value[0]
    } else {
      this.initialValueType = 'string'
    }
  }

  getCreatedValue = inputValue => {
    const { prependValue, conditionallyPrependValue } = this.props
    let actualPrependValue = prependValue

    if (conditionallyPrependValue) {
      const { value: prependedValue, pattern } = conditionallyPrependValue
      if (inputValue.match(pattern)) actualPrependValue = prependedValue
    }
    return `${actualPrependValue}${inputValue}`
  }

  handleOnChange = (newValue, actionType) => {
    const { onChange } = this.props
    const { action } = actionType
    let valueToSave = newValue

    // If a new option is created, check if it should be modified
    if (action === 'create-option') {
      const createdValue = this.getCreatedValue(newValue.value)
      valueToSave = { value: createdValue, label: createdValue }
    }

    // Return the same data type that was initially passed in (since 2 are allowed)
    if (this.initialValueType === 'object') {
      onChange(valueToSave)
      return
    }
    onChange(valueToSave.value)
  }

  formatCreateLabel = inputValue =>
    `Create option "${this.getCreatedValue(inputValue)}"`

  // In testing use the same guid always so that snapshots are consistent
  localGuid = () => (process.env.NODE_ENV === 'test' ? 'test-id' : nanoid())

  renderInvalidFeedback = invalid => {
    if (Array.isArray(invalid)) {
      return invalid.map(
        message => message && <span className="invalid-feedback">{message}</span>,
      )
    }
    return <span className="invalid-feedback">{invalid}</span>
  }

  renderSelect = guid => {
    const {
      additionalItems,
      appendLabel,
      base,
      className,
      description,
      disabled,
      enumerables,
      invalid,
      onChange,
      size,
      value,
      // Props that are not currently used but should not be passed to the input 🙅
      id,
      isRequired,
      ...rest
    } = this.props
    const enumCopy = [...enumerables]
    // A custom key has to manually be added to enumerables.
    // Any selected key comes in as a value, so you need to check if enumerables already has it
    // if not, add it
    if (value && !enumCopy.includes(value)) {
      enumCopy.push(value)
    }
    const options = enumCopy.map(e => ({
      value: e,
      label: appendLabel ? `${e} ${appendLabel}` : e,
    }))

    const SelectComponent = additionalItems ? Creatable : Select
    const placeholder = additionalItems ? 'Select or enter a value...' : 'Select...'

    const renderSelectValue = () => {
      const renderedValue = appendLabel
        ? { value, label: `${value} ${appendLabel}` }
        : { value, label: value }
      return renderedValue
    }

    return (
      <SelectComponent
        className={classNames(className, 'react-select', {
          'is-invalid': invalid && invalid.length,
          'vanilla-component': base,
        })}
        components={{
          Option: props => (
            <components.Option
              {...props}
              innerProps={{
                ...props.innerProps,
                'data-test': 'dropdown-option',
              }}
            >
              {props.children}
            </components.Option>
          ),
          IndicatorSeparator: () => null,
          DropdownIndicator: state => (
            <Icon
              className="mx-2"
              id={state.selectProps?.menuIsOpen ? 'chevron-up' : 'chevron-down'}
            />
          ),
        }}
        formatCreateLabel={this.formatCreateLabel}
        id={guid}
        isClearable={false}
        isDisabled={disabled}
        isSearchable
        onChange={this.handleOnChange}
        options={options}
        placeholder={placeholder}
        styles={sharedCustomStyles(this.props)}
        theme={theme => themeOverrides(theme, this.props)}
        value={value ? renderSelectValue() : null}
        {...rest}
      />
    )
  }

  render() {
    const {
      base,
      className,
      description,
      id,
      invalid,
      isRequired,
      label,
      size,
    } = this.props
    const computedLabel = label || ''
    const guid = id || this.localGuid()

    if (base)
      return (
        <div className={`${component} input-select w-100`} data-test="input-select">
          {this.renderSelect(guid)}
        </div>
      )

    return (
      <>
        <div
          className={classNames(
            component,
            className,
            'form-group input-select input-common-component',
            `form-control-${size}`,
          )}
          data-test="input-select"
        >
          {label ? (
            <div className="w-100">
              <div
                className={classNames(
                  'd-flex justify-content-between align-items-baseline',
                  { 'mb-1': computedLabel },
                )}
              >
                <label
                  htmlFor={guid}
                  className="input-text-label"
                  data-test="label"
                >
                  {computedLabel}
                </label>
                {isRequired && <RequiredFlag />}
              </div>
              {this.renderSelect(guid)}
            </div>
          ) : (
            this.renderSelect(guid)
          )}
          <FormHelpText
            description={description}
            invalid={invalid}
            id={`${guid}-helper`}
          />
        </div>
      </>
    )
  }
}

export default SelectDropdown
