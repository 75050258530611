/**
 * Returns the validation message or array of messages based upon the combination
 * of messages provided
 * @method compileValidationMessage
 */
export default function compileValidationMessage({
  invalid,
  validationMessage,
  validationOverride,
}) {
  // Only override the `invalid` message with the `validationMessage` if they both exist
  const formValidation =
    invalid && invalid.length && validationMessage ? validationMessage : invalid

  // Override the current invalid message with the `validationOverride` if it exists
  if (validationOverride) {
    if (Array.isArray(formValidation))
      return [...formValidation, validationOverride]
    return formValidation
      ? [formValidation, validationOverride]
      : validationOverride
  }

  return formValidation
}
