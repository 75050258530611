/**
 * Decorates the form schema nodes with useful meta data:
 *
 * * Adds `parentType` to each node for reference
 * * Renames `enum` to `enumerables` b/c React uses enumerables
 * @param node
 * @param meta Passed meta about the node that is not available in the node itself
 */
const visitNode = (node, meta) => {
  /* eslint-disable no-param-reassign */

  // ℹ️ We expect all object nodes to have a properties field, even if it's empty
  const { items, type, properties = {} } = node
  const { isRequired, parentType, ...metaRest } = meta

  // Include parent type on node so that we can know if we're an array child
  node.parentType = parentType

  if (type !== 'object' && type !== 'array') {
    // Update `enum` property to `enumerables b/c `enum` is a reserved keyword
    if (node.enum) node.enumerables = node.enum
    // If there is a jsonSchema title, decorate a label prop for form components
    if (node.title && !node.label) node.label = node.title
    // Propogate these global form-level attributes to all schemaNodes
    if (Object.keys(metaRest).length) {
      Object.keys(metaRest).forEach(attribute => {
        node[attribute] = metaRest[attribute]
      })
    }
  }

  // Default all strings to allow nulls, can be overridden with nullable property
  if (type === 'string' || type === 'number' || type === 'integer') {
    node.nullable = 'nullable' in node ? node.nullable : true
  }

  // Knowing when a node is required is useful, but the `required` keyword can only
  // be used in objects, so we decorate a `isRequired` property on children nodes
  // NOTE: AJV considers an empty string valid if it is marked as required. This does not.
  if (isRequired) {
    // For custom validation
    node.isRequired = true
    // For AJV validation
    if (node.type === 'string' && !node.minLength && !node.validationMessage)
      node.validationMessage = `A value is required for ${node.title || node.key}.`
    if (node.type === 'string' && !node.minLength) node.minLength = 1
  }

  // ========================================================
  // Object nodes - validate and walk properties
  // ========================================================

  if (type === 'object') {
    Object.keys(properties).forEach(property => {
      visitNode(properties[property], {
        parentType: 'object',
        isRequired: node.required && node.required.indexOf(property) !== -1,
        ...metaRest,
      })
    })
  }

  // ========================================================
  // Array nodes - validate and walk items
  // ========================================================

  if (type === 'array') {
    if (Array.isArray(items)) {
      items.forEach(item => {
        visitNode(item, { parentType: 'array', ...metaRest })
      })
    } else {
      visitNode(items, { parentType: 'array', ...metaRest })
    }
  }
  /* eslint-enable no-param-reassign */
}

/**
 * Handle decorating the schema with meta data needed for Form and SchemaNode
 * components.
 */
export default (schema, options) => {
  // These 🎿 are made for walking!!!
  visitNode(schema, { ...options, parentType: 'ROOT' })

  return schema
}
