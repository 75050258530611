import React, { Component } from 'react'
import { func, string } from 'prop-types'
import classNames from 'classnames'
import { BaseInput, Button, FormGroup, Icon } from '../..'

import { component } from './search-bar.scss'

/**
 * SearchBar
 * - String input component that will trigger an onSubmit callback if the user hits
 * ENTER or clicks the search button.
 * - If the `searchValue` prop is manually updated, the change will be reflected in
 * the search input, and the onSubmit callback will be triggered.
 *
 */
class SearchBar extends Component {
  static displayName = 'SearchBar'

  static propTypes = {
    onSubmit: func.isRequired,
    placeholder: string,
    searchValue: string,
    size: string,
    warning: string,
  }

  static defaultProps = {
    placeholder: '',
    searchValue: '',
    size: undefined,
    warning: undefined,
  }

  state = {
    /* eslint-disable-next-line react/destructuring-assignment */
    value: this.props.searchValue,
  }

  componentDidUpdate(prevProps) {
    const { onSubmit, searchValue } = this.props
    const { searchValue: prevValue } = prevProps
    // If the search value is manually changed then trigger a search with it
    if (searchValue !== prevValue) {
      this.setState({ value: searchValue })
      onSubmit(searchValue)
    }
  }

  clearInput = () => {
    // Clear input and trigger the search AFTER value has been cleared in state
    this.setState({ value: '' }, () => this.search())
  }

  search = () => {
    const { onSubmit } = this.props
    const { value } = this.state
    onSubmit(value)
  }

  handleKeyPress = evt => {
    if (evt.key === 'Enter') {
      this.search()
    }
  }

  renderClearButton = () => (
    <Button onClick={this.clearInput} aria-label="Clear search" link>
      <Icon className="ml-1" id="close-circle-fill" presentation />
    </Button>
  )

  render() {
    const { placeholder, warning, size } = this.props
    const { value } = this.state
    const buttonSizes = { sm: 'small', lg: 'large' }
    return (
      <FormGroup inputId="search-bar" warning={warning}>
        <span className={classNames(component, 'd-flex')}>
          <BaseInput
            data-test="search-input"
            decoration={value ? this.renderClearButton() : null}
            onChange={e => this.setState({ value: e.target.value })}
            onKeyPress={this.handleKeyPress}
            placeholder={placeholder}
            value={value}
            size={size}
          />
          <Button
            alt="search button"
            className="search-button rounded-0 btn-primary px-3 py-1"
            data-test="search-button"
            onClick={this.search}
            size={buttonSizes[size]}
          >
            <Icon id="search" />
          </Button>
        </span>
      </FormGroup>
    )
  }
}

export default SearchBar
