import React, { Component } from 'react'
import { bool, func, node, oneOfType, shape, string } from 'prop-types'
import { connect } from 'react-redux'

import { getFormEntities, getFormSchema, setFormFields as dispatchSetFormFields } from '../dux/form'
import compileEntityMaps from '../utils/compile-entity-maps'
import compileFormValue from '../utils/compile-form-value'
import decorateFormData from '../utils/decorate-form-data'
import decorateSchema from '../utils/decorate-schema'

import FormContext from '../FormContext/FormContext'
import SchemaNode from '../SchemaNode'

import { reduxNodeComponentMap as defaultComponentMap } from '../utils/default-node-component-maps'

/**
 * Form
 * Outer container of a jsonSchema driven form that uses REDUX to store it's data.
 *
 * Required props:
 * - defaultSchema (any valid jsonSchema)
 * - formId (unique string)
 * - onSubmit (function called when submit button is clicked with the current formData)
 *
 * Required Dependencies:
 * - A reducer must be initialized in the consuming application using the same formId as this Form
 * (EX: makeFormReducer('someSpecialForm'))
 *
 */
class Form extends Component {
  static displayName = 'Form'

  static propTypes = {
    // --- Consumer props
    children: oneOfType([node, func]),
    /** Custom component refs */
    componentMap: shape(),
    /** Custom rules for determining the component for a node */
    customNodeComponentRules: func,
    /** Flag that all input fields should debounce typing */
    debounced: bool,
    /** Starting base data */
    defaultData: shape(),
    /** starting schema */
    defaultSchema: shape().isRequired,
    /** Flag that all input fields should have an edit icon (deprecated) */
    editDecoration: bool,
    /** Optional: starting dirty form data */
    formData: shape(),
    /** The namespace of the form in the redux state */
    formId: string.isRequired,
    /** Prop called on form submit with form data */
    onSubmit: func.isRequired,
    /** Starting view mode */
    readOnly: bool,
    // --- Redux props
    valueById: shape().isRequired,
    defaultById: shape().isRequired,
    dirtyById: shape().isRequired,
    invalidById: shape().isRequired,
    schema: shape(),
    /** Dispatches form setup action */
    setFormFields: func.isRequired,
    validateOnMount: bool,
    /* Change this to cause a full form re-render */
    version: string,
  }

  static defaultProps = {
    children: null,
    componentMap: {},
    customNodeComponentRules: () => null,
    debounced: false,
    defaultData: {},
    editDecoration: false,
    formData: null,
    readOnly: false,
    schema: null,
    validateOnMount: true,
    version: '',
  }

  /* eslint-disable react/no-unused-state */
  /* eslint-disable react/destructuring-assignment */
  state = {
    FORM_PROPERTIES: {
      componentMap: {
        ...defaultComponentMap(),
        ...this.props.componentMap,
      },
      customNodeComponentRules: this.props.customNodeComponentRules,
    },
  }
  /* eslint-enable react/destructuring-assignment */

  // Hooks
  // ---------------------------------------------------------------------------

  /**
   * 1. Decorate the passed schema with useful node meta data.
   * 2. Create initial entity maps for form data values
   */
  componentDidMount() {
    const { validateOnMount } = this.props
    this.initializeForm({ validate: validateOnMount })
  }

  componentDidUpdate(prevProps) {
    const { componentMap, customNodeComponentRules, version } = this.props

    // If the form version is forced to change, re-initialize the form
    if (prevProps.version !== version) this.initializeForm({ validate: true })

    // The componentMap object is technically new on every rerender, so we need to
    // specifically check if any of the component names change
    const prevComponentMap = JSON.stringify(
      Object.values(prevProps.componentMap).map(c => c.displayName),
    )
    const newComponentMap = JSON.stringify(Object.values(componentMap).map(c => c.displayName))
    // Update the Form context if the componentMap changes after the Form is initiated
    if (prevComponentMap !== newComponentMap) {
      this.setState({
        FORM_PROPERTIES: {
          componentMap: {
            ...defaultComponentMap(),
            ...componentMap,
          },
          customNodeComponentRules,
        },
      })
    }
  }

  // Methods
  // ---------------------------------------------------------------------------
  initializeForm = ({ validate }) => {
    const {
      debounced,
      defaultSchema,
      defaultData,
      editDecoration,
      formData,
      formId,
      readOnly,
      setFormFields,
    } = this.props

    // Decorate the schema nodes with meta data
    const decoratedSchema = decorateSchema(defaultSchema, {
      editDecoration,
      debounced,
    })
    // Decorate the default data so that all arrays and object fields have values
    const decoratedDefaultData = decorateFormData(decoratedSchema, defaultData)
    const decoratedFormData = formData
      ? decorateFormData(decoratedSchema, formData)
      : decoratedDefaultData
    // Create the entity maps for the form store values
    const entityMaps = compileEntityMaps(
      decoratedSchema,
      decoratedFormData,
      formId,
      decoratedDefaultData,
      { withSchemaMap: true, validate },
    )
    const { invalidById } = entityMaps
    const globalInvalidOverride = !!Object.keys(invalidById).length

    setFormFields(
      formId,
      decoratedSchema,
      { ...entityMaps, invalidById: {} },
      {
        readOnly,
        globalInvalidOverride,
      },
    ) // Initialize redux data
  }

  handleSubmit = evt => {
    evt.preventDefault()
    const { formId, onSubmit, schema, valueById } = this.props

    // TODO: Either check validation on load, or check validation on submit. Currently
    // it doesnt happen until you update a form field.

    // Handle submitting with data
    onSubmit(compileFormValue(schema, valueById, formId), evt)
  }

  // Render
  // ---------------------------------------------------------------------------

  renderFormContents = () => {
    const {
      children,
      defaultData,
      defaultById,
      dirtyById,
      formId,
      invalidById,
      valueById,
    } = this.props

    // FACC Form Rendering
    if (typeof children === 'function') {
      return children({
        defaultById,
        defaultData,
        valueById,
        invalidById,
        dirtyById,
      })
    }

    // Manual Form rendering
    if (children) {
      return children
    }

    // Default Form & Submit button rendering
    return (
      <>
        <SchemaNode materializedPath={formId} />
        <button className="btn btn-primary mt-2" onClick={this.handleSubmit} type="submit">
          Submit
        </button>
      </>
    )
  }

  render() {
    const {
      // 🙅‍♀️ You shall not pass!
      children,
      componentMap,
      customNodeComponentRules,
      debounced,
      defaultData,
      defaultSchema,
      editDecoration,
      formId,
      formData,
      onSubmit,
      setFormFields,
      valueById,
      validateOnMount,
      defaultById,
      dirtyById,
      invalidById,
      schema,
      ...dom
    } = this.props

    if (!schema || !Object.keys(schema).length) return null // Wait for the calculations in componentDidMount

    return (
      <FormContext.Provider value={this.state}>
        <form onSubmit={this.handleSubmit} noValidate data-test="form" {...dom}>
          {this.renderFormContents()}
        </form>
      </FormContext.Provider>
    )
  }
}

const mapStateToProps = (store, ownProps) => ({
  ...getFormEntities(store, ownProps.formId),
  schema: getFormSchema(store, ownProps.formId),
})

const mapDispatchToProps = {
  setFormFields: dispatchSetFormFields,
}

export default connect(mapStateToProps, mapDispatchToProps)(Form)
