import { createElement } from 'react'

import SchemaNode from '../SchemaNode'

/**
 * Handles the different children node scenarios for object and array types, mapping
 * over the children nodes and returning a SchemaNode component for each.
 *
 * ℹ️ Using this utility to render children nodes is optional, it is available to
 * simplify mapping over the different children node shapes for objects and arrays,
 * but any component can also pick out children nodes and directly create SchemaNode
 * entries using those children nodes.
 * @param {object} schemaNode Schema node of the parent container
 * @param {string} sortprop Optional property by which we should sort our nodes
 */
const renderChildrenNodes = ({ schemaNode, sortprop, dynamicOptions }) => {
  const { itemsCount, properties, currentMaterializedPath } = schemaNode
  const childrenNodes = []

  if (properties) {
    // If we have a sortprop, sort these nodes before rendering, otherwise
    // just let Object.keys spit em out however it wants.
    const nodesToRender = sortprop
      ? Object.keys(properties).sort((a, b) =>
          properties[a][sortprop].localeCompare(properties[b][sortprop]),
        )
      : Object.keys(properties)

    nodesToRender.forEach(field => {
      const childMaterializedPath = `${currentMaterializedPath},${field}`

      childrenNodes.push(
        createElement(SchemaNode, {
          dynamicOptions,
          key: childMaterializedPath,
          materializedPath: childMaterializedPath,
        }),
      )
    })
  } else {
    // For arrays iterate over the Formogorgon entries field
    for (let i = 0, ii = itemsCount; i < ii; i += 1) {
      const childMaterializedPath = `${currentMaterializedPath},${i}`

      childrenNodes.push(
        createElement(SchemaNode, {
          dynamicOptions,
          key: childMaterializedPath,
          materializedPath: childMaterializedPath,
        }),
      )
    }
  }

  return childrenNodes
}

export default renderChildrenNodes
