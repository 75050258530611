import React from 'react'
import { string } from 'prop-types'

const PhoneLink = ({ phone, children, ...rest }) => {
  if (!phone) return null

  return (
    // rest to bind things like `className` and `onClick`
    <a {...rest} href={`tel:${phone.replace(/\D/g, '')}`}>
      {children || phone}
    </a>
  )
}

PhoneLink.propTypes = {
  phone: string,
  children: string,
}

PhoneLink.defaultProps = {
  phone: '',
  children: null,
}

export default PhoneLink
