import { useContext } from 'react'

import TranslationContext from './contexts'

function useLoc(key, params = []) {
  const context = useContext(TranslationContext)
  if (context && context.defaultContextValue) {
    if (process.env.NODE_ENV !== 'production') {
      throw new Error('useLoc() must be used within a TranslationsProvider component')
    }
    return null
  }
  const sanitizedParams = Array.isArray(params) ? params : [params]
  return key ? context.getLoc(key, sanitizedParams) : context
}

export default useLoc
