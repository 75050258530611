import React, { Component } from 'react'
import { arrayOf, bool, func, number, object, oneOfType, string } from 'prop-types'
import classNames from 'classnames'

import { BaseInput, Button, Icon, SelectDropdown } from '../..'

import { component } from './pagination.scss'

const buildPagination = (items, pageSize) => {
  const paginatedItems = []
  // Calculate number of items shown per page
  const pagedSize = Math.ceil(items.length / pageSize)

  // Build array of arrays for pagination
  // Start the array index at 1, to correspond with inputted page number
  for (
    let index = 0, sliced = 0;
    index < pagedSize;
    index += 1, sliced += pageSize
  ) {
    paginatedItems[index + 1] = items.slice(sliced, sliced + pageSize)
  }

  return paginatedItems
}

/**
 * @class Pagination
 * Navigate through rows of items based on pagination. Includes previous/next
 * pages, and jumping to specified page numbers.
 *
 * Page number is determined by either
 *  1. Incrementing or decementing from the current page, or
 *  2. Jumping directly to a specified page, via pagination array index
 */
class Pagination extends Component {
  static displayName = 'Pagination'

  static propTypes = {
    // All items to create paginated items from.
    items: arrayOf(oneOfType([object, string, number])),
    // Options for the number of items to be displayed per page
    enumerables: arrayOf(number),
    initialPageSize: number,
    // Optionally display pagination above data, position is at bottom by default.
    // Use case is with react-data-grid, where pagination should be above grid.
    positionTop: bool,
    // Render prop for returning component with paginated items.
    // Example implementation:
    //
    // <Pagination
    //   render={(items, currentPage) => (
    //    <Component content={items[currentPage]} />
    //  )}
    // />
    render: func.isRequired,
  }

  static defaultProps = {
    enumerables: [10, 25, 50, 100],
    items: [],
    initialPageSize: 25,
    positionTop: false,
  }

  state = {
    currentPage: 1,
    pageSize: this.props.initialPageSize, // eslint-disable-line react/destructuring-assignment
    paginatedItems: [[]],
    totalPages: 1,
  }

  static getDerivedStateFromProps(props, state) {
    const { items } = props
    const { pageSize } = state

    let paginatedItems = []
    if (items.length) {
      // Ideally buildPagination would be conditional based on the `items` prop changing, but for some
      // reason the items prop changing isn't triggering componentDidUpdate, so we're doin it here.
      paginatedItems = buildPagination(items, pageSize)
    }
    return {
      paginatedItems,
      totalPages: paginatedItems.length ? paginatedItems.length - 1 : 1,
    }
  }

  componentDidMount() {
    this.resetPage()
  }

  componentDidUpdate(prevProps, prevState) {
    const { items } = this.props
    const { pageSize } = this.state

    // If the someone changes the number of items to show per page or the number of items in total (by filtering),
    // go back to the first page and recalculate the pagination.
    if (
      pageSize !== prevState.pageSize ||
      items.length !== prevProps.items.length
    ) {
      this.resetPage()
      const paginatedItems = buildPagination(items, pageSize)
      this.setState({
        paginatedItems,
        totalPages: paginatedItems.length ? paginatedItems.length - 1 : 1,
      })
    }
  }

  handleCurrentPage = e => {
    this.setState({ currentPage: e.target.value })
  }

  decreasePage = () => {
    this.setState(prevState => ({ currentPage: prevState.currentPage - 1 }))
  }

  increasePage = () => {
    // Increment state count, instead of appending `1` to state
    /* eslint-disable no-plusplus */
    /* eslint-disable no-param-reassign */
    this.setState(prevState => ({ currentPage: ++prevState.currentPage }))
  }

  resetPage = () => {
    this.setState({ currentPage: 1 })
  }

  handlePageSize = value => {
    this.setState({ pageSize: value })
    this.resetPage()
  }

  render() {
    const { currentPage, pageSize, paginatedItems, totalPages } = this.state
    const { enumerables, positionTop, render } = this.props

    return (
      <>
        {!positionTop && render(paginatedItems, currentPage)}
        <div className="d-flex justify-content-between pt-3">
          <div>
            <Button
              className="mr-2"
              color="secondary"
              data-test="decrease-button"
              disabled={currentPage <= 1}
              onClick={this.decreasePage}
            >
              <Icon id="chevron-left" title="Page back" />
            </Button>
            <span className="mr-2">Page</span>
            <div className="d-inline-block w-25 p-1">
              <BaseInput
                onChange={this.handleCurrentPage}
                type="number"
                value={currentPage}
                min="1"
                max={totalPages}
              />
            </div>
            <span className="mx-2">{`of ${totalPages}`}</span>
            <Button
              className="ml-2"
              color="secondary"
              data-test="increase-button"
              disabled={currentPage >= paginatedItems.length - 1}
              onClick={this.increasePage}
            >
              <Icon id="chevron-right" title="Page forward" />
            </Button>
          </div>
          <div className={classNames(component, 'd-flex align-items-center pt-1')}>
            {/* Align label and dropdown to be side by side */}
            <span className="mr-2 mb-1">Showing</span>
            <SelectDropdown
              additionalItems={false}
              appendLabel="per page"
              base
              className="d-inline-block"
              data-test="pagination-select"
              enumerables={enumerables}
              onChange={this.handlePageSize}
              value={pageSize}
            />
          </div>
        </div>
        {positionTop && render(paginatedItems, currentPage)}
      </>
    )
  }
}

export default Pagination
