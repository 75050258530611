export const SHOW_KEYS = 'SHOW_KEYS'

// export const getKeysShown = () => !!JSON.parse(sessionStorage.getItem(SHOW_KEYS))

export default function setupShowKeys() {
  // If the ember showKeys exists, keep it.
  const showKeys = window.showKeys ? window.showKeys.bind(window) : () => {}
  window.showKeys = () => {
    // const keysShown = getKeysShown()
    showKeys()
    const event = new CustomEvent(SHOW_KEYS)
    // event[SHOW_KEYS] = !keysShown
    // sessionStorage.setItem(SHOW_KEYS, !keysShown)
    window.dispatchEvent(event)
  }
}
