import { createElement } from 'react'
import { shape } from 'prop-types'
import classNames from 'classnames'
import compileValidationMessage from './validationHelper'
import Toggle from '../Toggle'
import BaseToggle from '../Toggle/BaseToggle'

const propTypes = {
  schemaNode: shape(),
}

const defaultProps = {
  schemaNode: {},
}

/**
 * ToggleContainer
 * Wrapper for the Toggle/BaseToggle form elements passed into a Formogorgon Form that
 * converts Formogorgon related props to those expected by the plain components.
 */
const ToggleContainer = ({ schemaNode, ...rest }) => {
  const {
    // Props being used here to define the input attributes
    base,
    className,
    disabled,
    dynamicOptions = {},
    guid,
    id,
    invalid,
    label,
    materializedPath,
    readOnly, // Formogorgon flag to render a disabled form
    title,
    validationMessage, // Optional schemaNode override for invalid message from AJV
    /* Form related props that are not used here and don't get passed to the input 🙅 */
    currentMaterializedPath,
    debounced,
    defaultValue,
    dirty,
    editDecoration,
    parentType,
    type,
    uiMenuComponent,
    /* Remaining props to pass straight through to the rendered input component */
    ...propsRest
  } = {
    ...schemaNode,
    // ℹ️ Allow passed props to override the schemaNode values
    ...rest,
  }

  // dynamicOptions is the only way to  pass a prop to SchemaNode that will trigger
  // a component update when the prop changes
  const { validationOverride } = dynamicOptions

  const element = base ? BaseToggle : Toggle
  const derivedClassName = classNames(className, { base })
  const derivedDisabled = disabled || readOnly
  const derivedId = materializedPath || id || guid || ''
  // Get the invalid message based upon the three possible values
  const derivedInvalid = compileValidationMessage({
    invalid,
    validationMessage,
    validationOverride,
  })
  const derivedLabel =
    label || title || (materializedPath && materializedPath.split(',').pop()) || ''

  return createElement(element, {
    className: derivedClassName,
    disabled: derivedDisabled,
    id: derivedId,
    label: derivedLabel,
    invalid: derivedInvalid,
    renderDisabledAsText: true,
    ...propsRest,
  })
}

ToggleContainer.displayName = 'ToggleContainer'
ToggleContainer.defaultProps = defaultProps
ToggleContainer.propTypes = propTypes
export default ToggleContainer
