import React from 'react'
import { bool, node, string } from 'prop-types'
import classNames from 'classnames'
import { Dropdown } from 'componentry'
import Icon from '../Icon'

import { component } from './dropdown.scss'

/**
 * Default LegacyTrigger to `Dropdown.Trigger` if LegacyTrigger does not exist. This combats an
 * issue in Storybook where this code is being executed with each hot reload, causing the reassignment
 * of Dropdown.Trigger to create ever further nested ChevronTriggers. This ensures that the
 * ChevronTrigger only uses componentry's original Trigger (LegacyTrigger), even on multiple
 * compilations
 */
const { LegacyTrigger = Dropdown.Trigger } = Dropdown

// Custom Dropdown Trigger to match Hippo styles
const ChevronTrigger = ({ children, className, showDecoration, ...rest }) => (
  <LegacyTrigger className={classNames(component, className)} {...rest}>
    <div className="d-flex align-items-center">
      {children}

      {showDecoration && <Icon id="chevron-down" className="ml-2" presentation />}
    </div>
  </LegacyTrigger>
)

ChevronTrigger.propTypes = {
  children: node,
  className: string,
  showDecoration: bool,
}
ChevronTrigger.defaultProps = {
  children: null,
  className: '',
  showDecoration: true,
}
ChevronTrigger.displayName = 'ChevronDropdownTrigger'

Dropdown.Trigger = ChevronTrigger
Dropdown.LegacyTrigger = LegacyTrigger

export default Dropdown
