import React from 'react'

import FormContext from '../FormContext/FormContext'
import ContextSchemaNodeContainer from './ContextSchemaNodeContainer'
import ReduxSchemaNodeContainer from './ReduxSchemaNodeContainer'

/**
 * Determines if the SchemaNode data is stored in Redux or in Context (<Form> vs <FormProvider>)
 * - Both form types have context, but they only share `FORM_PROPERTIES`
 * - The context based forms will also contain `valueById`, `formSchema`, etc..
 */
const SchemaNodeConsumer = props => (
  <FormContext.Consumer>
    {({ valueById }) =>
      valueById ? (
        <ContextSchemaNodeContainer {...props} />
      ) : (
        <ReduxSchemaNodeContainer {...props} />
      )
    }
  </FormContext.Consumer>
)

SchemaNodeConsumer.displayName = 'SchemaNodeConsumer'
export default SchemaNodeConsumer
