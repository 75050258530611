import { sesh } from '@/storage'

const HSQ_ROUTE_NAME = 'HSQ_ROUTE_NAME'
const HSQ_ONE_ID = 'HSQ_ONE_ID'

export const getRouteName = () => sesh.getItem(HSQ_ROUTE_NAME)
export const setRouteName = routeName => sesh.setItem(HSQ_ROUTE_NAME, routeName)

/**
 * Set and retrieve the hsq "oneId". For public, this is the string parameters used for login,
 * for authenticated it is "auth". It is the string that renders after the `#/one/` and before any
 * sub-route.
 *
 * @param {String} id
 * @returns
 */
export const setOneId = id => sesh.setItem(HSQ_ONE_ID, id)
export const getOneId = () => sesh.getItem(HSQ_ONE_ID)
