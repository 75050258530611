import React from 'react'
import { arrayOf, bool, oneOfType, string } from 'prop-types'

const ConditionalList = ({ content, useHtml, ...rest }) => {
  const renderContent = item => {
    if (useHtml) {
      return (
        <span
          data-test="conditional-list-html"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: item }}
        />
      )
    }
    return item
  }

  if (!Array.isArray(content)) return renderContent(content)
  if (content.length === 1) return renderContent(content[0])
  return (
    <ul data-test="conditional-list" {...rest}>
      {content.map(item => (
        <li data-test="conditional-list-item" key={item}>
          {renderContent(item)}
        </li>
      ))}
    </ul>
  )
}

ConditionalList.propTypes = {
  content: oneOfType([string, arrayOf(string)]),
  useHtml: bool,
}

ConditionalList.defaultProps = {
  useHtml: false,
  content: null,
}

export default ConditionalList
