import React from 'react'
import {
  arrayOf,
  bool,
  func,
  node,
  number,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types'
import classNames from 'classnames'
import nanoid from 'nanoid'

import { SIZE_OPTIONS } from '../../../utils/constants'
import { component } from './radio.scss'

const propTypes = {
  ariaDescribedBy: string,
  className: string,
  'data-test': string,
  disabled: bool,
  enumerables: arrayOf(
    oneOfType([
      string,
      number,
      shape({
        label: oneOfType([string, number, node]).isRequired,
        description: oneOfType([string, number]),
        disabled: bool,
        value: oneOfType([string, number]),
      }),
    ]),
  ).isRequired,
  enumerableDescriptions: arrayOf(string),
  id: string,
  inputClasses: string,
  invalid: oneOfType([string, arrayOf(string)]),
  isRequired: bool,
  onChange: func.isRequired,
  size: oneOf(SIZE_OPTIONS),
  value: oneOfType([string, number]).isRequired,
}

const defaultProps = {
  ariaDescribedBy: '',
  className: '',
  'data-test': undefined,
  disabled: false,
  enumerableDescriptions: null,
  id: '',
  inputClasses: undefined,
  invalid: null,
  isRequired: false,
  size: 'md',
}

// In testing use the same guid always so that snapshots are consistent
const localGuid = () => (process.env.NODE_ENV === 'test' ? 'test-id' : nanoid())

const BaseRadio = props => {
  const {
    ariaDescribedBy,
    className,
    disabled,
    enumerables,
    enumerableDescriptions,
    id,
    inputClasses,
    invalid,
    size,
    value,
    // Props that are not currently used but should not be passed to the input 🙅
    isRequired,
    ...rest
  } = props
  const { 'data-test': dataTest } = props
  const guid = id || localGuid()

  return (
    <div
      className={classNames(
        component,
        className,
        `form-control-${size}`,
        'input-radio-component w-100',
        {
          'is-invalid': invalid && invalid.length,
        },
      )}
      aria-describedby={ariaDescribedBy}
    >
      {enumerables.map((option, idx) => {
        const enumId = `${guid}${idx}`
        const radioValue =
          typeof option === 'object' && option !== null ? option.value : option
        const label = typeof option === 'object' ? option.label : option
        const description =
          option.description ||
          (enumerableDescriptions && enumerableDescriptions[idx])
        // if the prop `disabled` is true, every radio should be disabled
        // otherwise, it is controlled by a property on the option
        const radioDisabled = disabled || option.disabled

        return (
          <div key={radioValue} className="mb-1">
            <div className="custom-control custom-radio">
              <input
                type="radio"
                checked={value === radioValue}
                className={classNames('custom-control-input', inputClasses)}
                disabled={radioDisabled}
                id={enumId}
                name={guid}
                value={radioValue}
                {...rest}
              />
              <label
                data-test={dataTest ? `${dataTest}-label` : undefined}
                className="custom-control-label"
                htmlFor={enumId}
              >
                {label}
              </label>
            </div>
            {description && (
              <div className="custom-control-description option-description">
                {description}
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

BaseRadio.displayName = 'BaseRadio'
BaseRadio.propTypes = propTypes
BaseRadio.defaultProps = defaultProps
export default BaseRadio
