import React from 'react'
import { shape } from 'prop-types'

import renderChildrenNodes from '../utils/render-children-nodes'

const propTypes = {
  schemaNode: shape().isRequired,
}

const FormObject = ({ schemaNode, ...rest }) => {
  const { className, title, description } = schemaNode

  return (
    <div className={className}>
      {title && <h3>{title}</h3>}
      {description && <p>{description}</p>}
      {renderChildrenNodes({ schemaNode, ...rest })}
    </div>
  )
}

FormObject.propTypes = propTypes
FormObject.displayName = 'FormObject'
export default FormObject
