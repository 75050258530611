import { connect } from 'react-redux'
import {
  addArrayItem as actionsAddArrayItem,
  removeArrayItem as actionsRemoveArrayItem,
  updateFormData as updateFormDataAction,
  selectors as formSelectors,
} from '../dux/form'

import SchemaNode from './SchemaNode'

const makeMapStateToProps = () => {
  const getSchemaNodeProps = formSelectors.makeGetSchemaNodeProps()
  const getValuesForNode = formSelectors.makeGetValuesForNode()

  const mapStateToProps = (store, ownProps) => {
    const { materializedPath } = ownProps
    const formId = materializedPath.split(',')[0]

    let nodeValues
    let schemaNodeProps
    if (store[formId]) {
      nodeValues = getValuesForNode(store[formId], materializedPath)
      schemaNodeProps = getSchemaNodeProps(store[formId], ownProps)
      // NOTE: Using reselect on getSchemaNodeProps is not enough to memoize it properly
      // because it's returning a new object each time. To get around constant rerendering,
      // shouldComponentUpdate is doing manual comparisons in the component above.
    }

    return {
      schemaNode: schemaNodeProps,
      // The `nodeValues` are: { defaultValue, dirty, invalid, value }
      ...nodeValues,
      // In case a form element needs to know if it is context or redux based
      connected: true,
    }
  }

  return mapStateToProps
}

const mapDispatchToProps = {
  updateFormData: updateFormDataAction,
  addArrayItem: actionsAddArrayItem,
  removeArrayItem: actionsRemoveArrayItem,
}

export default connect(makeMapStateToProps, mapDispatchToProps)(SchemaNode)
