import React, { Component } from 'react'
import { func, shape, string } from 'prop-types'

import renderChildrenNodes from '../utils/render-children-nodes'

export default class FormArray extends Component {
  static propTypes = {
    addArrayItem: func.isRequired,
    materializedPath: string.isRequired,
    removeArrayItem: func.isRequired,
    schemaNode: shape().isRequired,
  }

  // Methods
  // ---------------------------------------------------------------------------

  addItem = evt => {
    const { addArrayItem, materializedPath } = this.props
    evt.preventDefault()
    addArrayItem(materializedPath)
  }

  removeItem = evt => {
    const { removeArrayItem } = this.props
    evt.preventDefault()
    removeArrayItem(evt.target.dataset.materializedPath)
  }

  // Render
  // ---------------------------------------------------------------------------

  render() {
    const { schemaNode, materializedPath } = this.props
    const { className, items, title, description } = schemaNode

    // Don't show the add or remove button for arrays using tuple validation, only
    // for arrays that use list validation (See README for tuple details)
    const listArray = !Array.isArray(items)

    return (
      <div className={className}>
        {title && <h3>{title}</h3>}
        {description && <p>{description}</p>}
        <div>
          {renderChildrenNodes({ schemaNode }).map((arrayChild, idx) => (
            /* eslint-disable react/no-array-index-key */
            <div className="d-flex" key={`${materializedPath},${idx}`}>
              <div className="form-array-input-container">{arrayChild}</div>

              {listArray && (
                <button
                  className="btn btn-anchor text-danger ml-3"
                  type="button"
                  data-materialized-path={`${materializedPath},${idx}`}
                  onClick={this.removeItem}
                >
                  <svg className="icon close-circle font" role="img">
                    <use href="#close-circle" />
                  </svg>{' '}
                  Remove
                </button>
              )}
            </div>
          ))}
        </div>

        {/* Add new array item button */}

        {listArray && (
          <button
            className="btn btn-anchor btn-block text-primary"
            onClick={this.addItem}
            type="button"
          >
            <svg className="icon add-circle font" role="img">
              <use href="#add-circle" />
            </svg>{' '}
            Add
          </button>
        )}
      </div>
    )
  }
}
