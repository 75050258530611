import React from 'react'
import classNames from 'classnames'
import { node, string } from 'prop-types'
import { Modal } from 'componentry'

import { component } from './modal.scss'

/**
 * This modal wrapper component handles new custom sizes. Modals can take sizes
 * sm, md, lg, and xl. Sizes small and large are still accepted for compatibility
 * with Componentry.
 *
 * @param {string} size String from list of accepted modal sizes
 */

const CustomModal = ({ children, size, ...rest }) => {
  if (!size)
    return (
      <span className={component}>
        <Modal {...rest}>{children}</Modal>
      </span>
    )

  const componentrySizeSmall = size === 'small' || size === 'sm' ? 'small' : null
  const componentrySizeLarge = size === 'large' || size === 'lg' ? 'large' : null

  // If the size prop comes in as 'large' or 'small', use the basic Componentry
  // functionality, otherwise use wrapper component with a span for custom size
  return componentrySizeSmall || componentrySizeLarge ? (
    <span className={component}>
      <Modal {...rest} size={componentrySizeSmall || componentrySizeLarge}>
        {children}
      </Modal>
    </span>
  ) : (
    <span className={classNames(component, `size-${size}`)}>
      <Modal {...rest}>{children}</Modal>
    </span>
  )
}

CustomModal.Header = Modal.Header
CustomModal.Body = Modal.Body
CustomModal.Title = Modal.Title
CustomModal.Footer = Modal.Footer

CustomModal.propTypes = {
  children: node,
  size: string,
}

CustomModal.defaultProps = {
  children: null,
  size: '',
}

CustomModal.displayName = 'Modal'

export default CustomModal
