import React, { useEffect, useRef } from 'react'
import { bool, func, node, oneOfType, string } from 'prop-types'
import classNames from 'classnames'

import debounce from '../../../utils/debounce'

import { component } from './spotlight-modal-v2.scss'
import Dialog from '../../Dialog'

const SpotlightV2 = ({
  active,
  children,
  className,
  deactivate,
  modalContent,
  ...rest
}) => {
  const spotlightRef = useRef()
  /**
   * @NOTE cannot rely on the `Dialog`s `.focus` event to scroll the spotlight into view because
   * Firefox does the bare minimum to scroll on focus.
   */
  useEffect(() => {
    if (active) {
      const { current: spotlight } = spotlightRef
      // Debounce and onScroll to Combat a bug where if user is already scrolling, then
      // `scrollIntoView` does nothing due to the queued up scroll events.
      const scrollIntoView = debounce(
        () => spotlight.scrollIntoView({ block: 'center' }),
        400,
      )
      scrollIntoView()
      window.addEventListener('scroll', scrollIntoView)
      return () => window.removeEventListener('scroll', scrollIntoView)
    }
    return () => {}
  }, [active])

  // `Dialog` should prevent the elements that use this from ever having focus, but can't hurt and
  // it appeases the eslint gods.
  const handleKeyPress = ({ key }) => {
    if (key.toLowerCase() === 'enter') {
      deactivate()
    }
  }

  return (
    <span
      className={classNames(`${className} ${component} mobile-sizing`)}
      {...rest}
    >
      <div
        ref={spotlightRef}
        className={classNames('position-relative', 'd-inline-block mobile-sizing', {
          'z-index-modal': active,
        })}
      >
        <Dialog
          className="spotlight-modal position-absolute bg-white p-4 shadow-4dp"
          active={active}
          data-test="spotlight-dialog"
        >
          {modalContent}
        </Dialog>
        <div className="d-inline-block position-relative mobile-sizing">
          <div className="triangle"></div>
          {children}

          {/* eslint-disable jsx-a11y/no-static-element-interactions */}
          {/* Position absolute element to prevent clicking of the spotlit element until the spotlight is dismissed */}
          {active && (
            <div
              className="position-absolute center-y center-x w-100 h-100 cursor-pointer"
              data-test="spotlight-cover"
              onClick={deactivate}
              onKeyPress={handleKeyPress}
            />
          )}
        </div>
      </div>
      {/* Scrim bb */}
      {active && (
        <div
          className="modal-backdrop"
          data-test="spotlight-scrim"
          onClick={deactivate}
          onKeyPress={handleKeyPress}
        />
      )}
      {/* eslint-enable jsx-a11y/no-static-element-interactions */}
    </span>
  )
}

SpotlightV2.propTypes = {
  active: bool.isRequired,
  className: string,
  deactivate: func.isRequired,
  modalContent: oneOfType([node, string]).isRequired,
  children: oneOfType([node, string]).isRequired,
}

SpotlightV2.defaultProps = {
  className: '',
}

SpotlightV2.displayName = 'SpotlightV2'

export default SpotlightV2
