import React from 'react'
import { arrayOf, bool, func, shape } from 'prop-types'
import classNames from 'classnames'

import { Checkbox, Dropdown, Icon, Anchor } from '../../..'

import { component } from './MultiSelectOptions.scss'

const MultiSelectOptions = ({ actions, closeSidebar, isMobile }) => (
  <span className={component}>
    {actions.map(
      ({
        label,
        onSelect,
        selected,
        description,
        disabled = false,
        disabledMessage,
        dataTest,
        url,
      }) => {
        const DropdownItem = url ? Anchor : Dropdown.Item
        const anchorProps = { href: url }
        const buttonProps = {
          onClick: e => {
            if (isMobile) closeSidebar() // In mobile the MultiSelectOptions are rendered inside a drawer
            onSelect?.(e)
          },
          disabled,
          'aria-pressed': selected,
        }
        const props = url ? anchorProps : buttonProps
        return (
          <DropdownItem
            key={label}
            className={classNames('d-flex align-items-start', {
              'align-items-center': selected === undefined && !disabled,
              'justify-content-between': selected === undefined,
              'dropdown-item no-underline': url,
            })}
            data-test={dataTest || 'dropdown-item'}
            {...props}
          >
            {selected === undefined ? (
              <Icon id="chevron-right" className="order-2" presentation />
            ) : (
              <div role="presentation">
                <Checkbox
                  className="mt-1 mr-2"
                  value={selected}
                  onClick={e => e.stopPropagation()}
                  onChange={() => {}}
                  disabled={disabled}
                  tabIndex="-1"
                  base
                />
              </div>
            )}
            <div
              className={classNames('d-flex flex-column', {
                'w-90': disabled && selected === undefined,
              })}
            >
              <div className="disabled-header">{label}</div>
              {((disabled && disabledMessage) || description) && (
                <div className="text-wrap small">
                  {disabled && disabledMessage ? disabledMessage : description}
                </div>
              )}
            </div>
          </DropdownItem>
        )
      },
    )}
  </span>
)

MultiSelectOptions.propTypes = {
  actions: arrayOf(shape()).isRequired,
  closeSidebar: func.isRequired,
  isMobile: bool,
}
MultiSelectOptions.defaultProps = {
  isMobile: false,
}
MultiSelectOptions.displayName = 'MultiSelectOptions'

export default MultiSelectOptions
