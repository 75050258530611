import React from 'react'
import { bool, string } from 'prop-types'
import classNames from 'classnames'

import { component } from './loading-indicator.scss'

const propTypes = {
  className: string,
  inline: bool,
  'data-test': string,
}

const defaultProps = {
  className: '',
  inline: false,
  'data-test': 'loading',
}

const LoadingIndicator = ({ className, inline, 'data-test': dataTest }) => (
  <span
    className={classNames(component, className, 'loader-wrapper loading', {
      'large-loader full-screen-loader-wrapper': !inline,
    })}
    data-test={dataTest}
  >
    <span className="loader">
      <span />
      <span />
      <span />
    </span>
  </span>
)

LoadingIndicator.propTypes = propTypes
LoadingIndicator.defaultProps = defaultProps
LoadingIndicator.displayName = 'LoadingIndicator'
export default LoadingIndicator
