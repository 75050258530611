import React, { createElement } from 'react'
import { bool, node, string } from 'prop-types'
import classNames from 'classnames'
import { FormHelpText, RequiredFlag } from '../../utilComponents'

const WrappingElement = ({ inputId, ...rest }) =>
  createElement(inputId ? 'div' : 'fieldset', rest)

const FormGroup = ({
  children,
  className,
  description,
  inputId,
  invalid,
  isRequired,
  label,
  labelId,
  size,
  type,
  warning,
  isAriaLabel,
}) => {
  const computedLabel = label || ''
  return (
    <WrappingElement
      className={classNames(
        'form-group',
        `input-${type}-component`,
        `form-control-${size}`,
        className,
      )}
      inputId={inputId}
    >
      <div
        className={classNames(
          'd-flex justify-content-between align-items-baseline',
          { 'mb-1': computedLabel },
        )}
      >
        {inputId ? (
          <label
            id={labelId}
            htmlFor={inputId}
            className={classNames({ 'sr-only': isAriaLabel })}
          >
            {computedLabel}
          </label>
        ) : (
          <legend
            id={labelId}
            data-test="label"
            className={classNames({ 'sr-only': isAriaLabel })}
          >
            {computedLabel}
          </legend>
        )}

        {isRequired && <RequiredFlag />}
      </div>
      {children}
      <div className="d-flex justify-content-between">
        <FormHelpText
          description={description}
          invalid={invalid}
          warning={warning}
        />
      </div>
    </WrappingElement>
  )
}

WrappingElement.propTypes = {
  inputId: string.isRequired,
}

FormGroup.propTypes = {
  children: node.isRequired,
  className: string,
  description: string,
  inputId: string,
  invalid: string,
  isRequired: bool,
  label: string,
  labelId: string,
  size: string,
  type: string,
  warning: string,
  isAriaLabel: bool,
}

FormGroup.defaultProps = {
  className: '',
  description: '',
  inputId: '',
  invalid: '',
  isRequired: false,
  label: '',
  labelId: undefined,
  size: 'md',
  type: 'generic',
  warning: undefined,
  isAriaLabel: false,
}

FormGroup.displayName = 'FormGroup'
WrappingElement.displayName = 'FormGroupWrapper'

export default FormGroup
