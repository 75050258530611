import React, { useState, useEffect } from 'react'
import { arrayOf, func, shape } from 'prop-types'
import classNames from 'classnames'
import { useDebounce } from 'use-debounce'
import safeLoc from '../../utils/safeLoc'

import FilterBar from '../FilterBar'

const FilterGroup = ({ services, children }) => {
  const [userInput, setUserInput] = useState('')
  const [debouncedInput] = useDebounce(userInput, 100) // TODO: why can this use the debounced prop that is part of the <Input />
  const [filteredServices, setFilteredServices] = useState(services)
  const isSearchable = services && services.length >= 10

  useEffect(() => {
    if (!isSearchable) return
    if (!userInput) {
      setFilteredServices(services)
    } else {
      setFilteredServices(
        services.filter(({ name }) =>
          name.toLowerCase().includes(userInput.toLowerCase()),
        ),
      )
    }
  }, [debouncedInput, services])

  const displayedServices = isSearchable ? filteredServices : services

  return (
    <>
      <div
        className={classNames({
          'border-bottom pb-3': isSearchable,
        })}
      >
        {isSearchable && (
          <FilterBar
            value={userInput}
            label={safeLoc('FILTERS_filterableFiltersLabel', {
              backupText: 'Filter by name',
            })}
            aria-label={safeLoc('FILTERS_filterableFiltersLabel', {
              backupText: 'Filter by name',
            })}
            className={classNames('mt-0 px-4', {
              'mb-3': !userInput,
            })}
            onChange={value => {
              setUserInput(value)
            }}
            description={
              userInput
                ? safeLoc('GLO_blankOfBlank', {
                    params: [displayedServices.length, services.length],
                    backupText: `${displayedServices.length} of ${services.length}`,
                  })
                : null
            }
            iconSize="small"
            size="sm"
          />
        )}
      </div>
      {children(displayedServices, isSearchable)}
    </>
  )
}

FilterGroup.propTypes = {
  services: arrayOf(shape()).isRequired,
  children: func.isRequired,
}

export default FilterGroup
