import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { bool, oneOf, string } from 'prop-types'
import { component } from './anchor.scss'

const Anchor = ({
  children, // eslint-disable-line
  className,
  color,
  href,
  link,
  outline,
  size,
  target,
  ...rest
}) => {
  const anchor = useRef()
  const [computedTarget, setTarget] = useState(target)
  useEffect(() => {
    if (target) {
      if (target !== computedTarget) setTarget(target)
      return
    }
    setTarget(anchor.current.origin === window.location.origin ? '_self' : '_blank')
  }, [anchor.current, href, target])

  if (link && (outline || size || color) && process.env.NODE_ENV !== 'test') {
    console.warn('<Anchor/> will not render like a `.btn` while `link` is `true`')
  }
  const buttonClassNames = link
    ? ''
    : classNames('btn', {
        [`btn-${color}`]: color && !outline,
        [`btn-outline-${color}`]: color && outline,
        'btn-sm': size === 'small',
        'btn-lg': size === 'large',
      })
  return (
    <a
      className={classNames(component, buttonClassNames, className)}
      href={href}
      ref={anchor}
      target={computedTarget}
      rel={computedTarget === '_blank' ? 'noreferrer noopener' : undefined}
      {...rest}
    >
      {children}
    </a>
  )
}

Anchor.propTypes = {
  className: string,
  color: string,
  href: string.isRequired,
  link: bool,
  outline: bool,
  size: oneOf(['small', 'large', '']),
  target: oneOf(['_self', '_blank']),
}

Anchor.defaultProps = {
  className: '',
  color: '',
  link: true,
  outline: false,
  size: null,
  target: undefined,
}

Anchor.displayName = 'Anchor'

export default Anchor
