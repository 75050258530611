/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react'
import {
  arrayOf,
  bool,
  element,
  func,
  node,
  number,
  oneOf,
  oneOfType,
  string,
} from 'prop-types'
import nanoid from 'nanoid'
import classNames from 'classnames'

import BaseInput from './BaseInput'
import { FormHelpText, RequiredFlag } from '../../utilComponents'
import { SIZE_OPTIONS } from '../../../utils/constants'

import { component } from './input.scss'

export default class Input extends Component {
  static displayName = 'Input'

  static propTypes = {
    alwaysDisplayLabel: bool,
    base: bool,
    className: string,
    debounced: bool,
    decoration: oneOfType([element, arrayOf(node)]),
    disabled: bool,
    description: string,
    id: string,
    inputType: oneOf(['textarea', 'input']),
    invalid: oneOfType([string, arrayOf(string)]),
    isRequired: bool,
    label: string,
    maxLength: number,
    suppressMaxLengthDisplay: bool,
    onChange: func.isRequired,
    placeholder: string,
    size: oneOf(SIZE_OPTIONS),
    type: string,
    // `value` is not required so because a string may be `null` (A value initialized
    // as `null` is replaced by an empty string in the input component below)
    value: oneOfType([string, number, arrayOf(string)]),
  }

  static defaultProps = {
    alwaysDisplayLabel: true,
    base: false,
    className: '',
    debounced: false,
    decoration: null,
    disabled: false,
    description: '',
    id: '',
    inputType: 'input',
    invalid: null,
    isRequired: false,
    label: '',
    maxLength: null,
    placeholder: '',
    size: 'md',
    suppressMaxLengthDisplay: false,
    type: 'text',
    value: '',
  }

  state = {
    focused: false,
  }

  // In testing use the same guid always so that snapshots are consistent
  localGuid = () => (process.env.NODE_ENV === 'test' ? 'test-id' : nanoid())

  focus = () => {
    this.setState({ focused: true })
  }

  blur = () => {
    this.setState({ focused: false })
  }

  renderCharacterCount = () => {
    const { maxLength, value, suppressMaxLengthDisplay } = this.props
    if (!maxLength || typeof value !== 'string' || suppressMaxLengthDisplay) {
      return null
    }
    return (
      <span className="character-count text-muted">
        {value.length}/{maxLength}
      </span>
    )
  }

  render() {
    const {
      alwaysDisplayLabel,
      base,
      className,
      description,
      id,
      invalid,
      isRequired,
      label,
      placeholder,
      size,
      value,
      suppressMaxLengthDisplay,
      ...rest
    } = this.props
    const { focused } = this.state

    const computedLabel = label || ''
    const computedPlaceholder =
      placeholder || (alwaysDisplayLabel && !base ? '' : label) // It's weird, but I promise it makes sense...
    const guid = id || this.localGuid()

    if (base) {
      return (
        <BaseInput
          id={guid}
          invalid={invalid}
          placeholder={computedPlaceholder}
          size={size}
          value={value}
          {...rest}
        />
      )
    }

    return (
      <>
        <div
          className={classNames(
            component,
            className,
            // TODO: remove "input-text" class after making absolutely sure it's not being used in hsq1
            'input-text-component input-text form-group',
            `form-control-${size}`,
          )}
        >
          <div
            className={classNames(
              'd-flex justify-content-between align-items-baseline',
              { 'mb-1': computedLabel },
            )}
          >
            <label htmlFor={guid} className="input-text-label" data-test="label">
              {computedLabel}
            </label>
            {isRequired && <RequiredFlag />}
          </div>

          <BaseInput
            focused={focused}
            id={guid}
            invalid={invalid}
            onBlur={this.blur}
            onFocus={this.focus}
            placeholder={computedPlaceholder}
            size={size}
            aria-describedby={invalid || description ? `${guid}-helper` : ''}
            value={value}
            {...rest}
          />
          <div className="d-flex justify-content-between">
            <FormHelpText
              description={description}
              invalid={invalid}
              id={`${guid}-helper`}
            />
            {this.renderCharacterCount()}
          </div>
        </div>
      </>
    )
  }
}
