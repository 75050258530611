/* eslint-disable consistent-return */

/**
 * Function to determine if the prop exists before checking against the custom prop type
 */
function isRequired(...args) {
  const [propTypeCallback, ...rest] = args
  const [props, propName, componentName] = rest
  const propValue = props[propName]
  if (propValue === null || propValue === undefined) {
    return new Error(
      `Failed prop type: The prop \`${propName}\` is marked as required in \`${componentName}\`, but its value is \`${propValue}\`.`,
    )
  }
  return propTypeCallback(...rest)
}

/**
 * Generator method to include `isRequired` on all prop types
 * @param {*} callback
 */
const generatePropType = callback => {
  const propTypeCallback = (...args) => callback.call(null, ...args)
  propTypeCallback.isRequired = isRequired.bind(null, callback)
  return propTypeCallback
}

/**
 * Custom prop type to determine if all elements of an array are STRINGS
 * and that the array is a given length
 */
const stringArrayChecker = (expectedLength, props, propName, componentName) => {
  const propValue = props[propName]
  const arrayPropLength = propValue && propValue.length

  if (
    !Array.isArray(propValue) ||
    arrayPropLength !== expectedLength ||
    propValue.some(item => typeof item !== 'string')
  ) {
    return new Error(
      `Invalid array ${arrayPropLength} (expected ${expectedLength}) strings for prop ${propName} supplied to ${componentName}. Validation failed.`,
    )
  }
}

const arrayOfStringsWithLength = expectedLength =>
  generatePropType(stringArrayChecker.bind(null, expectedLength))

/**
 * Custom prop type to determine if all elements of an array are NUMBERS
 * and that the array is a given length
 */
const numberArrayChecker = (expectedLength, props, propName, componentName) => {
  const propValue = props[propName]
  const arrayPropLength = propValue && propValue.length

  if (
    !Array.isArray(propValue) ||
    arrayPropLength !== expectedLength ||
    propValue.some(item => typeof item !== 'number')
  ) {
    return new Error(
      `Invalid array ${arrayPropLength} (expected ${expectedLength}) numbers for prop ${propName} supplied to ${componentName}. Validation failed.`,
    )
  }
}
const arrayOfNumbersWithLength = expectedLength =>
  generatePropType(numberArrayChecker.bind(null, expectedLength))

export { arrayOfStringsWithLength, arrayOfNumbersWithLength }
