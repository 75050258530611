export const COLOR_OPTIONS = [
  'danger',
  'dark',
  'light',
  'muted',
  'primary',
  'secondary',
  'success',
  'warning',
  'white',
  'transparent',
]

export const SIZE_OPTIONS = ['xs', 'sm', 'md', 'md-shout', 'lg', 'xl']
export const SIZE_OPTIONS_OLD = ['small', 'medium', 'large']
