import React from 'react'
import { arrayOf, bool, number, oneOfType, string } from 'prop-types'
import TranslationContext from './contexts'

const MagicLoc = ({ fallbackText, locId, params, dangerouslySetInnerHTML }) => (
  <TranslationContext.Consumer>
    {({ getLoc }) => {
      if (!getLoc) {
        console.warn('MagicLoc must be wrapped in a TranslationsProvider, cannot display:', locId)
        return locId
      }
      if (dangerouslySetInnerHTML) {
        /* eslint-disable-next-line react/no-danger */
        return <div dangerouslySetInnerHTML={{ __html: getLoc(locId, params) }} />
      }
      return getLoc(locId, params) || fallbackText
    }}
  </TranslationContext.Consumer>
)

MagicLoc.propTypes = {
  dangerouslySetInnerHTML: bool,
  locId: string.isRequired,
  params: arrayOf(oneOfType([string, number])),
  fallbackText: string,
}
MagicLoc.defaultProps = {
  dangerouslySetInnerHTML: undefined,
  params: [],
  fallbackText: undefined,
}
MagicLoc.displayName = 'MagicLoc'

export default MagicLoc
