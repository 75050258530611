import React, { createElement, Component } from 'react'
import {
  arrayOf,
  bool,
  element,
  func,
  number,
  node,
  oneOf,
  oneOfType,
  string,
} from 'prop-types'
import classNames from 'classnames'
import nanoid from 'nanoid'
import debounce from '../../../utils/debounce'
import { SIZE_OPTIONS } from '../../../utils/constants'

import { component } from './input.scss'

class BaseInput extends Component {
  static displayName = 'BaseInput'

  static propTypes = {
    className: string,
    connected: bool,
    debounced: bool,
    decoration: oneOfType([element, arrayOf(node)]),
    disabled: bool,
    focused: bool,
    id: string,
    inputType: oneOf(['textarea', 'input']),
    invalid: oneOfType([string, arrayOf(string)]),
    isRequired: bool,
    label: string,
    onBlur: func,
    onChange: func,
    onFocus: func,
    placeholder: string,
    size: oneOf(SIZE_OPTIONS),
    type: string,
    // `value` is not required so because a string may be `null` (A value initialized
    // as `null` is replaced by an empty string in the input component below)
    value: oneOfType([string, number, arrayOf(string)]),
  }

  static defaultProps = {
    className: '',
    connected: false,
    debounced: false,
    decoration: null,
    disabled: false,
    focused: false,
    id: '',
    inputType: 'input',
    invalid: null,
    isRequired: false,
    label: '',
    onBlur: null,
    onChange: null,
    onFocus: null,
    placeholder: '',
    size: 'md',
    type: 'text',
    value: '',
  }

  state = {
    localValue: this.props.value, // eslint-disable-line react/destructuring-assignment
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillMount() {
    const { value, onChange } = this.props
    this.setLocalValue(value)
    // Debouncing the onChange handler so it will only be updated after a user stops
    // typing for 250 ms
    this.changed = debounce(onChange, 250)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { localValue } = this.state
    // Make absolutely sure the passed in filter value and the local state value stay in sync
    if (localValue !== nextProps.value) {
      this.setLocalValue(nextProps.value)
    }
  }

  setLocalValue = localValue => {
    if (typeof localValue === 'number') {
      // Making sure the `localValue` is always a string so that the logic to determine
      // the `inputValue` to render is cleaner (Fixes a bug related to 0 being falsey)
      this.setState({ localValue: localValue.toString() })
    } else {
      this.setState({ localValue })
    }
  }

  localOnChange = e => {
    const { debounced, onChange } = this.props
    e.persist()
    this.setLocalValue(e.target.value)

    if (debounced) {
      this.changed(e)
    } else {
      onChange(e)
    }
  }

  localGuid = () => (process.env.NODE_ENV === 'test' ? 'test-id' : nanoid())

  render() {
    const {
      className,
      debounced,
      decoration,
      disabled,
      focused,
      id,
      inputType,
      invalid,
      label,
      onChange,
      placeholder,
      size,
      type,
      value,
      // Props that are not currently used but should not be passed to the input 🙅
      connected,
      isRequired,
      ...rest
    } = this.props
    const { localValue } = this.state

    // Use localValue if debounced & fall back to empty strings to handle nulls (but
    // make sure not to fall back to an emptry string if a number input value is 0)
    const numSafeCheck = val => {
      if (val === 0) return val
      return val || ''
    }
    const inputValue = debounced ? numSafeCheck(localValue) : numSafeCheck(value)
    const computedPlaceholder = placeholder || label

    const guid = id || this.localGuid()

    return (
      <>
        <div
          className={classNames(
            component,
            // TODO: remove "input-text" class after making absolutely sure it's not being used in hsq1
            'input-text-component input-text d-flex align-items-center',
            `form-control-${size}`,
          )}
        >
          {createElement(inputType, {
            className: classNames(className, 'input-text-field form-control', {
              'is-invalid': invalid && invalid.length, // Bootstrap color
              'icon-padding': !!decoration, // Padding for icon decorations
              focused,
            }),
            'data-test': 'input',
            disabled,
            id: guid,
            onChange: this.localOnChange,
            // NOTE: This is required to remove the placeholder & convert it to a label when the input is focused
            placeholder: focused ? undefined : computedPlaceholder,
            rows: inputType === 'textarea' ? '2' : undefined,
            type,
            value: inputValue,
            title: disabled ? inputValue : undefined,
            ...rest, // Pass along remaining input attributes (minLength, onFocus, onBlur, type, etc...)
          })}
          {/* This enables displaying icons in the input with some CSS magiks */}
          <span
            className={classNames('input-decoration d-flex align-items-center', {
              'text-danger': invalid && !!invalid.length,
            })}
          >
            {decoration}
          </span>
        </div>
      </>
    )
  }
}

BaseInput.displayName = 'BaseInput'
export default BaseInput
