import React, { useEffect, useRef } from 'react'
import { arrayOf, bool, func, oneOf, oneOfType, string } from 'prop-types'
import nanoid from 'nanoid'
import classNames from 'classnames'

import { FormHelpText, RequiredFlag } from '../../utilComponents'
import { SIZE_OPTIONS } from '../../../utils/constants'
import { component } from './checkbox.scss'

const propTypes = {
  ariaLabel: string,
  base: bool,
  className: string,
  'data-test': string,
  description: string,
  disabled: bool,
  id: string,
  inputClasses: string,
  invalid: oneOfType([string, arrayOf(string)]),
  isRequired: bool,
  legend: string,
  label: string,
  onChange: func.isRequired,
  size: oneOf(SIZE_OPTIONS),
  value: bool,
}

const defaultProps = {
  ariaLabel: '',
  base: false,
  className: '',
  'data-test': undefined,
  description: '',
  disabled: false,
  id: '',
  inputClasses: undefined,
  invalid: null,
  isRequired: false,
  legend: '',
  label: '',
  size: 'md',
  value: null,
}

// In testing use the same guid always so that snapshots are consistent
const localGuid = () => (process.env.NODE_ENV === 'test' ? 'test-id' : nanoid())

/**
 * Checkbox
 * The interactive & visible parts of the checkbox consist of a few layers:
 *  1. A fake custom-control-label to duplicate the placement of the ::before square BEHIND the custom check
 *  2. A custom css checkmark rendered between the real & fake custom-control-label boxes
 *  3. The real custom-control-label with a translarent background so you can click on it appropriately
 *      but still see the custom check & white background behind it.
 *
 * This may seem complicated, but it avoids the need to use images for the checkmark, which is
 * necessary because these checkmarks use branded colors. Thus they need to be styled with css.
 */
const Checkbox = props => {
  const {
    ariaLabel,
    base,
    className,
    description,
    inputClasses,
    id,
    invalid,
    legend,
    label,
    size,
    value,
    // Props that are not currently used but should not be passed to the input 🙅
    isRequired,
    'data-test': dataTest,
    ...rest
  } = props
  const checkRef = useRef()

  useEffect(() => {
    checkRef.current.indeterminate = typeof value !== 'boolean'
  }, [value])

  const guid = id || localGuid()

  return (
    <div
      className={classNames(
        component,
        className,
        'input-checkbox-component',
        `form-control-${size}`,
        {
          'form-group w-100': !base,
          'vanilla-component': base,
          'is-invalid': invalid && invalid.length, // Bootstrap color
        },
      )}
    >
      {!base && (legend || isRequired) && (
        <div className="d-flex justify-content-between align-items-baseline mb-1">
          <legend className="col-form-label checkbox-label p-0" data-test="legend">
            {legend}
          </legend>
          {isRequired && <RequiredFlag />}
        </div>
      )}

      <div className={classNames('custom-control', { 'mt-1': !base })}>
        <input
          aria-describedby={invalid || description ? `${guid}-helper` : undefined}
          aria-label={ariaLabel || undefined}
          checked={value}
          className={classNames('custom-control-input', inputClasses)}
          id={guid}
          ref={checkRef}
          type="checkbox"
          {...rest}
        />
        <div className="custom-control-label checkbox-background" />
        <div className={classNames('checkbox-check')} />
        <div className={classNames('checkbox-indeterminate')} />
        <label
          className="custom-control-label"
          data-test={dataTest || 'label'}
          htmlFor={guid}
        >
          {!base && label}
        </label>
      </div>
      {!base && (
        <FormHelpText
          className="mt-2"
          description={description}
          id={`${guid}-helper`}
          invalid={invalid}
        />
      )}
    </div>
  )
}

Checkbox.displayName = 'Checkbox'
Checkbox.propTypes = propTypes
Checkbox.defaultProps = defaultProps
export default Checkbox
