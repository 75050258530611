import React, { Fragment } from 'react'
import { arrayOf, bool, func, oneOfType, string } from 'prop-types'
import classNames from 'classnames'
import nanoid from 'nanoid'

import { arrayOfStringsWithLength } from '../../../utils/customPropTypes'

import { component } from './slider-toggle.scss'

// TODO: ...this was really rushed
// - this component needs a lot of clean-up/additions to get it to
// parity with other form elements
// - Look into making this a toggle instead of a radio, since UX wants toggle behavior

const propTypes = {
  ariaLabel: string,
  className: string,
  color: string,
  disabled: bool,
  enumerables: arrayOfStringsWithLength(2).isRequired,
  id: string,
  invalid: oneOfType([string, arrayOf(string)]),
  isRequired: bool,
  onChange: func.isRequired,
  value: string.isRequired,
}

const defaultProps = {
  ariaLabel: '',
  className: '',
  color: 'primary',
  disabled: false,
  id: '',
  invalid: null,
  isRequired: false,
}

// In testing use the same guid always so that snapshots are consistent
const localGuid = () => (process.env.NODE_ENV === 'test' ? 'test-id' : nanoid())

/**
 * SliderToggle
 *
 * A controlled form component that is a hybrid between a 2-value radio button and a toggle.
 * Requires EXACTLY 2 `enumerables` options that the toggle will select the value from.
 */
const SliderToggle = props => {
  const {
    ariaLabel,
    className,
    color,
    enumerables,
    id,
    invalid, // TODO: Add this functionality
    onChange,
    value,
    // Props that are not currently used but should not be passed to the input 🙅
    isRequired, // TODO: Add this functionality
    ...rest
  } = props

  const guid = id || localGuid()
  const secondOptionSelected = value === enumerables[1]

  const updateRadio = e => {
    // Simulate the behavior of a toggle, so, no matter which side you click,
    // the selection will flip to the other option.
    // * Note: the standard <input> onChange does this if e.target.value !== value
    if (e.target.value === value) {
      const newValue = enumerables.find(el => el !== value)
      onChange({ target: { value: newValue } })
    }
  }

  const optionColor = color && `bg-${color}`

  return (
    <div className={classNames(component, className)}>
      <div
        className="position-relative d-inline-block slider-toggle"
        aria-label={ariaLabel}
      >
        <div
          className={classNames(
            'active-marker position-absolute btn btn-sm',
            optionColor,
            { right: secondOptionSelected },
          )}
          value={value}
        >
          {value}
        </div>
        <div className="btn-group btn-group-toggle">
          {enumerables.map((option, idx) => {
            const enumId = `${guid}${idx}`
            return (
              <Fragment key={option}>
                <input
                  id={enumId}
                  type="radio"
                  name="view"
                  className="radio-input"
                  onChange={onChange}
                  onClick={updateRadio}
                  value={option}
                  checked={value === option}
                  {...rest}
                />
                <label
                  key={option}
                  className={classNames('btn btn-secondary btn-sm radio-option', {
                    active: value === option,
                  })}
                  htmlFor={enumId}
                >
                  {option}
                </label>
              </Fragment>
            )
          })}
        </div>
      </div>
    </div>
  )
}

SliderToggle.displayName = 'SliderToggle'
SliderToggle.propTypes = propTypes
SliderToggle.defaultProps = defaultProps
export default SliderToggle
