import React from 'react'
import { arrayOf, func, shape, string } from 'prop-types'

import { MultiSelect } from '@hsq/razzle-dazzle'

const propTypes = {
  addArrayItem: func.isRequired,
  value: arrayOf(string).isRequired,
  removeArrayItem: func.isRequired,
  schemaNode: shape(),
}

const defaultProps = {
  schemaNode: {},
}

/**
 * MultiSelectContainer
 * Wrapper for the MultiSelect form elements passed into a Formogorgon Form that
 * converts Formogorgon related props to those expected by the plain components.
 */
const MultiSelectContainer = ({ schemaNode, ...rest }) => {
  const {
    // Special form specific props for MultiSelect (because it handles arrays)
    addArrayItem,
    removeArrayItem,
    value,
    // Props being used here to define the input attributes
    base,
    disabled,
    dynamicOptions = {},
    enumerables,
    guid,
    id,
    invalid,
    items,
    label,
    materializedPath,
    onChange,
    readOnly, // Formogorgon flag to render a disabled form
    title,
    validationMessage, // Optional schemaNode override for invalid message from AJV
    /* Form related props that are not used here and don't get passed to the input 🙅 */
    connected,
    currentMaterializedPath,
    debounced,
    defaultValue,
    dirty,
    editDecoration,
    itemsCount,
    nullable,
    parentType,
    type,
    NodeComponent,
    uiMenuComponent,
    /* Remaining props to pass straight through to the rendered input component */
    ...propsRest
  } = {
    ...schemaNode,
    // ℹ️ Allow passed props to override the schemaNode values
    ...rest,
  }

  // dynamicOptions is the only way to  pass a prop to SchemaNode that will trigger
  // a component update when the prop changes
  const { validationOverride } = dynamicOptions

  // There are a lot of special cases when modifying arrays in Formogorgon
  const derivedOnChange = newValue => {
    // This clause is for the clear all button. When clicked, it passes an empty array
    // which was being handled by the remove clause.
    if (newValue.length === 0) {
      removeArrayItem(materializedPath, { clearArray: true })
      // If the newValue has less items than value, something is being removed
    } else if (newValue.length < value.length) {
      //   // map an array of strings for easy comparison
      //   const stringArray = newValue.map(option => option.label)
      // get the index of the item that is being deleted. If it's in value, but not stringArray.
      const removed = value.findIndex(v => !newValue.includes(v))
      // call removeArrayItem with that index added to materializedPath
      const materializedPathToRemove = `${materializedPath},${removed}`
      removeArrayItem(materializedPathToRemove)
    } else if (newValue.length === value.length) {
      // This case happens when reordering the array elements.
      removeArrayItem(materializedPath, { clearArray: true })
      // Then add them back in the correct order
      addArrayItem(materializedPath, newValue)
    } else {
      // New values should always be at the end. Pass all the new values at the end to addArrayItem
      const newFormValues = newValue.slice(value.length)
      addArrayItem(materializedPath, newFormValues)
    }
  }
  const compileValidationMessage = ({ inv, valmessage, valOverride }) => {
    // Only override the `invalid` message with the `validationMessage` if they both exist
    const formValidation = inv && inv.length && valmessage ? valmessage : inv

    // Override the current invalid message with the `validationOverride` if it exists
    if (valOverride) {
      if (Array.isArray(formValidation)) return [...formValidation, valOverride]
      return formValidation ? [formValidation, valOverride] : valOverride
    }

    return formValidation
  }

  const derivedDisabled = disabled || readOnly
  const derivedId = materializedPath || id || guid || ''
  // Only override the `invalid` message with the `validationMessage` if they both exist
  const derivedInvalid = compileValidationMessage({
    invalid,
    validationMessage,
    validationOverride,
  })
  const derivedLabel =
    label || title || (materializedPath && materializedPath.split(',').pop()) || ''
  const derivedEnum = enumerables || items.enumerables

  return (
    <MultiSelect
      base={base}
      disabled={derivedDisabled}
      enumerables={derivedEnum}
      id={derivedId}
      invalid={derivedInvalid}
      items={items}
      label={derivedLabel}
      onChange={derivedOnChange}
      {...propsRest}
      // `value` must be set after spreading schemaNode (propsRest) because the
      // default value in the schemaNode of an array is it's length
      value={value}
    />
  )
}

MultiSelectContainer.displayName = 'MultiSelectContainer'
MultiSelectContainer.defaultProps = defaultProps
MultiSelectContainer.propTypes = propTypes

export default MultiSelectContainer
