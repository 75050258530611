import React from 'react'
import classNames from 'classnames'
import { node, string } from 'prop-types'

import { component } from './Body.scss'

/**
 * Just a wrapper for the styles we use on sidbar content.
 * @param {Object} props
 */

const SidebarBody = ({ className, children, ...rest }) => (
  <div className={classNames(className, component)} {...rest}>
    {children}
  </div>
)

SidebarBody.propTypes = {
  children: node.isRequired,
  className: string,
}

SidebarBody.defaultProps = {
  className: '',
}

SidebarBody.displayName = 'SidebarBody'

export default SidebarBody
