import React, { Component } from 'react'
import { arrayOf, bool, func, oneOfType, shape, string } from 'prop-types'
import { Button, ListGroup } from 'componentry'
import classNames from 'classnames'
import BaseInput from '../Forms/Input/BaseInput'
import Icon from '../Icon'
import ClickWrapper from '../ClickWrapper'

import { getOptionValue, getFilteredOptions } from './typeahead-helpers'
import { component } from './typeahead-dropdown.scss'

/**
 * <TypeaheadDropdown /> will render a list of options as a dropdown selector with
 * typeahead capability to search through the options.
 *
 * The `options` may be either an array of strings:
 * EXAMPLE: ['List', 'Of', 'Dropdown', 'Options']
 *
 * Or an array of option objects, such as:
 * EXAMPLE:
 *  [
 *    {
 *      key: 'option.1',
 *      title: 'Option Title',
 *      children: [
 *        {
 *          key: 'option.1.child.1',
 *          title: 'First Child of 1',
 *          children: [ ... ],
 *          },
 *          ...
 *      ],
 *      siblings: [
 *        {
 *          key: 'option.1.sibling.1,
 *          title: 'First Sibling of 1',
 *          children: [ ... ],
 *        }
 *      ]
 *    },
 *    ...
 *  ]
 *
 * FUNCTIONALITY NOTES:
 * - The typeahead will do a case insensitive search of the option strings, or of
 *    the key and title if each option if they are objects.
 * - If the the dropdown is closed without making a selection, the text will revert
 *    to the last selected option.
 */
class TypeaheadDropdown extends Component {
  static displayName = 'TypeaheadDropdown'

  // Props
  //----------------------------------------------------------------------------
  static propTypes = {
    autofocus: bool,
    className: string,
    customFilterMethod: func,
    clearInputOnFocus: bool,
    clearButton: bool,
    inputOptions: shape({
      // @TODO this should probably be required since the `input` does not include a `label`
      'aria-label': string,
      placeholder: string,
    }),
    options: oneOfType([
      arrayOf(string),
      arrayOf(
        shape({
          key: string,
          title: string,
          decorationClassName: string,
          children: arrayOf(oneOfType([string, shape()])),
          siblings: arrayOf(shape()),
          configs: oneOfType([
            shape({
              decorationText: string,
              decorationClassName: string,
            }),
            shape({
              icon: string,
              decorationClassName: string,
            }),
          ]),
        }),
      ),
    ]).isRequired,
    onBlur: func,
    onFocus: func,
    selectedOption: oneOfType([
      string,
      shape({
        key: string,
        title: string,
      }),
    ]),
    onSelect: func.isRequired,
  }

  static defaultProps = {
    autofocus: false,
    className: '',
    clearButton: false,
    clearInputOnFocus: false,
    customFilterMethod: null,
    inputOptions: {},
    onBlur: () => {},
    onFocus: () => {},
    selectedOption: '',
  }

  // State
  //----------------------------------------------------------------------------
  state = {
    hasBeenFocused: false,
    filteredOptions: this.props.options,
    localValue: getOptionValue(this.props.selectedOption),
    currentSelection: this.props.selectedOption,
    showDropdown: false,
  }
  // Hooks
  //----------------------------------------------------------------------------

  componentDidUpdate(prevProps) {
    const { options, selectedOption } = this.props
    const prevOptions = prevProps.options
    const currentOption = getOptionValue(selectedOption)
    const prevOption = getOptionValue(prevProps.selectedOption)

    if (currentOption !== prevOption) {
      this.setCurrentSelection(selectedOption)
      this.closeAndResetDropdown(false)
    }

    if (
      options &&
      options.length &&
      JSON.stringify(options) !== JSON.stringify(prevOptions)
    ) {
      this.setFilteredOptions(options)
    }
  }

  // Methods
  //----------------------------------------------------------------------------
  /**
   * Closes the dropdown, resets filter values, and reverts to the previously
   * selected value for the input value (if a search is made without a selection)
   *
   * `resetLocalValue` is configurable so that the dropdown can be closed without
   * forcing a localValue selection (e.g. as it is used for selectOption())
   */
  closeAndResetDropdown = (resetLocalValue = true) => {
    const { currentSelection, showDropdown } = this.state
    const { onBlur, options } = this.props

    if (showDropdown) {
      this.setState({
        showDropdown: false,
        filteredOptions: options,
        hasBeenFocused: true,
      })
      if (resetLocalValue) {
        this.setState({
          localValue: getOptionValue(currentSelection),
        })
      }
      onBlur()
    }
  }

  openDropdown = () => {
    const { onFocus, clearInputOnFocus } = this.props
    if (clearInputOnFocus) this.setState({ localValue: '' })
    this.setState({ showDropdown: true })
    onFocus()
  }

  setFilteredOptions = filteredOptions => {
    this.setState({ filteredOptions })
  }

  setCurrentSelection = option => {
    const value = getOptionValue(option)
    // If the currentSelection is updated, the localValue should also be set to it.
    this.setState({ localValue: value, currentSelection: option })
  }

  selectOption = e => {
    const { onSelect } = this.props
    const { key, stringoption, title, configs } = e.currentTarget.dataset
    const parsedConfigs = configs ? JSON.parse(configs) : null

    // The `stringoption` will only exist for arrayOf(string) dropdowns, or else
    // the dropdown options are objects with a `{ key, title }`
    const selectedOption = stringoption || { key, title, configs: parsedConfigs }
    onSelect(selectedOption) // Update the select callback
    this.setCurrentSelection(selectedOption) // Update localValue & currentSelection
    this.closeAndResetDropdown(false) // Reset the filteredOptions & close the dropdown
  }

  updateInputValue = e => {
    const { options, customFilterMethod } = this.props
    const { value = '' } = e.target
    const filteredOptions = customFilterMethod
      ? customFilterMethod(options, value)
      : getFilteredOptions(options, value)
    this.setFilteredOptions(filteredOptions)
    this.setState({ localValue: value })
  }

  renderDropdownOption = ({ option, isChild = false, isSibling = false }) => {
    if (!option)
      return <h6 className="bg-white text-muted ml-3 my-3">No matching options</h6>

    if (typeof option === 'string') {
      return (
        <ListGroup.Item
          className="rounded-0 text-dark py-2 px-12px"
          key={option}
          data-stringoption={option}
          onClick={this.selectOption}
          data-test="dropdown-item"
        >
          {option}
        </ListGroup.Item>
      )
    }

    const {
      configs = {},
      title,
      key,
      decorationClassName,
      children,
      siblings,
      renderTitleAsOption = true,
      indentChildren = true,
    } = option
    return (
      <div key={key || title}>
        {/* Heading */}
        {/* @TODO if there's a header, this should be a nested ul>li. 
          Maybe expand ConditionalList to accept render props? */}
        {!isChild && !isSibling && children && (
          <h6 className="bg-white h7 bg-white my-2 px-12px">{title || key}</h6>
        )}
        {/* Option */}
        {renderTitleAsOption && (
          <ListGroup.Item
            className={classNames(decorationClassName, 'rounded-0 pl-12px py-2')}
            key={key}
            data-key={key}
            data-title={title}
            data-configs={JSON.stringify(configs)}
            onClick={this.selectOption}
            data-test="dropdown-item"
          >
            {title || key}
            {configs && configs.decorationText && (
              <span className={configs.decorationClassName}>
                {configs.decorationText}
              </span>
            )}
            {configs && configs.icon && (
              <Icon
                className={`ml-2 ${configs.decorationClassName}`}
                id={configs.icon}
                presentation
              />
            )}
          </ListGroup.Item>
        )}
        {/* Sibling options */}
        {siblings &&
          siblings.map(sibling =>
            this.renderDropdownOption({ option: sibling, isSibling: key }),
          )}
        {/* Nested child options */}
        {children && (
          <div className={classNames({ 'pl-12px': indentChildren })}>
            {children.map(child =>
              this.renderDropdownOption({ option: child, isChild: key }),
            )}
          </div>
        )}
      </div>
    )
  }

  render() {
    const { autofocus, className, inputOptions, clearButton } = this.props
    const { filteredOptions, hasBeenFocused, localValue, showDropdown } = this.state

    return (
      <ClickWrapper onClickOutside={this.closeAndResetDropdown}>
        <div
          // Throwing this class in here to make sure this component picks up the hippo spacers/h7 classes
          className={classNames(component, className, 'scoped-hippo-styles')}
          data-test="typeahead-dropdown"
        >
          <BaseInput
            autoComplete="off"
            autoFocus={autofocus && !hasBeenFocused} // Only autofocus once
            className="dropdown-input"
            data-test="typeahead-input"
            placeholder="Type or select"
            value={localValue}
            onChange={this.updateInputValue}
            decoration={
              !!localValue && showDropdown && clearButton ? (
                <Button
                  className="line-height-0 text-body"
                  onClick={this.closeAndResetDropdown}
                  link
                >
                  <Icon id="close-circle-fill" />
                  {/* @TODO how do we loc in razzle?? */}
                  <div className="sr-only">Clear</div>
                </Button>
              ) : (
                <Icon className="text-body" id="chevron-down" presentation />
              )
            }
            onFocus={this.openDropdown}
            debounced
            {...inputOptions}
          />
          <div className="dropdown-options-wrapper" aria-hidden={!showDropdown}>
            <ListGroup className="dropdown-options bg-white w-100 border border-top-0 px-0 pt-1">
              {filteredOptions.length
                ? filteredOptions.map(option =>
                    this.renderDropdownOption({ option }),
                  )
                : this.renderDropdownOption({})}
            </ListGroup>
          </div>
        </div>
      </ClickWrapper>
    )
  }
}

export default TypeaheadDropdown
