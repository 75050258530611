import React, { useRef, useEffect } from 'react'
import { func, string } from 'prop-types'
import { renderCaptcha } from '../../utils'

let idNum = 0

const Captcha = ({ id, onSuccess, onExpired, ...rest }) => {
  const captchaRef = useRef()
  const { lang } = document.documentElement
  useEffect(() => {
    const { current: target } = captchaRef
    renderCaptcha({
      onSuccess,
      onExpired,
      target,
      // `hl` is the language attribute.
      // This used to just happen for free but now it seems busted.
      hl: lang?.toLowerCase() || 'en',
    })
  }, [])

  // eslint-disable-next-line no-plusplus
  return <div id={id || `captcha-${++idNum}`} ref={captchaRef} {...rest} />
}

Captcha.propTypes = {
  id: string,
  onSuccess: func.isRequired,
  onExpired: func,
}

Captcha.defaultProps = {
  id: '',
  onExpired: () => {},
}

export default Captcha
