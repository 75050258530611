import React from 'react'
import {
  arrayOf,
  bool,
  func,
  node,
  number,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types'
import nanoid from 'nanoid'
import classNames from 'classnames'

import { component } from './radio.scss'

import BaseRadio from './BaseRadio'
import { FormHelpText, RequiredFlag } from '../../utilComponents'

import { SIZE_OPTIONS } from '../../../utils/constants'

const propTypes = {
  base: bool,
  className: string,
  description: string,
  disabled: bool,
  enumerables: arrayOf(
    oneOfType([
      string,
      number,
      shape({
        label: oneOfType([string, number, node]).isRequired,
        description: oneOfType([string, number]),
        disabled: bool,
        value: oneOfType([string, number]),
      }),
    ]),
  ).isRequired,
  invalid: oneOfType([string, arrayOf(string)]),
  isRequired: bool,
  label: string,
  onChange: func.isRequired,
  size: oneOf(SIZE_OPTIONS),
  value: oneOfType([string, number]).isRequired,
}

const defaultProps = {
  base: false,
  className: '',
  description: '',
  disabled: false,
  invalid: null,
  isRequired: false,
  label: '',
  size: 'md',
}

const Radio = props => {
  const {
    base,
    className,
    description,
    disabled,
    invalid,
    isRequired,
    label,
    size,
    ...rest
  } = props

  // In testing use the same guid always so that snapshots are consistent
  const localGuid = () => (process.env.NODE_ENV === 'test' ? 'test-id' : nanoid())
  const guid = localGuid()

  if (base)
    return (
      <BaseRadio
        className={classNames(className, `form-control-${size}`)}
        disabled={disabled}
        invalid={invalid}
        {...rest}
      />
    )

  const computedLabel = label || ''

  return (
    <fieldset
      aria-describedby={invalid || description ? `${guid}-helper` : ''}
      className={classNames(
        component,
        `form-control-${size}`,
        'input-radio-component form-group',
      )}
    >
      {label && (
        <div className="d-flex justify-content-between align-items-baseline mb-1">
          <legend
            className={classNames('col-form-label radio-label p-0', { disabled })}
          >
            {computedLabel}
          </legend>
          {isRequired && <RequiredFlag />}
        </div>
      )}
      <div className="custom-controls-stacked">
        <BaseRadio
          className={className}
          disabled={disabled}
          invalid={invalid}
          {...rest}
        />
      </div>
      <div className="mt-2">
        <FormHelpText
          description={description}
          invalid={invalid}
          id={`${guid}-helper`}
        />
      </div>
    </fieldset>
  )
}

Radio.displayName = 'Radio'
Radio.propTypes = propTypes
Radio.defaultProps = defaultProps
export default Radio
