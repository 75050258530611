// export { default } from './SortableContainer'
import React from 'react'

const AAAH = () => {
  // eslint-disable-next-line
  console.log(
    'This package is temporarily unavailable. Please contact your nearest shoulder to cry into.',
  )
  return <span role="presentation" />
}

export default AAAH
