import tabbable from 'tabbable'

export default function tabLock(evt) {
  const { shiftKey, which, target, currentTarget } = evt

  if (which === 9) {
    const allTabbableItems = tabbable(currentTarget)
    const [firstItem] = allTabbableItems
    const lastItem = allTabbableItems[allTabbableItems.length - 1]
    if (shiftKey) {
      if (target === firstItem) {
        evt.preventDefault()
        lastItem.focus()
      }
    } else if (target === lastItem) {
      evt.preventDefault()
      firstItem.focus()
    }
  }
}
