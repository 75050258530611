import React, { useLayoutEffect, useRef, useState } from 'react'
import { arrayOf, bool, func, number, string } from 'prop-types'
import classNames from 'classnames'
import debounce from '../../utils/debounce'
import safeLoc from '../../utils/safeLoc'

const truncateText = (text, step) => {
  // Minimum text length of 10 characters
  const truncationStep = step + 10 >= text.length ? text.length - 10 : step
  return text
    .slice(0, -truncationStep)
    .trim()
    .concat('…')
}

/**
 * Helper to determine if a each of a set of DOM elements extend below the maxHeight
 * for the TruncatableList
 *
 * Will update `itemVisibility` & `overflowCount`
 */
const checkOverflowRefs = ({
  firstItem,
  items,
  itemRefs,
  maxHeight,
  nonList,
  overflowIndicatorRef,
  renderItem,
  setFirstItem,
  setItemVisibility,
  setOverflowCount,
  setOverrideOverflow,
  itemVisibility,
}) => {
  // To avoid triggering too many re-renders, limit the number of alterations to the items
  // (to 30) by adjusting the number of characters removed from the first item at a time.
  const minStep = Math.floor(items[0].length / 30)
  const truncationSteps = minStep < 6 ? 6 : minStep

  const newItemVisibility = itemRefs.map((ref, idx) => {
    // Just show it if we can't do calculations or we're not supposed to truncate
    if (!ref) return ''
    if (!items[idx]) return 'visible' // If for some reason we are passed an empty string, just render it

    // Keep it hidden if the item was previously hidden (if they need reevaluated, first reset itemVisibility and overflowCount)
    if (ref.offsetHeight === 0 && itemVisibility[idx] === 'overflow')
      return 'overflow'
    // Otherwise determine if the bottom of the element is below the maxHeight
    const offsetBottom = ref.offsetTop + ref.offsetHeight - itemRefs[0].offsetTop

    // We never want to show just the overflow indicator, so if the first element
    // is too long on it's own, then we want to try to truncate it manually.
    if (idx === 0) return 'visible'

    return offsetBottom <= maxHeight ? 'visible' : 'overflow'
  })

  if (overflowIndicatorRef.current && itemRefs[0]) {
    // If the overflow indicator is below the max height, hide the last item (this
    // will re-trigger this function because the overflowItemCount will change, thus
    // doing this recursively until the overflow indicator is above the maxHeight)
    const firstOverflowIndex = newItemVisibility.findIndex(el => el === 'overflow')

    const indicatorOffsetBottom =
      overflowIndicatorRef.current.offsetTop +
      overflowIndicatorRef.current.offsetHeight -
      itemRefs[0].offsetTop
    if (indicatorOffsetBottom > maxHeight) {
      if (firstOverflowIndex > 1) {
        newItemVisibility[firstOverflowIndex - 1] = 'overflow'
      } else if (!renderItem) {
        // We never want to fully hide the first element, so we iteratively truncate it (if it's not custom rendered)
        setFirstItem(truncateText(firstItem, truncationSteps))
      }
    }
  }

  // If there is only one item and it is too long, just truncate it but don't have an overflowIndicator
  if (itemRefs[0] && itemRefs.length === 1) {
    const offsetBottom =
      itemRefs[0].offsetTop + itemRefs[0].offsetHeight - itemRefs[0].offsetTop

    if (offsetBottom > maxHeight && !renderItem) {
      setFirstItem(truncateText(firstItem, truncationSteps))
      // If there is only one item and we want to truncate it and provide a link to see
      // the whole thing, set this override flag to true
      if (nonList) setOverrideOverflow(true)
    }
  }

  const visibleItemCount = newItemVisibility.filter(item => item === 'visible')
    .length
  const newOverflowItemCount = items.length - visibleItemCount

  // Handles an edge case that can be caused by resizing the browser where the first item
  // is still truncated and another item is visible after it
  if (firstItem !== items[0] && visibleItemCount > 1) setFirstItem(items[0])

  setItemVisibility(newItemVisibility)
  setOverflowCount(newOverflowItemCount)
}

/**
 * Component to render a list of variable length (default 2 lines) truncated with
 * an action replacing the remaining items
 *
 * @param {Array} items            List elements to render
 * @param {Number} maxHeight       Pixel height at which to start truncating the item list
 * @param {Boolean} nonList        Use if you have a single item & you want to have an overflow indicator for the single truncated string
 * @param {Function} renderItem    Custom render handling for each `item`
 * @param {Function} renderOverflowAction    Custom render handling for overflow indicator
 */
const TruncatableList = ({
  items,
  maxHeight,
  nonList,
  renderItem,
  renderOverflowAction,
}) => {
  const [overflowCount, setOverflowCount] = useState(0)
  const [itemVisibility, setItemVisibility] = useState([])
  const [firstItem, setFirstItem] = useState(items[0])
  const [overrideOverflow, setOverrideOverflow] = useState(false)
  const overflowIndicatorRef = useRef()
  const showMoreContent = renderOverflowAction ? (
    renderOverflowAction(overflowCount)
  ) : (
    <div title={items.slice(-overflowCount)}>
      {safeLoc('GLO_plusMore', {
        params: [overflowCount],
        backupText: `+ ${overflowCount} more`,
      })}
    </div>
  )

  let itemRefs = []

  useLayoutEffect(() => {
    // Determine which items to hide when the component mounts
    checkOverflowRefs({
      firstItem,
      items,
      itemRefs,
      maxHeight,
      nonList,
      overflowIndicatorRef,
      renderItem,
      setFirstItem,
      setItemVisibility,
      setOverflowCount,
      setOverrideOverflow,
      itemVisibility,
    })

    // Create a debounced function to recalculate the overflow items when the window is resized
    const checkOverflowOnResize = debounce(() => {
      // Reset the visibility state, or else the 'aria-hidden' items cannot be re-evaluated
      setItemVisibility(itemRefs.map(() => 'visible'))
      setFirstItem(items[0])
      setOverflowCount(0)
      return checkOverflowRefs({
        firstItem,
        items,
        itemRefs,
        maxHeight,
        nonList,
        overflowIndicatorRef,
        renderItem,
        setFirstItem,
        setItemVisibility,
        setOverflowCount,
        setOverrideOverflow,
        itemVisibility,
      })
    }, 300)
    window.addEventListener('resize', checkOverflowOnResize)

    return () => {
      window.removeEventListener('resize', checkOverflowOnResize)
    }
  }, [])

  useLayoutEffect(() => {
    // Recalculate which items to hide when related props change
    checkOverflowRefs({
      firstItem,
      items,
      itemRefs,
      maxHeight,
      nonList,
      overflowIndicatorRef,
      renderItem,
      setFirstItem,
      setItemVisibility,
      setOverflowCount,
      setOverrideOverflow,
      itemVisibility,
    })
  }, [firstItem, items, maxHeight, overflowCount])

  const setItemRef = (ref, idx) => {
    // Reset item refs each time in case the # of items has changed
    if (idx === 0) itemRefs = []
    // Must be set up like this to send the ref back to the component
    itemRefs[idx] = ref
  }

  const lastVisibleIndex = items.length - overflowCount - 1

  return (
    <div>
      {items.map((item, idx) => (
        <span
          className={classNames({
            'mr-1': idx !== lastVisibleIndex,
            'd-none': itemVisibility[idx] === 'overflow',
          })}
          key={item}
          ref={ref => setItemRef(ref, idx)}
          title={item}
        >
          {renderItem
            ? renderItem(item)
            : `${idx === 0 ? firstItem : item}${
                idx === lastVisibleIndex ? '' : ', '
              }`}
        </span>
      ))}
      {(!!overflowCount || overrideOverflow) && (
        <div data-test="overflow-indicator" ref={overflowIndicatorRef}>
          {showMoreContent}
        </div>
      )}
    </div>
  )
}

TruncatableList.propTypes = {
  items: arrayOf(string),
  maxHeight: number,
  nonList: bool,
  renderItem: func,
  renderOverflowAction: func,
}

TruncatableList.defaultProps = {
  items: null,
  maxHeight: 72, // 3 lines for base font size
  nonList: false,
  renderItem: null,
  renderOverflowAction: null,
}
TruncatableList.displayName = 'TruncatableList'

export default TruncatableList
