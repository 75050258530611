import React from 'react'
import { string } from 'prop-types'
import classNames from 'classnames'
import safeLoc from '../../../utils/safeLoc'

const propTypes = {
  className: string,
}

const defaultProps = {
  className: '',
}

// It's a little silly for this to be it's own component right now, but having it
// separate will make it easier to localize "Required" later.
// (This is styled in `forms.scss`)
const RequiredFlag = ({ className }) => (
  <span className={classNames(className, 'required-flag text-muted')}>
    {safeLoc('FORMS_required', {
      backupText: 'Required',
    })}
  </span>
)

RequiredFlag.propTypes = propTypes
RequiredFlag.defaultProps = defaultProps
export default RequiredFlag
