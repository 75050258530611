const getDefaultForNode = ({ type, default: defaultValue }) => {
  if (defaultValue !== undefined && defaultValue !== null) return defaultValue

  switch (type) {
    case 'object':
      return {}
    case 'array':
      return []
    case 'string':
      return ''
    case 'number':
    case 'integer':
      return 0
    case 'boolean':
      return false
    default:
      return null
  }
}

// We're going to create a default value for a given node but only create the value
const makeNodeDefault = node => {
  const { properties } = node
  const value = getDefaultForNode(node)

  if (properties) {
    Object.keys(properties).forEach(property => {
      value[property] = makeNodeDefault(properties[property])
    })
  }

  return value
}

export default makeNodeDefault
